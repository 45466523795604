import { Reducer } from 'redux';
import { AuthState, AuthActionTypes } from './types';

// Type-safe initialState!
export const initialState: AuthState = {
    currentUser: null,
    isAuth: false,
    regionSettingsConfig: [],
    regionKeyConfig: [],
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<AuthState> = (state = initialState, action) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN_USER:
            return {
                ...state,
                ...action.payload,
            };
        case AuthActionTypes.LOGOUT_USER:
            return {
                ...initialState,
            };
        case AuthActionTypes.UPDATE_REGION_SETTINGS_CONFIG:
            return {
                ...state,
                regionSettingsConfig: [...action.payload],
            };
        case AuthActionTypes.UPDATE_REGION_KEY_CONFIG:
            return {
                ...state,
                regionKeyConfig: [...action.payload],
            };
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as authReducer };
