import { action } from 'typesafe-actions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { AccessToken, AccessTokensActionTypes } from './types';

/**
 * Actions for getting the access tokens - request, to handle the loading indicator.
 * @param payload
 */
export const getAccessTokensRequestAction = () =>
    action(AccessTokensActionTypes.GET_ACCESS_TOKENS_REQUEST);
export const getAccessTokensSuccessAction = (payload: {
    data: AccessToken[];
}) => action(AccessTokensActionTypes.GET_ACCESS_TOKENS_SUCCESS, payload);
export const getAccessTokensErrorAction = (payload: {
    errorMessages: string[];
}) => action(AccessTokensActionTypes.GET_ACCESS_TOKENS_ERROR, payload);

/**
 *Actions for revoking the access tokens of the selected users.
 * @param payload
 */
export const revokeAccessTokensRequestAction = (
    key: string,
    callback?: (response: ResponseModalObject) => void
) =>
    action(AccessTokensActionTypes.REVOKE_ACCESS_TOKENS_REQUEST, {
        key,
        callback,
    });
export const revokeAccessTokensResponseAction = () =>
    action(AccessTokensActionTypes.REVOKE_ACCESS_TOKENS_RESPONSE);

/**
 * Function for clearing all the access tokens redux state.
 */
export const clearAccessTokensStateDataAction = () =>
    action(AccessTokensActionTypes.CLEAR_ACCESS_TOKENS_STATE_DATA);

/**
 * Action that triggers the saga for creating a personal access token\
 */
export const createAccessTokenRequestAction = (
    payload: DynamicObject,
    callback?: (response: DynamicObject) => void
) =>
    action(AccessTokensActionTypes.CREATE_ACCESS_TOKEN_REQUEST, {
        payload,
        callback,
    });
