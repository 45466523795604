/**
 * File responsible for all the UI and actions for Import page - `/app/import`.
 */

import React from 'react';
import ImportExcelComponent from '../../../../components/import/ImportExcelComponent';

interface IProps {}
const ImportExcelPage: React.FC<IProps> = () => {
    return (
        <ImportExcelComponent
            downloadTemplate={"IODM_Invoice_Template.xlsx"}
            templateText={"open invoices"}
        />
    );
};

export default ImportExcelPage;