/**
 * Function for populating the raise ticket panel when clicking `Raise customer ticket` button.
 */

import { Button, Col, Form, Modal, Row, Skeleton } from 'antd';
import { filter, find, get, isEmpty, map, times } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../components/common/DateFormatHandler';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import RaiseTicketCommonFields from '../../components/common/RaiseTicketCommonFields';
import {
    raiseTicketFieldVariables,
    ticketOptionTypes,
} from '../../constants/customerAssistanceSortAndFilters';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import { ApplicationState } from '../../store';
import { raiseQueryInvoiceBulkRequestAction } from '../../store/invoices/actions';
import { getTicketOptionsRequestAction } from '../../store/notifications/actions';
import { removeAppliedFiltersForApiRequest } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';

interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly formatCurrency?: (amount: number) => React.ReactNode;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly getSelectedInvoicesValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
    };
    readonly customerId?: undefined | string;
}

const InvoiceRaiseTicketPanel: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    getSelectedInvoicesValues,
    customerId,
}: IProps) => {
    const [ticketOptions, setTicketOptions] = useState<{
        options: any[];
        loading: boolean;
        customerId: string;
        companyId: string;
    }>({
        options: [],
        loading: false,
        customerId: '',
        companyId: '',
    });

    // const [fileToUpload, setFileToUpload] = useState();

    /**
     * Function for getting the company Id of the currently selected user company.
     */
    const companyId = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.CompanyId')
    );

    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
        setFieldsValue,
    } = form;

    /**
     * Function called to close the drawer.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function called when drawer is closed.
     * Resets each field to it's initial state.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function for updating ticketOptions state.
     * @param ticketOptionsObject - must conform to ticketOptions state
     */
    const updateTicketOptions = (ticketOptionsObject: {}) => {
        setTicketOptions({
            ...ticketOptions,
            ...ticketOptionsObject,
        });
    };

    /**
     * Function called to get the ticket options needed for the dropdown inside the drawer.
     */
    const getTicketOptions = () => {
        if (companyId) {
            updateTicketOptions({
                loading: true,
            });
            dispatch(
                getTicketOptionsRequestAction(companyId, (res: any) => {
                    const filteredRes = filter(res, (r: DynamicObject) => {
                        return !r.Hidden;
                    });
                    updateTicketOptions({
                        options: filteredRes,
                        loading: false,
                        customerId,
                        companyId,
                    });
                })
            );
        }
    };

    useEffect(getTicketOptions, [companyId]);

    /**
     * Function called to submit the form.
     * Validates the fields first and then call an action for raising query/ticket.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, { TicketOption, ...values }: any) => {
            if (!err) {
                if (values.PaymentDate)
                    values.PaymentDate = moment(values.PaymentDate).format(
                        dateFormatDDMMMYYYYSpace
                    );

                const { allExcept, keysToUse, filterObject } =
                    getSelectedInvoicesValues();

                setSubmitLoading(true);

                const cleanFilters = removeAppliedFiltersForApiRequest(
                    filterObject,
                    true,
                    'invoice'
                );
                const ticketOptionSelected = find(ticketOptions.options, [
                    'Id',
                    TicketOption,
                ]);
                const WorkflowId = get(ticketOptionSelected, 'WorkflowId');
                const ticketOptionReason = get(ticketOptionSelected, 'Reason');
                dispatch(
                    raiseQueryInvoiceBulkRequestAction({
                        TicketOptionId: TicketOption,
                        Reason: ticketOptionReason,
                        WorkflowId,
                        Details: JSON.stringify(values),
                        CustomerId: customerId,
                        CompanyId: companyId,
                        InvoiceIds: keysToUse,
                        InvoiceManagementFilter: {
                            InvoiceIds: keysToUse,
                            ExcludeInvoices: allExcept,
                            ...cleanFilters,
                        },
                        callback: raiseQueryResponseModal,
                    })
                );
            }
        });
    };

    /**
     * Function called for populating the response modal (success / error).
     * @param param0
     */
    const raiseQueryResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Ticket sent successfully!',
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to send ticket!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }
    };

    /**
     * Function called for populating the raise ticket drawer content.
     * Loads a skeleton with loading prop while data is still fetched.
     */
    const populatePanelContent = () => {
        if (ticketOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {
            // const uploadProps = {
            //     accept: '.xlsx,.xlsm',
            //     name: fileToUpload ? '' : 'Attach file',
            //     onRemove: (file: any) => {
            //         setFileToUpload(null);
            //     },
            //     beforeUpload: (file: any) => {
            //         setFileToUpload(file);
            //         return false;
            //     },
            //     fileList: fileToUpload ? [fileToUpload] : [],
            // };

            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <RaiseTicketCommonFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldValue={getFieldValue}
                                ticketOptions={ticketOptions}
                                setFieldsValue={setFieldsValue}
                            />
                        </Col>
                    </Row>
                    {/* <Row className="mt-10">
                        <Col span={24} className="ta-right">
                            <Form.Item
                                label={<b>Or attach the remittance advice</b>}
                                colon={false}
                            >
                                {getFieldDecorator('remittanceAdvice')(
                                    <Upload {...uploadProps}>
                                        <Button type="primary">
                                            Attach file
                                        </Button>
                                    </Upload>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Form.Item>
                                {getFieldDecorator('notifyCustomers', {
                                    valuePropName: 'checked',
                                })(
                                    <Checkbox>
                                        Notify the customer you have raised this
                                        ticket
                                    </Checkbox>
                                )}
                            </Form.Item>
                        </Col>
                    </Row> */}
                </Form>
            );
        }
    };

    /**
     * Function for handling if submit button be shown or not.
     */
    const populateSubmitButton = () => {
        const selectedTicketId =
            getFieldValue(raiseTicketFieldVariables.SELECT_ACTION) ||
            get(ticketOptions.options, '0.Id');
        const selectedTicketOption = get(
            filter(ticketOptions.options, ['Id', selectedTicketId]),
            '0'
        );
        const Type = get(selectedTicketOption, 'Type');

        if (Type !== ticketOptionTypes.INFO_ONLY) {
            return (
                <Button
                    className="mr-8"
                    type="primary"
                    onClick={handleSubmitForm}
                    disabled={
                        ticketOptions.loading || isEmpty(ticketOptions.options)
                    }
                >
                    Submit
                </Button>
            );
        }
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        {populateSubmitButton()}
                        <Button onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <ModalWithSpinner
                    modalTitle="Sending ticket"
                    modalVisible={submitLoading}
                    displayMessage="Please wait while sending the ticket . . ."
                />
            )}
        </Row>
    );
};

const InvoiceRaiseTicketPanelForm = Form.create({
    name: 'invoice-raise-ticket-panel-form',
})(InvoiceRaiseTicketPanel);

export default withRouter(withDateFormatHandler(InvoiceRaiseTicketPanelForm));
