import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';
import { getRolePermissions } from '../../store/roles/sagas';
import './settings.less';

const UsersManagementPage = lazy(() => import('./UsersManagementPage'));
const InviteUsersPage = lazy(() => import('./InviteUsersPage'));

interface IProps {
    match: {
        path: string;
    };
}
const UsersPageContainer: React.FC<IProps> = (props: IProps) => {
    const rolePermissions = useSelector(getRolePermissions);
    return (
        <Suspense
            fallback={
                <Spin className="h-100 w-100 center-flex-all ta-center" />
            }
        >
            <Switch>
                <ProtectedRoute
                    exact
                    path={props.match.path}
                    component={UsersManagementPage}
                />
                <ProtectedRoute
                    path={`${props.match.path}/invite-users`}
                    exact
                    component={InviteUsersPage}
                    allowedRoles={rolePermissions.USER_INVITE_CREATE}
                />
            </Switch>
        </Suspense>
    );
};

export default withRouter(UsersPageContainer);
