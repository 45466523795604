export const PENDING = {
    name: 'Pending',
    display: 'pending',
};

export const IN_PROGRESS = {
    name: 'InProgress',
    display: 'in progress',
};

export const SUCCESSFUL = {
    name: 'Successful',
    display: 'successful',
};

export const FAILED = {
    name: 'Failed',
    display: 'failed',
};

export const SUCCESSFUL_WITH_WARNINGS = {
    name: 'SuccessfulWithWarnings',
    display: 'successful with warnings',
};
