/**
 * Component for populating each item in Personal access token management page.
 */

import { Avatar, Checkbox, Col, Row } from 'antd';
import { get, includes, isEqual } from 'lodash';
import React, { memo } from 'react';
import { DynamicObject } from '../../utils/commonInterfaces';

interface IProps {
    readonly item: DynamicObject;
    readonly hideCheckbox?: boolean;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly selectedId?: any;
}
const PersonalAccessTokenItemComponent: React.FC<IProps> = ({
    item,
    hideCheckbox,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    selectedId,
}: IProps) => {
    const { Id } = item;

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    const isActive = get(item, 'active');

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col span={24} style={{ height: '100%' }}>
                <Row type="flex" align="middle" style={{ height: '100%' }}>
                    {!hideCheckbox && (
                        <Col md={2} sm={24} xs={24}>
                            <div style={{ padding: '0px 20px' }}>
                                <Checkbox
                                    checked={includes(selectedRowKeys, Id)}
                                    onClick={handleCheckboxClick}
                                    disabled={get(item, 'active') === false}
                                />
                            </div>
                        </Col>
                    )}
                    <Col md={hideCheckbox ? 8 : 6} sm={24} xs={24}>
                        {hideCheckbox ? <span>&emsp;</span> : null}
                        {get(item, 'name')}
                    </Col>
                    <Col className="center-flex-i" md={4} sm={24} xs={24}>
                        <Avatar
                            style={{
                                backgroundColor: isActive ? 'green' : 'red',
                                verticalAlign: 'middle',
                                marginRight: 8,
                            }}
                            size={15}
                        ></Avatar>
                        {isActive ? 'Active' : 'Revoked'}
                    </Col>
                    <Col md={5} sm={24} xs={24}>
                        {get(item, 'role')}
                    </Col>
                    <Col md={5} sm={24} xs={24}>
                        {get(item, 'fullName')}
                    </Col>
                    <Col md={2} sm={24} xs={24} className="ta-center">
                        {get(item, 'avatar')}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

// export default PersonalAccessTokenItemComponent;

const arePropsEqual = (prevProps: any, nextProps: any) => {
    const prevItem = { ...prevProps.item };
    const nextItem = { ...nextProps.item };
    if (get(prevItem, 'avatar')) {
        delete prevItem.avatar;
    }
    if (get(nextItem, 'avatar')) {
        delete nextItem.avatar;
    }
    if (
        JSON.stringify(prevItem) === JSON.stringify(nextItem) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(PersonalAccessTokenItemComponent, arePropsEqual);
