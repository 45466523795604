/**
 * Component that listens for when the user goes inactive/idle.
 */

import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';
import {
    TIMEOUT_MILLISECOND,
    TIME_ACTIVE_COOKIE_SETTING,
    TOKEN_NAME,
} from '../../config/config';
import {
    getBooleanIndicatorIfInactiveWithinPeriod,
    getDiffInMsLastActiveAndCurrentTimestamp,
    setCookieLastActiveCurrent,
} from '../../utils/commonFunctions';
import { withAuthHandler } from '../common/AuthHandler';

interface IProps {
    logoutUser: () => void;
}

let idleListenerTimeoutHandler: any = undefined;
let unmounted = false;
let hasLoaded = false;
const SessionTimeout: React.FC<IProps> = (props: IProps) => {
    /**
     * Function called when the onIdle listener is called due to user inactivity.
     * Checks with the current last saved cookie for syncing inactivity across all tabs.
     */
    const onIdle = () => {
        const isInactive = getBooleanIndicatorIfInactiveWithinPeriod();

        if (isInactive) {
            props.logoutUser();
        } else {
            if (idleListenerTimeoutHandler)
                clearTimeout(idleListenerTimeoutHandler);

            const diffInMs = getDiffInMsLastActiveAndCurrentTimestamp();
            idleListenerTimeoutHandler = setTimeout(() => {
                const token = Cookies.get(TOKEN_NAME);
                if (token) {
                    onIdle();
                } else {
                    props.logoutUser();
                }
            }, diffInMs);
        }
    };

    /**
     * Function called on user action
     */
    const onAction = () => {
        if (!hasLoaded || unmounted) return;

        setCookieLastActiveCurrent();
    };

    /**
     * Listener function for checking if page has loaded.
     */
    const checkIfHasLoaded = () => {
        if (!hasLoaded) {
            hasLoaded = true;
        }
    };
    useEffect(checkIfHasLoaded, [hasLoaded]);

    const unmountListener = () => {
        // unmount
        return () => {
            if (idleListenerTimeoutHandler)
                clearTimeout(idleListenerTimeoutHandler);
            unmounted = true;
            hasLoaded = false;
        };
    };
    useEffect(unmountListener, []);

    return (
        <div>
            <IdleTimer
                element={document}
                onIdle={onIdle}
                onAction={onAction}
                timeout={TIMEOUT_MILLISECOND}
                debounce={TIME_ACTIVE_COOKIE_SETTING}
            />
        </div>
    );
};

export default withRouter(withAuthHandler(SessionTimeout));
