/**
 * File responsible for the content when clicking `Send Information` in management pages.
 */

import { Button, Col, Form, Modal, Row, Select, Skeleton } from 'antd';
import { get, isEmpty, map, times } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getPopoverContainer } from '../../utils/commonFunctions';
import { ManualCommunicationTemplate } from '../../store/contents/types';

const { Option } = Select;
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const { Item: FormItem } = Form;

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly form?: any;
    readonly closePanel?: () => void;
    readonly customerFilterPayload?: {};
    readonly dispatchAction?: (payload: any) => void;
    readonly templateOptions: {
        options: ManualCommunicationTemplate[];
        loading: boolean;
    };
}

const SendInformationPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    form,
    closePanel,
    customerFilterPayload,
    dispatchAction,
    templateOptions
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [currentTemplate, setCurrentTemplate] = useState<ManualCommunicationTemplate | undefined>();
    const { getFieldDecorator, validateFields, resetFields } = form;
    const customerLabel = useSelector(getCustomerUILabel);

    /**
     * Function called when `Cancel` button is clicked inside Send Information panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                if (dispatchAction) {
                    dispatch(
                        dispatchAction({
                            ...customerFilterPayload,
                            TemplateName: get(values, 'Template'),
                            callback: sendInformationResponseModal,
                        })
                    );
                } else {
                    if (closePanel) closePanel();
                }
            }
        });
    };

    /**
     * Handle side effects logic when selecting a template in Template dropdown list
     * @param value selected value
     */
    const handleOnSelectTemplate = (value: string | any) => {
        const selectedTemplate = templateOptions.options.find(option => option.TemplateName === value);
        setCurrentTemplate(selectedTemplate);
    };

    /**
     * Function responsible for showing the response modal after sending information.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const sendInformationResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
        RefetchList: boolean;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Information sent successfully!',
                onOk: () => {
                    if (closePanel) closePanel();
                },
                getContainer: () => getPopoverContainer(containerRef),
            });
        } else {
            let errorMessageContent: any = `Failed to send information!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    /**
     * Function called for populating the send information to panel content.
     * Loads a skeleton with loading prop while data is still fetched.
     */
    const populatePanelContent = () => {
        if (templateOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {
            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <FormItem>
                                {getFieldDecorator(
                                    'Template',
                                    {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please choose a template!'
                                            }
                                        ]
                                    }
                                )(
                                    <Select
                                        placeholder="Select a template"
                                        style={{ width: '100%' }}
                                        loading={templateOptions.loading}
                                        onSelect={handleOnSelectTemplate}
                                    >
                                        {map(
                                            templateOptions.options,
                                            ({ TemplateName }) => (
                                                <Option key={TemplateName} value={TemplateName}>
                                                    {TemplateName}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </FormItem>
                            <p>
                                {
                                    currentTemplate && (currentTemplate.SentToVip
                                        ? `Please note, this sends information to all selected ${customerLabel}s, including those marked as VIP`
                                        : '')
                                }
                            </p>
                        </Col>
                    </Row>
                </Form>
            );
        }
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            Send
                        </Button>
                        <Button onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Sending information"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while sending the information . . ."
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const SendInformationPanelForm = Form.create({
    name: 'send-information-panel-form',
})(SendInformationPanel);

export default withRouter(SendInformationPanelForm);
