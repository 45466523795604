import { Col, Input, Row } from 'antd';
import { range } from 'lodash';
import React, { CSSProperties, KeyboardEventHandler, useEffect, useState } from 'react';
import { setCaretPosition } from '../../utils/commonFunctions';

interface IProps {
    number?: number;
    values: string[];
    onChange: (values: string[]) => void;
    onPressEnter?: () => void;
    rowStyle?: CSSProperties;
    readOnly?: boolean;
}

const OtpInput: React.FC<IProps> = ({
    onChange, onPressEnter, values, rowStyle, number = 6, readOnly
}: IProps) => {
    const [inputRefs, setInputRefs] = useState<React.RefObject<Input>[]>([]);

    useEffect(() => {
        setInputRefs(Array.from({ length: number }, () => React.createRef<Input>()));
    }, [number]);

    const focusPrev = (idx: number) => {
        if (idx > 0) {
            const inputRef = inputRefs[idx - 1].current;
            if (inputRef) {
                setCaretPosition(inputRef.input, 1);
            }
        }
    }

    const focusNext = (idx: number) => {
        if (idx + 1 < number) {
            const inputRef = inputRefs[idx + 1].current;
            if (inputRef) {
                setCaretPosition(inputRef.input, 1);
            }
        }
    }

    const onInputChange = (idx: number) => (value: string) => {
        const newValues = [...values];
        newValues[idx] = value;
        onChange(newValues);
        if (value) {
            focusNext(idx);
        } else {
            focusPrev(idx);
        }
    }

    const onKeyDown: (idx: number) => KeyboardEventHandler<HTMLInputElement> = (idx: number) => (event) => {
        if (readOnly) return;

        const focusPrevKeys = ['ArrowLeft']
        const focusNextKeys = ['ArrowRight']
        if (focusPrevKeys.includes(event.key)) {
            event.preventDefault();
            focusPrev(idx);
        } else if (focusNextKeys.includes(event.key)) {
            event.preventDefault();
            focusNext(idx);
        } else if (event.key === 'Backspace') {
            if (!values[idx]) {
                event.preventDefault();
                focusPrev(idx);
            }
        } else if (event.key.length === 1 && values[idx]) {
            onInputChange(idx)(event.key);
            event.preventDefault();
        }
    }

    return <Row style={rowStyle} gutter={[10, 10]} align='middle' justify='center'>
        {range(0, number).map(idx => <Col span={24 / number} key={idx}>
            <Input
                ref={inputRefs[idx]}
                style={{ textAlign: 'center', fontSize: 32, width: 48, height: 48 }}
                maxLength={1}
                onChange={(event) => {
                    onInputChange(idx)(event.target.value);
                }}
                value={values[idx]}
                onPressEnter={readOnly ? undefined : onPressEnter}
                onKeyDown={onKeyDown(idx)}
                readOnly={readOnly}
            />
        </Col>)}
    </Row>
};

export default OtpInput;
