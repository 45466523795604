import { PageData } from '../common/types';

export enum ImportActionTypes {
    UPLOAD_TEMPLATE_FILE_REQUEST = '@@import/UPLOAD_TEMPLATE_FILE_REQUEST',
    DOWNLOAD_TEMPLATE_FILE_REQUEST = '@@import/DOWNLOAD_TEMPLATE_FILE_REQUEST',
    GET_IMPORT_HISTORY_REQUEST = '@@import/GET_IMPORT_HISTORY_REQUEST',
    GET_IMPORT_HISTORY_SUCCESS = '@@import/GET_IMPORT_HISTORY_SUCCESS',
    GET_IMPORT_HISTORY_ERROR = '@@import/GET_IMPORT_HISTORY_ERROR',
    SET_IMPORT_HISTORY_SELECTED_ID_REQUEST = '@@import/SET_IMPORT_HISTORY_SELECTED_ID_REQUEST',
    SET_IMPORT_HISTORY_SELECTED_ID_SUCCESS = '@@import/SET_IMPORT_HISTORY_SELECTED_ID_SUCCESS',
    GET_IMPORT_HISTORY_DATA_REQUEST = '@@import/GET_IMPORT_HISTORY_DATA_REQUEST',
    GET_IMPORT_HISTORY_DATA_SUCCESS = '@@import/GET_IMPORT_HISTORY_DATA_SUCCESS',
    GET_IMPORT_HISTORY_DATA_ERROR = '@@import/GET_IMPORT_HISTORY_DATA_ERROR',
    GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_REQUEST = '@@import/GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_REQUEST',
    GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_SUCCESS = '@@import/GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_SUCCESS',
    GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_ERROR = '@@import/GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_ERROR',
    CLEAR_IMPORT_HISTORY_DATA_SUCCESS = '@@import/CLEAR_IMPORT_HISTORY_DATA_SUCCESS',

    GET_XERO_CONFIGURATION_REQUEST = '@@import/GET_XERO_CONFIGURATION_REQUEST',
    XERO_CONNECT_REQUEST = '@@import/XERO_CONNECT_REQUEST',
    XERO_DISCONNECT_REQUEST = '@@import/XERO_DISCONNECT_REQUEST',
    XERO_MANUAL_SYNC_REQUEST = '@@import/XERO_MANUAL_SYNC_REQUEST',
}

export interface ImportState {
    history: ImportHistoryState;
}

export interface ImportHistoryState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: ImportHistory[];
    readonly activeData: {
        loading: boolean;
        record: {};
        errorsAndWarnings: {
            data: [];
            pageData: PageData;
            loading: boolean;
            errorMessages: [];
        };
        selectedId: string | null;
        errorMessages: string[];
    };
}

export interface ImportHistory {
    readonly Id: string;
    readonly User: {
        GivenName: string;
        FamilyName: string;
    };
    readonly Status: string;
    readonly StartDateTime: string;
    readonly EndDateTime: string;
    readonly TotalWarnings: number;
    readonly TotalPaymentPlanCreated: number
}

export interface ImportStagingMessage {
    readonly RowNumber: number;
    readonly InvoiceCode: string;
    readonly PaymentPlanCode: string;
    readonly Message: string;
    readonly Type: string;
}

export interface GetImportHistoryRequestPayload {
    pageSize: number;
    currentPage: number;
}

export interface GetImportHistoryErrorsAndWarningsRequestPayload {
    pageSize: number;
    currentPage: number;
}
