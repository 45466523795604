import API, { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import { API_NAME } from '../../config/config';
import queries from '../../graphql/queries.graphql';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    getAccessTokensSuccessAction,
    revokeAccessTokensResponseAction,
} from './actions';
import { initialState } from './reducer';
import { AccessTokensActionTypes } from './types';

export const getCurrentUser = (state: ApplicationState) =>
    state.auth.currentUser;

/**
 * Function that connects to get accessTokens and role for company api.
 */
function* handleGetAccessTokensRequest() {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_API_USERS_FOR_COMPANY)
        );

        const { ApiUsers } = get(res.data, 'GetApiUsersForCompany');
        const responsePayload = {
            data: ApiUsers,
        };

        yield put(getAccessTokensSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        yield put(
            getAccessTokensSuccessAction({
                data: initialState.data,
            })
        );
    }
}

/**
 * Function that connects to revoke the access tokens of the selected users.
 */
function* handleRevokeAccessTokensRequest({ payload: sagaPayload }: any) {
    const { key, callback } = sagaPayload;
    const payload = {
        Key: key,
    };

    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/company/token',
            {
                body: payload,
            }
        );

        if (callback) {
            res.IsSuccess = true;

            callback(res);
        }
        yield put(revokeAccessTokensResponseAction());
    } catch (err) {
        console.log('err: ', err);
        if (callback) {
            let returnData: DynamicObject;
            if (typeof err === 'object' && err !== null && 'response' in err) {
                const errorResponse = err as { response: { data: any } };
                returnData = get(errorResponse.response, 'data')
                    ? errorResponse.response.data
                    : { Messages: ['An error occurred'] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }
            returnData.IsSuccess = false;
            callback(returnData);
        }
        yield put(revokeAccessTokensResponseAction());

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that connects to revoke the access tokens of the selected users.
 */
function* handleCreateAccessTokenRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/company/token',
            {
                body: payload,
            }
        );

        if (callback) {
            res.IsSuccess = true;

            callback(res);
        }
    } catch (err) {
        console.log('err: ', err);
        if (callback) {
            let returnData: DynamicObject;
             if (typeof err === 'object' && err !== null && 'response' in err) {
                const errorResponse = err as { response: { data: any } };
                returnData = get(errorResponse.response, 'data')
                    ? errorResponse.response.data
                    : { Messages: ['An error occurred'] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetAccessTokensRequest() {
    yield takeLatest(
        AccessTokensActionTypes.GET_ACCESS_TOKENS_REQUEST,
        handleGetAccessTokensRequest
    );
}

function* watchRevokeAccessTokensRequest() {
    yield takeLatest(
        AccessTokensActionTypes.REVOKE_ACCESS_TOKENS_REQUEST,
        handleRevokeAccessTokensRequest
    );
}

function* watchCreateAccessTokenRequest() {
    yield takeLatest(
        AccessTokensActionTypes.CREATE_ACCESS_TOKEN_REQUEST,
        handleCreateAccessTokenRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* accessTokensSaga() {
    yield all([
        fork(watchGetAccessTokensRequest),
        fork(watchRevokeAccessTokensRequest),
        fork(watchCreateAccessTokenRequest),
    ]);
}

export default accessTokensSaga;
