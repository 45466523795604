/**
 * File for the `Total owed` widget.
 */

import { Col, Row, Spin } from 'antd';
import { clone, forEach, get, includes } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { ATBStateOptions } from '../../constants/customersSortAndFilters';
import { populatePayloadForOrganisationWidgets } from '../../constants/dashboards';
import { getDashboardTotalOwedRequestAction } from '../../store/dashboards/actions';
import { dashboardBypassAPIFetch } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatCurrency: (value: number) => React.ReactNode;
    readonly isOrgView?: boolean;
    readonly functionRefObj?: any;
}

const TotalOwedWidget: React.FC<IProps> = ({
    widgetDetails,
    formatCurrency,
    isOrgView,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();

    const [widgetState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        totalOwed: number;
    }>({
        lastWidgetDetails: {},
        loading: false,
        totalOwed: 0,
    });

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateWidgetStateObject = (widgetStateObject: {}) => {
        setTableState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        let customerFieldValue: string = '';
        const customFieldFilters: DynamicObject = {};
        const customFieldsUsed = [
            CUSTOM_FIELD_TYPES.CUSTOMER,
            CUSTOM_FIELD_TYPES.INVOICE,
        ];
        let invoiceOpenState = 'All';
        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );

                if (includes(customFieldsUsed, customFieldType)) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }

            else if (includes(wdKey, 'Customer')) {
                customerFieldValue = wdValue;
            }

            if (wdKey === 'invoiceOpenState') {
                if (wdValue === ATBStateOptions[1].value) {
                    invoiceOpenState = 'Overdue';
                }
            }

        });

        let payload: DynamicObject = {
            filters: {
                ...customFieldFilters,
            },
            DisplayOption: invoiceOpenState,
            Customer: customerFieldValue
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationWidgets({
                payload,
                widgetDetails,
            });
        }

        dispatch(
            getDashboardTotalOwedRequestAction(
                payload,
                isOrgView,
                (totalOwed: number) => {
                    if (unmountedRef.current) return;

                    updateWidgetStateObject({
                        loading: false,
                        totalOwed: totalOwed || 0,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateWidgetStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    const { loading: stateLoading, totalOwed: stateTotalOwed } = widgetState;

    return (
        <div className="box-number-widget">
            <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                <Row className="box-number-widget-row">
                    <Col span={24} className="box-number-widget-title">
                        {widgetDetails.title}
                    </Col>
                    <Col span={24} className="box-number-widget-count">
                        {formatCurrency(stateTotalOwed)}
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default withNumberFormatHandler(TotalOwedWidget);
