/**
 * Select component that will function as a read-only component when disabled.
 */

import React, { Component } from 'react';

import { Select } from 'antd';
import { DynamicObject } from '../../utils/commonInterfaces';

interface IProps {
    children: any;
    readOnly?: boolean;
    disabled?: boolean;
    value?: any;
    onSelect?: any;
    onChange?: any;
    placeholder?: string;
    className?: string;
    style?: DynamicObject;
    getPopupContainer?: any;
    mode?: 'default' | 'multiple' | 'tags';
    showSearch?: boolean;
    loading?: boolean;
    allowClear?: boolean;
}
class SelectReadonly extends Component<IProps> {
    // const SelectReadonly: React.FC<IProps> = ({
    // children,
    // readOnly,
    // className,
    // ...restProps
    // }: IProps) => {

    render() {
        const { children, readOnly, className, ...restProps } = this.props;

        const readonlyProps = readOnly ? { open: false } : null;
        return (
            <Select
                className={`${className} ${
                    readOnly ? 'cursor-na' : 'cursor-p'
                }`}
                {...readonlyProps}
                {...restProps}
                mode={restProps.mode || 'default'}
            >
                {children}
            </Select>
        );
    }
}

export default SelectReadonly;
