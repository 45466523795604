/**
 * HOC Function for populating automation icons.
 */

import React from 'react';
import {
    taskHistoryAutomation,
    taskHistoryBatchTypes,
} from '../../constants/tasksSortAndFilters';
import FontAwesome from './FontAwesome';
import { getIfIsLg } from '../../utils/commonFunctions';

export const withAutomationIconsHandler = (WrappedComponent: any) => {
    const AutomationIconsHandler: React.FC<any> = (props: any) => {
        /**
         * HOC function for generating the automation icon
         * @param type - type of task history
         * @param automation - type of automation (usually for communication types)
         * @param iconColor - color of the icon itself
         * @param iconCircleColor - color of the circle border surrounding the icon
         * @param fontSizeProp - font size property
         * @param isCompactView - property to check if compact or not.
         */
        const generateAutomationIcon = (
            type: string,
            automation: string | null,
            iconColor: string,
            iconCircleColor?: string | null,
            fontSizeProp?: number | string | null,
            isCompactView: boolean = false,
            includePaymentPlan: boolean = false
        ) => {
            const isLg = getIfIsLg();
            const fontSize = isCompactView
                ? 16
                : fontSizeProp || (isLg ? 24 : undefined);
            let smallIcon = undefined;
            const smallTransform = isCompactView
                ? 'right-28'
                : 'shrink-9 down-10 right-15';
            if (automation === taskHistoryAutomation.Automatic) {
                smallIcon = (
                    <FontAwesome
                        icon={['fas', 'robot']}
                        className={iconColor}
                        transform={smallTransform}
                        // style={{ fontSize: 12 }}
                    />
                );
            } else if (automation === taskHistoryAutomation.Manual) {
                smallIcon = (
                    <FontAwesome
                        icon={['fas', 'user']}
                        className={iconColor}
                        transform={smallTransform}
                        // style={{ fontSize: 12 }}
                    />
                );
            }
            /**
             * automated notification
             */
            if (
                includePaymentPlan &&
                (type === taskHistoryBatchTypes.PaymentSchedule ||
                    type === taskHistoryBatchTypes.PaymentPlan)
            ) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'money-bill-alt']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-9"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (
                (type === taskHistoryBatchTypes.Communication ||
                    type === taskHistoryBatchTypes.PaymentSchedule ||
                    type === taskHistoryBatchTypes.NewInvoiceEmail) &&
                automation === taskHistoryAutomation.Automatic
            ) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'satellite-dish']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-8"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (
                type === taskHistoryBatchTypes.Communication &&
                automation === taskHistoryAutomation.Manual
            ) {
                /**
                 * manual notifications
                 */
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'satellite-dish']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-8 right-1"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (
                type === taskHistoryBatchTypes.Query ||
                type === taskHistoryBatchTypes.Ticket
            ) {
                /**
                 * resolved tickets
                 */
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'question-circle']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-8"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            }
            /**
             * rejected approval to send notifications
             */
            // return (
            //     <>
            //         <div className="fa-layers fa-fw fa-2x">
            //             <FontAwesome
            //                 icon={['far', 'circle']}
            //                 className={iconCircleColor || iconColor}
            //                 size="2x"
            //             />
            //             <FontAwesome
            //                 icon={['fas', 'check']}
            //                 className={iconColor}
            //                 size="2x"
            //                 transform="shrink-7"
            //             />
            //         </div>
            //     </>
            // );
            /**
             * changed tasks
             */
            else if (type === taskHistoryBatchTypes.Changed) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'edit']}
                                className={iconColor}
                                size="2x"
                                transform="shrink-9"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (type === taskHistoryBatchTypes.Collection) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'signal']}
                                className={`${iconColor} ${
                                    !isCompactView && 'e2c-big'
                                }`}
                                size="2x"
                                transform="shrink-9"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (type === taskHistoryBatchTypes.PaymentVerification || type === taskHistoryBatchTypes.PaymentManualAllocation) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'receipt']}
                                className={`${iconColor} ${
                                    !isCompactView && 'e2c-big'
                                }`}
                                size="2x"
                                transform="shrink-8"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (type === taskHistoryBatchTypes.PaymentAllocation) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'receipt']}
                                className={`${iconColor} ${
                                    !isCompactView && 'e2c-big'
                                }`}
                                size="2x"
                                transform="shrink-8"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (type === taskHistoryBatchTypes.RemittanceAdvice) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            <FontAwesome
                                icon={['fas', 'search-dollar']}
                                className={`${iconColor} ${!isCompactView && 'e2c-big'
                                    }`}
                                size="2x"
                                transform="shrink-8"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (type === taskHistoryBatchTypes.PaymentBehaviourInsights) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />
                            
                            <FontAwesome
                                icon={['fa', 'recycle']}
                                className={`${iconColor} ${!isCompactView && 'e2c-big'
                                    }`}
                                size="2x"
                                transform="shrink-8"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (type === taskHistoryBatchTypes.PhoneCall) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />

                            <FontAwesome
                                icon={['fas', 'phone-volume']}
                                className={`${iconColor} ${!isCompactView && 'e2c-big'
                                    }`}
                                size="2x"
                                transform="shrink-8"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            } else if (type === taskHistoryBatchTypes.CreditLimit) {
                return (
                    <>
                        <div
                            className="fa-layers fa-fw fa-2x w-100"
                            style={{ fontSize }}
                        >
                            <FontAwesome
                                icon={['far', 'circle']}
                                className={iconCircleColor || iconColor}
                                size="2x"
                                transform="shrink-2"
                            />

                            <FontAwesome
                                icon={['fas', 'exclamation-triangle']}
                                className={`${iconColor} ${!isCompactView && 'e2c-big'
                                    }`}
                                size="lg"
                                transform="shrink-8"
                            />
                            {smallIcon}
                        </div>
                    </>
                );
            }
            /**
             * requested approval to send notifications
             */
            // return (
            //     <>
            //         <div className="fa-layers fa-fw fa-2x">
            //             <FontAwesome
            //                 icon={['far', 'circle']}
            //                 className={iconCircleColor || iconColor}
            //                 size="2x"
            //             />
            //             <FontAwesome
            //                 icon={['fas', 'phone-volume']}
            //                 className={iconColor}
            //                 size="2x"
            //                 transform="shrink-7 right-2"
            //             />
            //         </div>
            //         <div className={smallBtmClass}>
            //             <FontAwesome
            //                 icon={['fas', 'robot']}
            //                 className={iconColor}
            //             />
            //         </div>
            //     </>
            // );
            /**
             * reassigned tasks
             */
            // return (
            //     <>
            //         <div className="fa-layers fa-fw fa-2x">
            //             <FontAwesome
            //                 icon={['far', 'circle']}
            //                 className={iconCircleColor || iconColor}
            //                 size="2x"
            //             />
            //             <FontAwesome
            //                 icon={['fas', 'user-tag']}
            //                 className={iconColor}
            //                 size="2x"
            //                 transform="shrink-9 left-1"
            //             />
            //         </div>
            //     </>
            // );
            /**
             * added notes to task
             */
            // return (
            //     <>
            //         <div className="fa-layers fa-fw fa-2x">
            //             <FontAwesome
            //                 icon={['far', 'circle']}
            //                 className={iconCircleColor || iconColor}
            //                 size="2x"
            //             />
            //             <FontAwesome
            //                 icon={['fas', 'comment']}
            //                 className={iconColor}
            //                 size="2x"
            //                 transform="shrink-8"
            //             />
            //         </div>
            //     </>
            // );
        };

        return (
            <WrappedComponent
                {...props}
                generateAutomationIcon={generateAutomationIcon}
            />
        );
    };

    return AutomationIconsHandler;
};
