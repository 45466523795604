/**
 * Common component for displaying an iframe with loading indicator
 */

import React, { useState } from 'react';
import { Spin } from 'antd';

interface IProps {
    title: string;
    iframeURL: string;
    height?: string;
    className?: string;
    hasError?: boolean;
    loading?: boolean;
}
const IFrameWithLoading: React.FC<IProps> = ({
    title,
    iframeURL,
    height,
    className,
    hasError,
    loading
}) => {
    const [loaded, setLoaded] = useState<boolean>(false);

    const errorElement = (
        <svg width="100" height="100" viewBox="0 0 100 100">
            <rect width="100" height="100" rx="10" ry="10" fill="#EFEFEF" />
        </svg>
    );

    const isLoading = !loaded || loading === true;

    return (
        <>
            {hasError ? errorElement : null}
            <Spin spinning={isLoading} tip="Please wait. . .">
                <iframe
                    title={title}
                    className={className}
                    width="100%"
                    height={height || '600'}
                    src={iframeURL}
                    frameBorder="0"
                    onLoad={() => setLoaded(true)}
                />
            </Spin>
        </>
    );
};

export default IFrameWithLoading;
