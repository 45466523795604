import { Reducer } from 'redux';
import { WorkflowActionTypes, WorkflowDefinitionState } from './types';
import update from 'immutability-helper';

// Type-safe initialState!
export const initialState: WorkflowDefinitionState = {
    saveLoading: false,
    selectedWorkflowId: null,
    workflowDefinition: {
        errorMessages: [],
        loading: false,
        workflowDefinitionConfigure: null
    }
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<WorkflowDefinitionState> = (state = initialState, action) => {
    switch (action.type) {
        case WorkflowActionTypes.GET_WORKFLOW_DEFINITION_REQUEST: {
            const workflowDefinition = update(state.workflowDefinition, {
                loading: { $set: true }
            });

            return {
                ...state,
                workflowDefinition: workflowDefinition,
            };
        }
        case WorkflowActionTypes.GET_WORKFLOW_DEFINITION_SUCCESS: {
            const workflowDefinition = update(state.workflowDefinition, {
                loading: { $set: false },
                errorMessages: { $set: initialState.workflowDefinition.errorMessages },
                workflowDefinitionConfigure: { $set: action.payload }
            });

            return {
                ...state,
                workflowDefinition: workflowDefinition,
            };
        }
        case WorkflowActionTypes.GET_WORKFLOW_DEFINITION_ERROR: {
            const workflowDefinition = update(state.workflowDefinition, {
                loading: { $set: false },
                errorMessages: { $set: action.payload },
                workflowDefinitionConfigure: { $set: initialState.workflowDefinition.workflowDefinitionConfigure }
            });

            return {
                ...state,
                workflowDefinition: workflowDefinition,
            };
        }
        case WorkflowActionTypes.SELECT_WORKFLOW_ID: {
            return { ...state, selectedWorkflowId: action.payload };
        }
        case WorkflowActionTypes.SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST: {
            return { ...state, saveLoading: true };
        }
        case WorkflowActionTypes.SAVE_WORKFLOW_DEFINITION_CONFIGURATION_RESPONSE: {
            return { ...state, saveLoading: false };
        }
        case WorkflowActionTypes.CLEAR_WORKFLOW_DEFINITION: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as workflowDefinitionsReducer };
