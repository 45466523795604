import { Reducer } from 'redux';
import { StoredRouteState, StoredRouteActionTypes } from './types';

// Type-safe initialState!
export const initialState: StoredRouteState = {
    currentRoute: '/app',
    menuCollapsed: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<StoredRouteState> = (state = initialState, action) => {
    switch (action.type) {
        case StoredRouteActionTypes.SET_CURRENT_ROUTE:
            return {
                ...state,
                currentRoute: action.payload,
            };
        case StoredRouteActionTypes.SET_MENU_COLLAPSE_STATE:
            return {
                ...state,
                menuCollapsed: action.payload,
            };
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as storedRouteReducer };
