export const announcementsSortValues = {
    ANNOUNCEMENT_TITLE: 'Title',
    ANNOUNCEMENT_TAG: 'Tag',
    ANNOUNCEMENT_CREATED_DATETIME: 'CreatedDateTime',
    ANNOUNCEMENT_PUBLISH_DATETIME: 'PublishDateTime',
};

export const announcementTagTypes = {
    FEATURE: 'Feature',
    IMPROVEMENT: 'Improvement',
};

export const formFieldNames = {
    TAG: 'Tag',
    TAG_LABEL: 'Tag',
    PUBLISH_DATETIME: 'PublishDateTime',
    PUBLISH_DATETIME_LABEL: 'Publish datetime',
    TITLE: 'Title',
    TITLE_LABEL: 'Title',
    BODY: 'Body',
    BODY_LABEL: 'Content',
};
