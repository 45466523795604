import { Reducer } from 'redux';
import { InviteActionTypes, InvitesState } from './types';

// Type-safe initialState!
export const initialState: InvitesState = {
    companyInviteLinkingInProgress: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<InvitesState> = (state = initialState, action) => {
    switch (action.type) {
        case InviteActionTypes.COMPANY_INVITE_LINKING_IN_PROGRESS_UPDATE: {
            return {
                ...state,
                companyInviteLinkingInProgress: action.payload.inProgress,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as invitesReducer };
