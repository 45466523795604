export enum NotificationsActionTypes {
    GET_CATEGORIZED_NOTIFICATIONS_REQUEST = '@@notification/GET_CATEGORIZED_NOTIFICATIONS_REQUEST',
    GET_CATEGORIZED_NOTIFICATIONS_SUCCESS = '@@notification/GET_CATEGORIZED_NOTIFICATIONS_SUCCESS',
    GET_CATEGORIZED_NOTIFICATIONS_ERROR = '@@notification/GET_CATEGORIZED_NOTIFICATIONS_ERROR',
    GET_NOTIFICATIONS_BY_CATEGORY_REQUEST = '@@notification/GET_NOTIFICATIONS_BY_CATEGORY_REQUEST',
    GET_NOTIFICATIONS_BY_CATEGORY_SUCCESS = '@@notification/GET_NOTIFICATIONS_BY_CATEGORY_SUCCESS',
    GET_NOTIFICATIONS_BY_CATEGORY_ERROR = '@@notification/GET_NOTIFICATIONS_BY_CATEGORY_ERROR',
    UPDATE_NOTIFICATIONS_READ_STATE = '@@notification/UPDATE_NOTIFICATIONS_READ_STATE',
    CLEAR_CATEGORIZED_NOTIFICATIONS_STATE_DATA = '@@notification/CLEAR_CATEGORIZED_NOTIFICATIONS_STATE_DATA',
    CLEAR_NOTIFICATIONS_BY_CATEGORY_STATE_DATA = '@@notification/CLEAR_NOTIFICATIONS_BY_CATEGORY_STATE_DATA',

    REFETCH_NEW_NOTIFICATIONS_TRIGGER = '@@notification/REFETCH_NEW_NOTIFICATIONS_TRIGGER',
    FETCH_NEW_NOTIFICATIONS = '@@notification/FETCH_NEW_NOTIFICATIONS',

    FETCH_NOTIFICATIONS_DETAILS_PUBLIC = '@@notification/FETCH_NOTIFICATIONS_DETAILS_PUBLIC',
    GET_UNREAD_NOTIFICATIONS_FOR_USER_REQUEST = '@@notification/GET_UNREAD_NOTIFICATIONS_FOR_USER_REQUEST',
    GET_UNREAD_NOTIFICATIONS_FOR_USER_SUCCESS = '@@notification/GET_UNREAD_NOTIFICATIONS_FOR_USER_SUCCESS',

    GET_NOTIFICATION_LETTER_PRESIGNED_URL = '@@notification/GET_NOTIFICATION_LETTER_PRESIGNED_URL',

    GET_TICKET_OPTIONS_REQUEST = '@@notification/GET_TICKET_OPTIONS_REQUEST',

    RAISE_QUERY_REQUEST = '@@notification/RAISE_QUERY_REQUEST',

    MARK_NOTIFICATION_AS_READ = '@@notification/MARK_NOTIFICATION_AS_READ',

    PAY_USING_CREDITS = '@@notification/PAY_USING_CREDITS',
    GET_PAYMENT_DATA_PUBLIC_REQUEST = '@@notification/GET_PAYMENT_DATA_PUBLIC_REQUEST',

    GET_PRODUCT_ANNOUNCEMENTS_REQUEST = '@@notification/GET_PRODUCT_ANNOUNCEMENTS_REQUEST',
    SET_LOGIN_PRODUCT_ANNOUNCEMENT_LOADING = '@@notification/SET_LOGIN_PRODUCT_ANNOUNCEMENT_LOADING',
    GET_CORPAY_CURRENCIES_FOR_COMPANY_REQUEST = '@@notification/GET_CORPAY_CURRENCIES_FOR_COMPANY_REQUEST',

}

export interface NotificationsState {
    readonly categorizedNotifications: NotificationsDataState;
    readonly notificationsByCategory: NotificationsDataState;
    readonly refetchNewNotifications: boolean;
    readonly unreadNotificationCategories: string[];
    readonly announcementsFetching: boolean;
}

interface NotificationsDataState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Notification[];
}

export interface Notification {
    readonly Id: string;
    readonly CategoryId: string;
    readonly Title: string;
    readonly Description: string;
    readonly CreatedDate: string;
    readonly ItemsCount: number;
    readonly Company: {
        Name: string;
    };
}

export interface GetCorpayCurrenciesForCompanyRequestPayload {
    companyId: string;
    callback?: (response: any[]) => void;
}
