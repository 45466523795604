/**
 * Component for generating the routes based on array of routes given.
 */

import { map } from 'lodash';
import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute';

interface IProps {
    readonly routeList: any;
    match: {
        path: string;
    };
}
const GenerateRoutes: React.FC<IProps> = (props: IProps) => {
    const routeItemComponents: any = [];

    /**
     * Function for populating the route components.
     * @param routeItems
     */
    const populateRouteComponents = (routeItems: any) => {
        map(routeItems, (routeItem: any) => {
            if (routeItem.children) {
                return populateRouteComponents(routeItem.children);
            } else {
                if (routeItem.component) {
                    const fullRoute = `${props.match.path}${routeItem.route}`;
                    // console.log('item name', routeItem.name);
                    // routeItemComponents.push(routeItem.route);
                    routeItemComponents.push(
                        <ProtectedRoute
                            key={fullRoute}
                            path={fullRoute}
                            component={routeItem.component}
                            allowedRoles={routeItem.allowedRoles}
                            exact={routeItem.exact}
                        />
                    );
                }
            }
        });

        return routeItemComponents;
    };

    return (
        <Switch>
            {populateRouteComponents(props.routeList)}
            <Redirect to="/app" />
        </Switch>
    );
};

export default withRouter(GenerateRoutes);
