/**
 * File for the `Top payments`s widget form.
 */

import { Col, Divider, Form, Input, InputNumber, Row, Select, Switch, DatePicker, Checkbox } from 'antd';
import { filter, get, includes, isUndefined, map, toLower, isObject, values, forEach, has, capitalize, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { ATBStateOptions } from '../../constants/customersSortAndFilters';
import { hiddenCloudImportFields } from '../../constants/settings';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    populatePopoverContainer,
    replaceInstancesOfCustomerString,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { customFieldIndicator, paymentFieldIndicator } from '../common/FilterBar';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import {
     dateSelectOptions
} from '../../constants/invoicesSortAndFilters';
import { ApplicationState } from '../../store';
import { AppCommonState } from '../../store/common/types';
import moment from 'moment-timezone';
import {
    dateFormatDDMMYYYYSlash,
    dateFormatYYYYMMDDDash,
} from '../../constants/dateFormats';
import { paymentsSortByOptions, paymentsWidgetFilters } from '../../constants/paymentsSortAndFilters';
import { CompanyCustomFieldConfigure } from '../../store/companies/types';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    isUsingCloudImportType: boolean;
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
    organisationCurrenciesAll?: DynamicObject[];
}

export const displayColumnIndicator = 'DisplayColumn---';
export const showCustomFieldsIndicator = 'ShowCustomFields--';

export const displayColumnOptions = {
    PaidAmount: {
        label: 'Amount paid',
        value: 'PaidAmount',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    PaymentReference: {
        label: 'Payment reference',
        value: 'PaymentReference',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    LocalPaidDate: {
        label: 'Payment date',
        value: 'LocalPaidDate',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    LocalSettlementDate: {
        label: 'Settlement date',
        value: 'LocalSettlementDate',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    Result: {
        label: 'Result',
        value: 'Result',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    CustomerName: {
        label: 'Customer name',
        value: 'CustomerName',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    CustomerCode: {
        label: 'Customer code',
        value: 'CustomerCode',
        defaultChecked: false,
        OrgViewOnly: false,
    },
    CompanyName: {
        label: 'Company name',
        value: 'CompanyName',
        defaultChecked: false,
        OrgViewOnly: true,
    },
    [`Customer${showCustomFieldsIndicator}`]: {
        label: 'Customer custom fields',
        value: `Customer${showCustomFieldsIndicator}`,
        defaultChecked: false,
        customFieldType: CUSTOM_FIELD_TYPES.CUSTOMER,
        OrgViewOnly: false
    },
};

export const hiddenTableColumns = [
    displayColumnOptions[`Customer${showCustomFieldsIndicator}`].value,
];

export const displayRowCountOptions = [5, 10, 15, 20];

const PaymentsWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    customFieldsFilterList,
    isUsingCloudImportType,
    isOrgView,
    getFieldValue,
    setFieldsValue,
    organisationCurrenciesAll,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);

    const appState: AppCommonState = useSelector(
        (state: ApplicationState) => state.app
    );

    const currencyObj = Intl.NumberFormat(appState.locale, {
        style: 'currency',
        currency: appState.currencyCode,
    }).formatToParts(1111);

    const currencySign = get(
        filter(currencyObj, ['type', 'currency']),
        `0.value`
    );

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});

    /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
    const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

    /**
     * Function that will be triggered when selecting a date range.
     * @param rangeType  - From and To - based on UI mockup
     * @param filterName - name of filter
     */
    const selectDateRange = (rangeType: 'From' | 'To', filterName: string) => {
        return (dateValue: any) => {
            const searchFilterParent = get(searchFilters, filterName);
            const newSearchFilterParent = {
                ...searchFilterParent,
                [rangeType]: dateValue,
            };
            updateSearchFiltersObject({
                [filterName]: newSearchFilterParent,
            });
        };
    };

    /**
     * Function responsible for setting the days range filter values.
     * @param rangeType - Last, Next
     * @param filterName - name of filter
     * @param daysType  - From, To, or not defined
     */
    const selectDaysRange = (
        rangeType: 'Last' | 'Next',
        filterName: string,
        daysType: 'From' | 'To' | undefined = undefined
    ) => {
        return (daysValue: number | undefined) => {
            if (daysType) {
                const searchFilterParent = get(searchFilters, filterName);
                if (get(searchFilterParent, daysType)) {
                    if (rangeType === 'Last') {
                        delete searchFilterParent[daysType].Next;
                    } else {
                        delete searchFilterParent[daysType].Last;
                    }
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [daysType]: {
                        [rangeType]: daysValue,
                    },
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            } else {
                const searchFilterParent = get(searchFilters, filterName);
                if (rangeType === 'Last') {
                    delete searchFilterParent.Next;
                } else {
                    delete searchFilterParent.Last;
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [rangeType]: daysValue,
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            }
        };
    };

    /**
      * Function for handling the select onChange event.
      * @param selectValue - selected value on dropdown
      * @param filter - name of the filter
      * @param fieldKey - key for the component
      * @param stateName - state name of the filter
      */
    const handleSelectOnChange = (
        selectValue: string,
        filter: any,
        fieldKey: string,
        stateName: string
    ) => {
        const newFilterValue: any = {
            value: selectValue,
        };
        if (
            selectValue ===
            dateSelectOptions.CUSTOM_DAYS_RANGE
        ) {
            const customType =
                filter.filterOptionsCustomTypes[selectValue];

            if (customType === 'all-days-range-from-to') {
                if (get(widgetDetails, `${fieldKey}${"--RangeTypeFrom"}`) === "Last") {
                    newFilterValue.From = {
                        Last: get(widgetDetails, `${fieldKey}${"--Last--From"}`)
                    }
                }
                else {
                    newFilterValue.From = {
                        Next: get(widgetDetails, `${fieldKey}${"--Next--From"}`)
                    }
                }

                if (get(widgetDetails, `${fieldKey}${"--RangeTypeTo"}`) === "Last") {
                    newFilterValue.To = {
                        Last: get(widgetDetails, `${fieldKey}${"--Last--To"}`)
                    }
                }
                else {
                    newFilterValue.To = {
                        Next: get(widgetDetails, `${fieldKey}${"--Next--To"}`)
                    }
                }
            }
            else if (customType === 'all-days-range-from-to-last') {
                newFilterValue.From = {
                    Last: get(widgetDetails, `${fieldKey}${"--Last--From"}`)
                };
                newFilterValue.To = {
                    Last: get(widgetDetails, `${fieldKey}${"--Last--To"}`)
                };
            }
            else if (customType === 'last-days-range') {
                newFilterValue.Last = get(widgetDetails, `${fieldKey}${"--Last"}`);
            }
            else if (customType === 'all-days-range') {
                if (get(widgetDetails, `${fieldKey}${"--RangeType"}`) === "Last") {
                    newFilterValue.Last = get(widgetDetails, `${fieldKey}${"--Last"}`);
                }
                else {
                    newFilterValue.Next = get(widgetDetails, `${fieldKey}${"--Next"}`);
                }
            }
            else {
                newFilterValue.Last = 1;
            }
        }

        changeSearchFilter(
            stateName,
            newFilterValue
        );
    }

    /**
     * Function to populate the select/dropdown filter.
     * @param customType - nype of filter, date-range or etc
     * @param filterName - name of filter
     * @param filterFieldKey - key for the component
     * @param filterMaxDate - optional max date for filter, can be a moment object or string
     */
    const populateSelectCustomType = (
        customType: string,
        filterName: string,
        filterFieldKey: string,
        filterMaxDate?: any
    ) => {
        if (customType === 'date-range') {
            const fromDateFieldKey = `${filterFieldKey}${"--From"}`
            const toDateFieldKey = `${filterFieldKey}${"--To"}`
            let disabledDate;
            if (filterMaxDate) {
                disabledDate = (dateValue: any) => {
                    return moment(
                        moment(dateValue).format(dateFormatYYYYMMDDDash)
                    ).isAfter(
                        moment(
                            moment(filterMaxDate).format(dateFormatYYYYMMDDDash)
                        )
                    );
                };
            }
            return (
                <Col span={12} key={filterFieldKey}>
                    <FormItem label={"From"}>
                        {getFieldDecorator(fromDateFieldKey, {
                            initialValue: !isEmpty(get(widgetDetails, fromDateFieldKey)) ?
                                moment(get(widgetDetails, fromDateFieldKey)) :
                                null,
                            })(
                            <DatePicker
                                className="dashboard-datepicker"
                                format={dateFormatDDMMYYYYSlash}
                                disabledDate={disabledDate}
                                placeholder="Start Date"
                                onChange={selectDateRange('From', filterName)}
                                getCalendarContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                                allowClear={true}
                            />
                        )}
                    </FormItem>
                    <FormItem label={"To"}>
                        {getFieldDecorator(toDateFieldKey, {
                            initialValue: !isEmpty(get(widgetDetails, toDateFieldKey)) ?
                                moment(get(widgetDetails, toDateFieldKey)) :
                                null,
                            })(
                            <DatePicker
                                className="dashboard-datepicker"
                                format={dateFormatDDMMYYYYSlash}
                                disabledDate={disabledDate}
                                placeholder="End Date"
                                onChange={selectDateRange('To', filterName)}
                                getCalendarContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                                allowClear={true}
                            />
                        )}
                    </FormItem>
                </Col>
            );
        } else if (customType === 'last-days-range') {
            const lastDayFieldKey = `${filterFieldKey}${"--Last"}`
            const daysValue = get(searchFilters, `${filterName}.Last`)

            return (
                <div className="dashboard-div" key={filterFieldKey}>
                    <span>Last</span>&nbsp;&nbsp;
                    <FormItem>
                        {getFieldDecorator(lastDayFieldKey, {
                            initialValue: daysValue
                        })(
                            <InputNumber
                                onChange={selectDaysRange('Last', filterName)}
                                placeholder="Count"
                                min={1}
                            />
                        )}
                    </FormItem>
                    &nbsp;&nbsp;
                    <span>
                        day
                        {daysValue > 1 ? 's' : ''}
                    </span>
                </div>
            );
        } else if (customType === 'all-days-range') {
            const daysRangeType = ['Last', 'Next'];
            const rangeTypeFieldKey = `${filterFieldKey}${"--RangeType"}`

            const rangeTypeSelected = has(searchFilters, `${filterName}.Last`)
                ? 'Last'
                : 'Next';

            const setRangeTypeSelected = !isUndefined(rangeTypeSelected) ?
                rangeTypeSelected :
                get(widgetDetails, rangeTypeFieldKey)

            const lastNextDayFieldKey = `${filterFieldKey}${"--"}${setRangeTypeSelected}`

            const daysValue = get(searchFilters, `${filterName}.${setRangeTypeSelected}`)

            return (
                <div className="dashboard-div" key={filterFieldKey}>
                    <FormItem>
                        {getFieldDecorator(rangeTypeFieldKey, {
                            initialValue: setRangeTypeSelected
                        })(
                            <SelectReadonly
                                readOnly={formDisabled}
                                onChange={(rangeType: 'Last' | 'Next') => {
                                    selectDaysRange(rangeType, filterName)(1);
                                }}
                                placeholder="Type"
                                getPopupContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                            >
                                {map(daysRangeType, (rangeType: string) => (
                                    <Option key={rangeType} value={rangeType}>
                                        {rangeType}
                                    </Option>
                                ))}
                            </SelectReadonly>
                        )}
                    </FormItem>
                    &nbsp;&nbsp;
                    <FormItem>
                        {getFieldDecorator(lastNextDayFieldKey, {
                            initialValue: daysValue
                        })(
                            <InputNumber
                                placeholder="Count"
                                onChange={selectDaysRange(
                                    setRangeTypeSelected,
                                    filterName
                                )}
                                min={1}
                            />
                        )}
                    </FormItem>
                    &nbsp;&nbsp;
                    <span>
                        day
                        {daysValue > 1 ? 's' : ''}
                    </span>
                </div>
            );
        } else if (customType === 'all-days-range-from-to-last') {
            const daysRangeType = ['Last'];
            const rangeTypeSelectedFrom = 'Last';
            const rangeTypeSelectedTo = 'Last';

            const daysValueFrom = get(
                searchFilters,
                `${filterName}.From.${rangeTypeSelectedFrom}`
            );
            const daysValueTo = get(
                searchFilters,
                `${filterName}.To.${rangeTypeSelectedTo}`
            );

            const fromDateFieldKey = `${filterFieldKey}${"--From--Last"}`;
            const toDateFieldKey = `${filterFieldKey}${"--To--Last"}`;
            const fromCountDateFieldKey = `${filterFieldKey}${"--From--Last--Count"}`;
            const toCountDateFieldKey = `${filterFieldKey}${"--To--Last--Count"}`;

            return (
                <div>
                    <div key="From" className="mb-10">
                        <div>Selected from</div>
                            <div>
                                <span>
                                <FormItem>
                                    {getFieldDecorator(fromDateFieldKey, {
                                        initialValue: daysRangeType[0]
                                    })(
                                    <SelectReadonly
                                        onChange={(rangeType: 'Last') => {
                                        selectDaysRange(
                                                rangeType,
                                                filterName,
                                                'From'
                                            )(1);
                                        }}
                                        placeholder="Type"
                                        style={{
                                            width: 100,
                                        }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(
                                            daysRangeType,
                                            (rangeType: string) => (
                                                <Option
                                                    key={rangeType}
                                                    value={rangeType}
                                                >
                                                    {rangeType}
                                                </Option>
                                            )
                                        )}
                                    </SelectReadonly>
                                    )}
                                </FormItem>
                                </span>
                                <span>
                                <FormItem>
                                    {getFieldDecorator(fromCountDateFieldKey, {
                                        initialValue: daysValueFrom
                                    })(
                                        <InputNumber
                                            placeholder="Count"
                                            onChange={selectDaysRange(
                                                'Last',
                                                filterName,
                                                'From'
                                            )}
                                            min={1}
                                        />
                                    )}
                                     {` day`}{daysValueFrom > 1 ? 's' : ''}
                                </FormItem>
                                </span>
                        </div>
                    </div>
                    <div key="To" className="mb-10">
                        <div>Go to</div>
                            <div>
                                <span>
                                    <FormItem>
                                        {getFieldDecorator(toDateFieldKey, {
                                            initialValue: daysRangeType[0]
                                        })(
                                            <SelectReadonly
                                                onChange={(rangeType: 'Last') => {
                                                    selectDaysRange(
                                                        rangeType,
                                                        filterName,
                                                        'To'
                                                    )(1);
                                                }}
                                                placeholder="Type"
                                                style={{
                                                    width: 100,
                                                }}
                                                getPopupContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                            >
                                                {map(
                                                    daysRangeType,
                                                    (rangeType: string) => (
                                                        <Option
                                                            key={rangeType}
                                                            value={rangeType}
                                                        >
                                                            {rangeType}
                                                        </Option>
                                                    )
                                                )}
                                            </SelectReadonly>
                                        )}
                                    </FormItem>
                                    <FormItem>
                                    {getFieldDecorator(toCountDateFieldKey, {
                                        initialValue: daysValueTo
                                    })(
                                        <InputNumber
                                            placeholder="Count"
                                            onChange={selectDaysRange(
                                                'Last',
                                                filterName,
                                                'To'
                                            )}
                                            min={1}
                                        />
                                    )}
                                   {` day`}{daysValueTo > 1 ? 's' : ''}
                                    </FormItem>
                                    
                                </span>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const SupportCashAllocation = useSelector((state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.SupportCashAllocation')
);

    /**
    * Function for populating the payments fields filters.
    */
    const populatePaymentFieldsFilters = () => {
        const filters = paymentsWidgetFilters(SupportCashAllocation);
    
        return filters.map(
            (filter: DynamicObject) => {
                const fieldKey = `${paymentFieldIndicator}${filter.filterStateName}`;
                const stateName = filter.filterStateName || filter.filterName;

                if (filter.filterElement === 'select-multiple-and-input-amount') {
                    const amountValueFieldKey = `${fieldKey}${"--AmountValue"}`
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={filter.filterName}>
                                {
                                    map(
                                        filter.filterOptions,
                                        (optionSelect: any, index: number) => {
                                            const valuesArray = get(
                                                values(optionSelect),
                                                0
                                            );
                                            const optionFieldKey = `${fieldKey}${"--"}${filter.filterOptions[index].filterOptionName}`
                                            return (
                                                <div key={index}>
                                                    <FormItem>
                                                        {getFieldDecorator(optionFieldKey, {
                                                            initialValue: get(
                                                                widgetDetails,
                                                                optionFieldKey
                                                            ),
                                                        })(
                                                            <SelectReadonly
                                                                allowClear={true}
                                                                placeholder={filter.filterOptions[index].filterPlaceholder}
                                                                readOnly={formDisabled}
                                                                style={{ width: '100%' }}
                                                                getPopupContainer={populatePopoverContainer(
                                                                    drawerRef
                                                                )}
                                                            >
                                                                {map(
                                                                    valuesArray,
                                                                    (option: any) => {
                                                                        let value, label;
                                                                        if (
                                                                            isObject(option)
                                                                        ) {
                                                                            value = get(
                                                                                option,
                                                                                'value'
                                                                            );
                                                                            label = get(
                                                                                option,
                                                                                'label'
                                                                            );
                                                                        } else {
                                                                            value = option;
                                                                            label = option;
                                                                        }

                                                                        return (
                                                                            <Option
                                                                                key={value}
                                                                                value={
                                                                                    value
                                                                                }
                                                                            >
                                                                                {label}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                )}
                                                            </SelectReadonly>
                                                        )}
                                                    </FormItem>
                                                </div>
                                            )
                                        }
                                    )
                                }
                                <FormItem>
                                    {`${currencySign} `}
                                    {getFieldDecorator(amountValueFieldKey, {
                                        initialValue: get(
                                            widgetDetails,
                                            amountValueFieldKey
                                        ) != null ? get(widgetDetails, amountValueFieldKey) : 0,
                                    })(
                                        <InputNumber
                                            readOnly={formDisabled}
                                        />
                                    )}
                                </FormItem>
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'radio-group') {
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={filter.filterName}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        placeholder={filter.filterPlaceholder}
                                        readOnly={formDisabled}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(filter.filterOptions, ({ label, value }: any) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'select') {
                    const dateTypeFieldKey = `${fieldKey}${"--DateType"}`
                    const optionsObject: any = {};

                    forEach(filter.filterOptions, (option: string) => {
                        if (!optionsObject[option]) {
                            optionsObject[option] = option;
                        }
                    });

                    const selectCustomValueType = !isUndefined(searchFilters[stateName]) ?
                        get(searchFilters[stateName], 'value') :
                        get(widgetDetails, dateTypeFieldKey)

                    const selectCustomType = filter.filterOptionsCustomTypes[selectCustomValueType];

                    // Manually simulate onChange event on initial load
                    if ((selectCustomValueType === dateSelectOptions.CUSTOM_DATE_RANGE ||
                        selectCustomValueType === dateSelectOptions.CUSTOM_DAYS_RANGE) &&
                        isUndefined(searchFilters[stateName])) {
                        handleSelectOnChange(selectCustomValueType, filter, fieldKey, stateName);
                    }
                    return (
                        <Col span={12} key={dateTypeFieldKey}>
                            <FormItem label={filter.filterName}>
                                {getFieldDecorator(dateTypeFieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        dateTypeFieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        readOnly={formDisabled}
                                        className="date-picker-select"
                                        placeholder={filter.filterPlaceholder}
                                        onChange={(selectValue: any) => {
                                            handleSelectOnChange(selectValue, filter, fieldKey, stateName);
                                        }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(optionsObject, (option: any) => (
                                            <Option key={option} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                            {selectCustomType &&
                                populateSelectCustomType(
                                    selectCustomType,
                                    stateName,
                                    fieldKey,
                                    filter.filterMaxDate
                                )}
                        </Col >
                    );
                }
                else if (filter.filterElement === 'input-autocomplete') {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    const stateName = filter.filterStateName || filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={filterNameUsed}>
                                <InputAutoCompleteWithButtonDashboard
                                    readOnly={formDisabled}
                                    hasNoOkButton={true}
                                    updateField={(value: string) => {
                                        changeSearchFilter(stateName, value);
                                    }}
                                    stateValue={searchFilters[stateName]}
                                    queryName={filter.filterQuery}
                                    queryFilterName={filter.filterNameQuery}
                                    filterField={filter.filterStateName}
                                    sortField={filter.filterSort}
                                    responseName={filter.filterResponse}
                                    labelField={filter.filterLabelField}
                                    placeholder={filter.filterPlaceholder}
                                    filterSubChecking={filter.filterSubChecking}
                                    queryFilterNameSub={filter.filterNameQuerySub}
                                    getFieldDecorator={getFieldDecorator}
                                    widgetDetails={widgetDetails}
                                    filterFieldKey={fieldKey}
                                />
                            </FormItem>
                        </Col>
                    );
                }
                else if (filter.filterElement === 'checkbox-group' ){
                    return (
                        <Col span={12} key={fieldKey}>
                             <FormItem label={filter.filterName}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        readOnly={formDisabled}
                                        style={{ width: '100%' }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                        mode="multiple"
                                    >
                                        {map(filter.filterOptions, ({ label, value }: any) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                        </FormItem>
                    </Col>
                    );

                }
                else {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={filterNameUsed}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <Input
                                        placeholder={filterNameUsed}
                                        readOnly={formDisabled}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    );
                }
            }
        );
    };

    /**
     * Common function for populating the custom fields filters.
     * @param customFieldType
     */
    const populateCustomFieldsFilters = (customFieldType: string) => {
        return filter(customFieldsFilterList, ['Type', customFieldType]).map(
            ({ Type, FieldName }: DynamicObject) => {
                const customFieldKey = `${customFieldIndicator}${Type}--${FieldName}`;
                return (
                    <Col span={12} key={customFieldKey}>
                        <FormItem label={FieldName}>
                            {getFieldDecorator(customFieldKey, {
                                initialValue: get(
                                    widgetDetails,
                                    customFieldKey
                                ),
                            })(
                                <Input
                                    placeholder={FieldName}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                );
            }
        );
    };

    /**
     * Function that populates the columns to display switches.
     */
    const populateColumnsToDisplayOptions = () => {
        return map(
            displayColumnOptions,
            ({ label, value, defaultChecked, OrgViewOnly }: DynamicObject) => {
                if (
                    isUsingCloudImportType &&
                    includes(hiddenCloudImportFields, value)
                ) {
                    return;
                }
                if (
                    label !== "Customer custom fields"
                    ) {
                        if (!isOrgView && OrgViewOnly) {
                            return null;
                        }
                        else {
                            const displayColumnKey = `${displayColumnIndicator}${value}`;
                            const switchValue = !isUndefined(
                                get(widgetDetails, displayColumnKey)
                            )
                                ? get(widgetDetails, displayColumnKey)
                                : defaultChecked;

                            const labelUsed = replaceInstancesOfCustomerString(
                                label,
                                customerLabel,
                                isOrgView
                            );
                            return (
                                <Col
                                    key={value}
                                    span={12}
                                    className="switch-fields-container"
                                >
                                    <FormItem label={labelUsed}>
                                        {getFieldDecorator(displayColumnKey, {
                                            valuePropName: 'checked',
                                            initialValue: switchValue,
                                        })(<Switch disabled={formDisabled} />)}
                                    </FormItem>
                                </Col>
                            );
                        }
                    }
        }
        );
    };

    /**
     * Function that populates the custom Customer columns to display switches.
     */
    const populateCustomCustomerFilterColumnsToDisplayOption = () => {
        return map(customFieldsFilterList, 
            ({ Type, FieldName }: DynamicObject) => {
                if (Type == "Customer") {
                    if (isOrgView) {
                        return null;
                    }
                    else {
                        const customFieldKey = `${showCustomFieldsIndicator}${customFieldIndicator}${Type}--${FieldName}`;
                        const switchValue = !isUndefined(
                            get(widgetDetails, customFieldKey)
                        )
                            ? get(widgetDetails, customFieldKey)
                            : false;

                        const labelUsed = replaceInstancesOfCustomerString(
                            FieldName,
                            customerLabel,
                            isOrgView
                        );

                        return ( 
                                <Col
                                    key={customFieldKey}
                                    span={12}
                                    className="switch-fields-container"
                                >
                                    <FormItem label={labelUsed}>
                                        {getFieldDecorator(customFieldKey, {
                                            valuePropName: 'checked',
                                            initialValue: switchValue,
                                        })(<Switch disabled={formDisabled} />)}
                                    </FormItem>
                                </Col>
                                );
                        }
                }

            });
    }

    /**
     * Function for populating the sort by options.
     */
    const populateSortPaymentsByOption = () => {
        const option = map(paymentsSortByOptions, ({label, value}) => {
            const labelUsed = label
                .replace('Customer', capitalize(customerLabel))
                .replace('customer', toLower(customerLabel));

            return (
                <Option key={value} value={value}>
                    {labelUsed}
                </Option>
            );
        });

        const custom = map(customFieldsFilterList,
            ({
                Type,
                Number: CFNumber,
                FieldName,
            }: CompanyCustomFieldConfigure) => {
            if (Type === "Customer") {
                return (
                    <Option
                        key={Type + CFNumber + FieldName}
                        value={`${customFieldIndicator}${Type}--${FieldName}`}
                    >
                        {FieldName}
                    </Option>
                )
            }
        });

        return (
            <Row>
                <FormItem label="Sort By">
                    {getFieldDecorator('SortField', {
                        initialValue:
                            get(widgetDetails, 'SortField') ||
                            get(paymentsSortByOptions[2], 'value'),
                        rules: [
                            {
                                required: true,
                                message: 'Sort By is required!',
                            },
                        ],
                    })(
                        <SelectReadonly
                            readOnly={formDisabled}
                            style={{ width: 200 }}
                            getPopupContainer={populatePopoverContainer(
                                drawerRef
                            )}
                        >
                           {option}
                           {custom}
                        </SelectReadonly>
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('SortFieldAsc', {
                        initialValue: get(widgetDetails, 'SortFieldAsc') || false,
                        valuePropName: 'checked'
                    })(
                        <Checkbox
                            disabled={formDisabled}
                        >
                            {`Ascending`}
                        </Checkbox>
                    )}
                </FormItem>
            </Row>
        );
    };
        

    /**
     * Function for populating the row count options.
     */
    const populateRowCountOptions = () =>
        map(displayRowCountOptions, (count: number) => (
            <Option key={count} value={count}>
                {count}
            </Option>
    ));

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>Filter by payment fields</h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populatePaymentFieldsFilters()}
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>
                                    Filter by{' '}
                                    {isOrgView
                                        ? 'customer'
                                        : toLower(customerLabel)}{' '}
                                    custom fields
                                </h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateCustomFieldsFilters(
                                CUSTOM_FIELD_TYPES.CUSTOMER
                            )}
                        </Row>
                    </Col>
                </Row>
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional
                        organisationCurrenciesAll={organisationCurrenciesAll}
                    />
                )}
                <Divider />
                <Row>
                    <Col>
                        {populateSortPaymentsByOption()}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>Columns to display</h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateColumnsToDisplayOptions()}
                            {populateCustomCustomerFilterColumnsToDisplayOption()}
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <FormItem label="Number of rows (Amount)">
                            {getFieldDecorator('rowCount', {
                                initialValue:
                                    get(widgetDetails, 'rowCount') ||
                                    get(displayRowCountOptions, '0'),
                                rules: [
                                    {
                                        required: true,
                                        message: 'Row count is required!',
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: 80 }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateRowCountOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default withAccountingSystemHandler(PaymentsWidgetFields);
