/**
 * Component for populating the TaskActionManuallyAllocatePaymentPanel panel when clicking on `Action` button for Manual Allocate Payment task items.
 */

import {
    Button,
    Col,
    Form,
    Row,
    Skeleton,
    Select
} from 'antd';
import { get, map, times,filter,forEach, includes } from 'lodash';
import React, { useEffect, useState,lazy,Suspense} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import {
    getTriggersForWorkflowRequestAction
} from '../../../store/tasks/actions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { ApplicationState } from '../../../store';
import { Task,WorkflowTransition } from '../../../store/tasks/types';
import {
    manualAllocatePaymentActionOptions,
} from '../../../constants/tasksSortAndFilters';

const TaskActionRequestRemittanceAdviceContent = lazy(
    () => import('./TaskActionRequestRemittanceAdviceContent')
);
const TaskActionPaymentLinkRemittanceContent = lazy(
    () => import('./TaskActionPaymentLinkRemittanceContent')
);
const TaskActionManualAllocatePaymentDrawer = lazy(
    () => import('./TaskActionManualAllocatePaymentDrawer')
);

interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly selectedTaskId: string;
    readonly customerLabel: string;
}
const { Item: FormItem } = Form;
const { Option } = Select;

const formFields = {
    SELECT_ACTION: 'Trigger',
    COMMENT: 'Comment',
    INCLUDE_COMMENT: 'IncludeComment',
};

const TaskActionManuallyAllocatePaymentPanelOptionsContent: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    selectedTaskId,
    customerLabel
}: IProps) => {
    const [actionOptions, setActionOptions] = useState<{
        options: string[];
        workflowTransitionList: DynamicObject[];
        loading: boolean;
    }>({
        options: [],
        workflowTransitionList: [],
        loading: false,
    });

    const [nextStepInfo, setNextStepInfo] = useState<{
        workflowName: string;
        requestRemittanceAdviceVisible: boolean;
        manuallyAllocateRemittanceAdviceVisible: boolean;
        manuallyAllocatePaymentVisible: boolean;
    }>({
        workflowName: '',
        requestRemittanceAdviceVisible: false,
        manuallyAllocateRemittanceAdviceVisible: false,
        manuallyAllocatePaymentVisible: false
    });

    const tasksList: Task[] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks.data
    );

    const dispatch = useDispatch();
    const { getFieldDecorator, validateFields, resetFields } = form;

    /**
     * Function for closing the panel.
     * When `Cancel` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

     /**
     * Function for updating the `showConditions` state.
     * @param selectionObject - must conform to `showConditions` state
     */
     const updateNextStepInfo = (newValue: {}) => {
        setNextStepInfo(current => ({
            ...current,
            ...newValue,
        }));
    };

    /**
         * Function for resetting the action drawer values (state).
         * @param refreshList
         */
    const resetActionDrawerValues = () => {
        updateNextStepInfo({
            workflowName: '',
            requestRemittanceAdviceVisible: false,
            manuallyAllocatePaymentVisible: false,
            manuallyAllocateRemittanceAdviceVisible: false
        });
    };

    /**
     * Listener function if the drawer visibility changes.
     * If drawer closes, resets the field values.
     * If drawer opens, gets action options.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        } else {
            getActionOptions();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when clicking the `Next` button
     */
    const handleNextButton = () => {
        validateFields((err: any, values: DynamicObject) => {
            if (!err) {
                updateNextStepInfo({
                    workflowName: values.Trigger,
                    requestRemittanceAdviceVisible: values.Trigger === manualAllocatePaymentActionOptions.RequestRemittanceAdvice,
                    manuallyAllocatePaymentVisible: values.Trigger === manualAllocatePaymentActionOptions.ManuallyAssociatePayment,
                    manuallyAllocateRemittanceAdviceVisible: values.Trigger === manualAllocatePaymentActionOptions.ManuallyAssociateRemmitanceAdvice
                });
            }
        });
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    /**
     * Function for updating `actionOptions` state.
     * @param actionOptionsObject - must conform to `actionOptions` state
     */
    const updateActionOptions = (actionOptionsObject: {}) => {
        setActionOptions({
            ...actionOptions,
            ...actionOptionsObject,
        });
    };

    /**
     * Function for fetching the action options to be used in this panel based on the workflowId and workflow statename.
     */
    const getActionOptions = () => {
        if (selectedTaskId) {
            updateActionOptions({
                loading: true,
            });
            const record = get(
                filter(tasksList, ['Id', selectedTaskId]),
                0
            );

            dispatch(
                getTriggersForWorkflowRequestAction(
                    get(record, 'Workflow.WorkflowId'),
                    get(record, 'Workflow.StateName'),
                    (res: WorkflowTransition[]) => {
                        const options: string[] = [];
                        const workflowTransitionList: DynamicObject = {};
                        forEach(res, (r: WorkflowTransition) => {
                            const trigger = get(r, 'Trigger');
                            if (!includes(options, trigger)) {
                                options.push(trigger);
                            }
                            workflowTransitionList[trigger] = r;
                        });
                        updateActionOptions({
                            options,
                            workflowTransitionList,
                            loading: false,
                        });
                    }
                )
            );
        }
    };
    /**
     * Function to populate the loading content.
     * Shows a loading skeleton if the content is being populated (API not finished fetching data for action options).
     */
    const populatePanelContent = () => {
        if (actionOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {         
            const record = get(
                filter(tasksList, ['Id', selectedTaskId]),
                0
            );

            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <Row className="mb-16" type="flex" align="middle">
                                <Col span={24}>
                                    Choose which action to complete then click Next.
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-10" type="flex" >
                                <Col span={24}>
                                <FormItem
                                        {...formItemLayout}
                                        label="Action"
                                    >
                                        {getFieldDecorator(
                                            formFields.SELECT_ACTION,
                                            {
                                                initialValue: get(
                                                    actionOptions.options,
                                                    '0'
                                                ),
                                            }
                                        )(
                                            <Select
                                                style={{ width: '100%' }}
                                                loading={actionOptions.loading}
                                            >
                                                {map(
                                                    actionOptions.options,
                                                    (option) => (
                                                        <Option
                                                            key={option}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    <Suspense fallback={null}>
                        <div ref={null}>
                            <TaskActionRequestRemittanceAdviceContent
                                visible={nextStepInfo.requestRemittanceAdviceVisible}
                                record={record}
                                selectedTaskId={selectedTaskId}
                                panelTitle={nextStepInfo.workflowName}
                                customerLabel={customerLabel}
                                closePanel={closePanel}
                                closePaymentRemittancePanel={resetActionDrawerValues}
                            />
                            <TaskActionPaymentLinkRemittanceContent
                                visible={nextStepInfo.manuallyAllocateRemittanceAdviceVisible}
                                record={record}
                                selectedTaskId={selectedTaskId}
                                panelTitle={nextStepInfo.workflowName}
                                customerLabel={customerLabel}
                                closePanel={closePanel}
                                closePaymentRemittancePanel={resetActionDrawerValues}
                            />
                            <TaskActionManualAllocatePaymentDrawer
                                visible={nextStepInfo.manuallyAllocatePaymentVisible}
                                selectedTaskId={selectedTaskId}
                                closePanel={closePanel}
                                goBack={resetActionDrawerValues}
                                panelTitle={nextStepInfo.workflowName}
                            />
                        </div>
                    </Suspense>
                </Form>
                
            );
        }
    };

    return (
        <Row>
            <Col className="dropdown-multiline-cont">
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleNextButton}
                        >
                            Next
                        </Button>
                        <Button className="buttonGrey" onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const TaskResolveCustomerAssistanceTicketPanelContentForm = Form.create({
    name: 'task-resolve-ticket-panel-form',
})(TaskActionManuallyAllocatePaymentPanelOptionsContent);

export default withRouter(
    withDateFormatHandler(TaskResolveCustomerAssistanceTicketPanelContentForm)
);
