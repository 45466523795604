import { Col, Row, Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React from 'react';
import ReportHistoryTable from '../../components/report/ReportHistoryTable';
import { getReportDataRequestAction, getReportsRequestAction } from '../../store/report/actions';

const { Title } = Typography;

interface IProps { }

const ReportHistoryPage: React.FC<IProps> = ({ }) => {

    return (<>
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container" type="flex" justify="space-between">
                    <Col>
                        <Title level={3}>Report History</Title>
                    </Col>
                </Row>
                <div className="spacer-10" />
                <div className="spacer-10" />
                <ReportHistoryTable
                    getReportsRequestAction={getReportsRequestAction}
                    getReportDataRequestAction={getReportDataRequestAction}
                />
            </QueueAnim>
        </Col>
    </>)
};

export default ReportHistoryPage;
