/**
 * File responsible for the content when clicking `Assign to User` in management pages.
 */

import { Button, Col, Form, Modal, Row } from 'antd';
import { get, isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ticketAssignUserRequestAction } from '../../store/tickets/actions';
import { getPopoverContainer, checkIfEmailIsValid } from '../../utils/commonFunctions';
import InputAutoCompleteWithButton from '../../components/common/InputAutoCompleteWithButton';
import { ApplicationState } from '../../store';
import {
    getUserByEmailAction,
    getUserByNameAction,
} from '../../store/users/actions';
import { DynamicObject } from '../../utils/commonInterfaces';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

let listenForSaveTaskLoading: boolean = false;
const filterStateName = 'Name';
const filterQuery = 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER';
const filterSort = 'Name';
const filterResponse = 'GetUsersForCompany.CompanyUserRoles';
const filterLabelField = ['User.GivenName', 'User.FamilyName'];
const filterNameInQuery = 'Name';
const filterNameInQuerySub = 'Email';
const FormItem = Form.Item;
interface IProps {
    onSaveSuccess: () => void;
    onCancelClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    containerRef?: any;
    form: any;
    visible: boolean;
    getSelectedTicketsValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
        recordLimit: number | undefined;
    };
    companyId: string;
}
const AssignUserPanel: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const { validateFields } = props.form;
    const saveTicketDataLoading: boolean = useSelector(
        (state: ApplicationState) => state.tickets.saveTicketDataLoading
    );

    const [ticketAssignUserProperties, setTicketAssignUserProperties] = useState<{
        loading: boolean;
        fetchingUser: boolean;
        value: string;
    }>({
        loading: false,
        fetchingUser: false,
        value: '',
    });

    /**
     * Function for updating the `ticketAssignUserProperties` state.
     * @param ticketAssignUserPropertiesObject - must conform to `ticketAssignUserProperties` state
     */
    const updateTicketAssignToProperties = (ticketAssignUserPropertiesObject: {}) => {
        setTicketAssignUserProperties({
            ...ticketAssignUserProperties,
            ...ticketAssignUserPropertiesObject,
        });
    };

    /**
 * Function called when assign to changes has been saved.
 * @param values
 */
    const onSaveAssignToUserChanges = (values: DynamicObject) => {
        const { allExcept, keysToUse, filterObject } =
            props.getSelectedTicketsValues();
        dispatch(
            ticketAssignUserRequestAction({
                userObject: values,
                ticketIds: keysToUse,
                excludeTickets: allExcept,
                filter: filterObject,
                callback: assignUserResponseModal(keysToUse.length),
                companyId: props.companyId
            })
        );
    };

    /**
     * 
    * Function responsible for showing the response modal after ticket editing has finished.
    * Either success/error
    * @param idsCount - number of selected items edited, used for populating the label if plural/singular
    */
    const assignUserResponseModal =
        (idsCount: number) =>
            ({
                IsSuccess,
                Messages,
            }: {
                IsSuccess: boolean;
                Messages: string[];
            }) => {
                updateTicketAssignToProperties({ loading: false });

                if (IsSuccess) {
                    Modal.success({
                        title: 'Success',
                        content: Messages[0],
                        onOk: () => {
                            props.onSaveSuccess();
                        },
                    });
                } else {
                    let errorMessageContent: any = `Failed to update ticket${idsCount > 1 ? 's' : ''
                        } data!`;
                    if (!isEmpty(Messages)) {
                        errorMessageContent = map(
                            Messages,
                            (error: string, index: number) => (
                                <div key={index}>{error}</div>
                            )
                        );
                    }
                    Modal.error({
                        title: 'Error',
                        content: errorMessageContent
                    });
                }
            };


    /**
     * Function called when the `Save` button is clicked.
     * @param e
     */
    const handleFormSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        validateFields((err: any) => {
            if (!err) {
                const inputValue = ticketAssignUserProperties.value;
                const isValidEmail = checkIfEmailIsValid(inputValue);
                updateTicketAssignToProperties({ loading: true });

                if (isValidEmail) {
                    return dispatch(
                        getUserByEmailAction(
                            inputValue,
                            (res: DynamicObject) => {
                                if (get(res, 'IsSuccess') === true) {
                                    const userId = get(res, 'User.UserId');
                                    if (userId) {
                                        return onSaveAssignToUserChanges({
                                            AssignedUserId: userId
                                        });
                                    }
                                }
                            }
                        )
                    );
                }

                dispatch(
                    getUserByNameAction(inputValue, (res: DynamicObject) => {
                        if (get(res, 'IsSuccess') === true) {
                            const userId = get(res, 'User.UserId');
                            if (userId) {
                                return onSaveAssignToUserChanges({
                                    AssignedUserId: userId
                                });
                            }
                        }

                        updateTicketAssignToProperties({ loading: false });
                        let errorMessageContent: any = !isValidEmail ? (
                            <div>Email entered is not valid!</div>
                        ) : (
                            <div>
                                <div>Unable to find a matching user!</div>
                                <div>
                                    Please enter in the name again and
                                    confirm the correct spelling.
                                </div>
                            </div>
                        );
                        const messages = get(res, 'Messages');
                        if (!isEmpty(messages)) {
                            errorMessageContent = map(
                                messages,
                                (error: string, index: number) => (
                                    <div key={index}>{error}</div>
                                )
                            );
                        }

                        Modal.error({
                            title: 'Error',
                            content: errorMessageContent,
                            getContainer: () =>
                                getPopoverContainer(props.containerRef),
                        });
                    })
                );
            }
        });
    };

    /**
     * Function for handling the loading modal based on redux state and component state.
     */
    const loadingFromReduxListener = () => {
        if (
            ticketAssignUserProperties.loading &&
            !saveTicketDataLoading &&
            listenForSaveTaskLoading
        ) {
            listenForSaveTaskLoading = false;
            updateTicketAssignToProperties({ loading: false });
        }
        if (ticketAssignUserProperties.loading && saveTicketDataLoading) {
            listenForSaveTaskLoading = true;
        }
    };

    useEffect(loadingFromReduxListener, [
        ticketAssignUserProperties.loading,
        saveTicketDataLoading
    ]);

    /**
     * Function for populating the loading message.
     */
    const populateLoadingMessage = () => {
        const { keysToUse } = props.getSelectedTicketsValues();
        return `Please wait while assigning ticket${keysToUse.length > 1 ? 's' : ''
            } to user. . .`;
    };

    const isSaveBtnDisabled = isEmpty(ticketAssignUserProperties.value);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
            md: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
            md: { span: 21 },
        },
    };

    if (!props.visible) {
        return null;
    }

    return (
        <Form className="form-inline-mb-8" {...formItemLayout}>
            <Row type="flex" align="middle">
                <Col span={24}>
                    <FormItem label="User">
                        <InputAutoCompleteWithButton
                            updateField={(value: string) => {
                                updateTicketAssignToProperties({ value });
                            }}
                            stateValue={ticketAssignUserProperties.value}
                            queryName={filterQuery}
                            filterField={filterStateName}
                            queryFilterName={filterNameInQuery}
                            sortField={filterSort}
                            responseName={filterResponse}
                            labelField={filterLabelField}
                            hasNoOkButton
                            filterSubChecking={checkIfEmailIsValid}
                            queryFilterNameSub={filterNameInQuerySub}
                            loading={ticketAssignUserProperties.loading}
                        />
                    </FormItem>
                </Col>
            </Row>
            {ticketAssignUserProperties.loading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Assigning to user"
                        modalVisible={ticketAssignUserProperties.loading}
                        displayMessage={populateLoadingMessage()}
                    />
                </Suspense>
            )}
            <div className="mt-10 ta-right">
                <Button
                    className="mr-10"
                    onClick={handleFormSave}
                    disabled={isSaveBtnDisabled}
                    type="primary"
                >
                    Assign
                </Button>
                <Button className="buttonGrey" onClick={props.onCancelClick}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

const AssignUserPanelForm = Form.create({
    name: 'assign-user-panel-form',
})(AssignUserPanel);

export default withRouter(AssignUserPanelForm);
