export enum RolesActionTypes {
    GET_ALL_ROLES_REQUEST = '@@role/GET_ALL_ROLES_REQUEST',
    GET_ALL_ROLES_SUCCESS = '@@role/GET_ALL_ROLES_SUCCESS',
    GET_ROLE_PERMISSIONS_SUCCESS = '@@role/GET_ROLE_PERMISSIONS_SUCCESS',
}

export interface RolesState {
    readonly loading: boolean;
    readonly allRoles: Role[];
    readonly rolePermissions: {};
}

export interface Role {
    readonly RoleId: string;
    readonly Name: string;
}
