import {
    filter,
    find,
    forEach,
    get,
    includes,
    isEmpty,
    map,
    orderBy,
    isNumber,
    set
} from 'lodash';
import React from 'react';
import FontAwesome from '../components/common/FontAwesome';
import CurrentATBWidget from '../components/dashboard/CurrentATBWidget';
import CurrentATBWidgetFields from '../components/dashboard/CurrentATBWidgetFields';
import InvoicingSettlementsWidget from '../components/dashboard/InvoicingSettlementsWidget';
import InvoicingSettlementsWidgetFields from '../components/dashboard/InvoicingSettlementsWidgetFields';
import NotificationSuccessWidget from '../components/dashboard/NotificationSuccessWidget';
import NotificationSuccessWidgetFields from '../components/dashboard/NotificationSuccessWidgetFields';
import NotificationsWidget from '../components/dashboard/NotificationsWidget';
import NotificationsWidgetFields from '../components/dashboard/NotificationsWidgetFields';
import OpenInvoicesWidget from '../components/dashboard/OpenInvoicesWidget';
import OpenInvoicesWidgetFields from '../components/dashboard/OpenInvoicesWidgetFields';
import OpenSupportTicketsWidget from '../components/dashboard/OpenSupportTicketsWidget';
import OpenSupportTicketsWidgetFields from '../components/dashboard/OpenSupportTicketsWidgetFields';
import {
    allOptionsSelectedKey,
    commonOrgFormFields,
} from '../components/dashboard/organisation/OrganisationWidgetCommonFilters';
import SalesWidget from '../components/dashboard/SalesWidget';
import SalesWidgetFields from '../components/dashboard/SalesWidgetFields';
import TasksWidget from '../components/dashboard/TasksWidget';
import TasksWidgetFields from '../components/dashboard/TasksWidgetFields';
import TasksForUsersWidget from '../components/dashboard/TasksForUsersWidget';
import TasksForUsersWidgetFields from '../components/dashboard/TasksForUsersWidgetFields';
import TicketsWidget from '../components/dashboard/TicketsWidget';
import TicketsWidgetFields from '../components/dashboard/TicketsWidgetFields';
import TicketsForUsersWidget from '../components/dashboard/TicketsForUsersWidget';
import TicketsForUsersWidgetFields from '../components/dashboard/TicketsForUsersWidgetFields';
import TopCustomersWidget from '../components/dashboard/TopCustomersWidget';
import TopCustomersWidgetFields from '../components/dashboard/TopCustomersWidgetFields';
import TopInvoicesWidget from '../components/dashboard/TopInvoicesWidget';
import TopInvoicesWidgetFields from '../components/dashboard/TopInvoicesWidgetFields';
import TotalOwedWidget from '../components/dashboard/TotalOwedWidget';
import TotalOwedWidgetFields from '../components/dashboard/TotalOwedWidgetFields';
import TopCreditsWidget from '../components/dashboard/TopCreditsWidget';
import TopCreditsWidgetFields from '../components/dashboard/TopCreditsWidgetFields';
import RecentChangesWidget from '../components/dashboard/RecentChangesWidget';
import RecentChangesWidgetFields from '../components/dashboard/RecentChangesWidgetFields';
import ChangeStatisticsWidget from '../components/dashboard/ChangeStatisticsWidget';
import ChangeStatisticsWidgetFields from '../components/dashboard/ChangeStatisticsWidgetFields';
import CustomersSettlementWidget from '../components/dashboard/CustomersSettlementWidget';
import CustomersSettlementWidgetFields from '../components/dashboard/CustomersSettlementWidgetFields';
import { store } from '../index';
import { Company } from '../store/companies/types';
import { DynamicObject } from '../utils/commonInterfaces';
import CommunicationDeliveryWidget from '../components/dashboard/CommunicationDeliveryWidget';
import CommunicationDeliveryWidgetFields from '../components/dashboard/CommunicationDeliveryWidgetFields';
import PaymentsWidgetFields from '../components/dashboard/PaymentsWidgetFields';
import PaymentsWidget from '../components/dashboard/PaymentsWidget';
import PaymentsAllocationWidgetFields from '../components/dashboard/PaymentsAllocationWidgetFields';
import PaymentsAllocationWidget from '../components/dashboard/PaymentsAllocationWidget';
import CashflowForecastingWidget from '../components/dashboard/CashflowForecastingWidget';
import CashflowForecastingWidgetField from '../components/dashboard/CashflowForecastingWidgetField';
import DailySalesOutWidget from '../components/dashboard/DailySalesOutWidget';
import DailySalesOutWidgetFields from '../components/dashboard/DailySalesOutWidgetFields';
import TaskActionedPerUserWidget from '../components/dashboard/TaskActionedPerUserWidget';
import TaskActionedPerUserWidgetField from '../components/dashboard/TaskActionedPerUserWidgetField';

export const WIDGET_TYPES = {
    TOP_INVOICES: {
        type: 'TOP_INVOICES',
        name: 'Top invoices',
        title: 'Top invoices',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
        // isBounded: undefined,
        // isDraggable: undefined,
        // isResizable: undefined,
        // maxH: undefined,
        // maxW: undefined,
        // resizeHandles: undefined,
    },
    TOP_CREDITS: {
        type: 'TOP_CREDITS',
        name: 'Top credits',
        title: 'Top credits',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    TOP_CUSTOMERS: {
        type: 'TOP_CUSTOMERS',
        name: 'Top customers',
        title: 'Top customers',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    RECENT_CHANGES: {
        type: 'RECENT_CHANGES',
        name: 'Recent changes',
        title: 'Recent changes',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    CUSTOMERS_SETTLEMENT: {
        type: 'CUSTOMERS_SETTLEMENT',
        name: 'Customers settlement timeframe',
        title: 'Customers settlement timeframe',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    TASKS: {
        type: 'TASKS',
        name: 'Tasks',
        title: 'Tasks',
        w: 4,
        minW: 4,
        h: 4,
        minH: 4,
        maxH: 7,
    },
    TASKS_FOR_USERS: {
        type: 'TASKS_FOR_USERS',
        name: 'Active Tasks per user',
        title: 'Active Tasks per user',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
        maxH: 7,
    },
    TASK_COUNT_ACTIONED_BY_USER: {
        type: 'TASK_COUNT_ACTIONED_BY_USER',
        name: 'Tasks Actioned By Users',
        title: 'Tasks Actioned By Users',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
        maxH: 7,
    },
    OPEN_SUPPORT_TICKETS: {
        type: 'OPEN_SUPPORT_TICKETS',
        name: 'Assistance/Tickets',
        title: 'Assistance/Tickets',
        w: 2,
        minW: 2,
        h: 2,
        minH: 2,
        maxH: 6,
    },
    SALES: {
        type: 'SALES',
        name: 'Sales',
        title: 'Sales',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    CHANGE_STATISTICS: {
        type: 'CHANGE_STATISTICS',
        name: 'Statistics of changes',
        title: 'Statistics of changes',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    NOTIFICATION_SUCCESS: {
        type: 'NOTIFICATION_SUCCESS',
        name: 'Notification success',
        title: 'Notification success',
        w: 6,
        minW: 6,
        h: 5,
        minH: 5,
    },
    INVOICING_AND_SETTLEMENTS: {
        type: 'INVOICING_AND_SETTLEMENTS',
        name: 'Invoicing and settlements',
        title: 'Invoicing and settlements',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    TICKETS: {
        type: 'TICKETS',
        name: 'Tickets',
        title: 'Tickets',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    TICKETS_FOR_USERS: {
        type: 'TICKETS_FOR_USERS',
        name: 'Users assigned to tickets',
        title: 'Users assigned to tickets',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    TOTAL_OWED: {
        type: 'TOTAL_OWED',
        name: 'Total owed',
        title: 'Total owed',
        w: 2,
        minW: 2,
        h: 2,
        minH: 2,
        maxH: 6,
    },
    NOTIFICATIONS: {
        type: 'NOTIFICATIONS',
        name: 'Notifications',
        title: 'Notifications',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    CURRENT_ATB: {
        type: 'CURRENT_ATB',
        name: 'Current ATB',
        title: 'Current ATB',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    OPEN_INVOICES: {
        type: 'OPEN_INVOICES',
        name: 'Open invoices',
        title: 'Open invoices',
        w: 2,
        minW: 2,
        h: 2,
        minH: 2,
        maxH: 6,
    },
    COMMUNICATION_DELIVERY: {
        type: 'COMMUNICATION_DELIVERY',
        name: 'Communication Delivery',
        title: 'Communication Delivery',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    PAYMENTS: {
        type: 'PAYMENTS',
        name: 'Payments - General',
        title: 'Payments - General',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    PAYMENTS_ALLOCATION: {
        type: 'PAYMENTS_ALLOCATION',
        name: 'Payments - Allocation',
        title: 'Payments - Allocation',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
        supportCashAllocationOnly: true
    },
    CASHFLOW_FORECASTING: {
        type: 'CASHFLOW_FORECASTING',
        name: 'Cashflow Forecasting',
        title: 'Cashflow Forecasting',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    DAILY_SALES_OUT: {
        type: 'DAILY_SALES_OUT',
        name: 'Daily Sales Out',
        title: 'Daily Sales Out',
        w: 2,
        minW: 2,
        h: 2,
        minH: 2,
        maxH: 6,
    }
    
};

export const ORGANISATION_WIDGET_TYPES = {
    ORGANISATION_TOP_INVOICES: {
        type: 'ORGANISATION_TOP_INVOICES',
        name: 'Top invoices',
        title: 'Top invoices',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_TOP_CREDITS: {
        type: 'ORGANISATION_TOP_CREDITS',
        name: 'Top credits',
        title: 'Top credits',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_TOP_CUSTOMERS: {
        type: 'ORGANISATION_TOP_CUSTOMERS',
        name: 'Top customers',
        title: 'Top customers',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_RECENT_CHANGES: {
        type: 'ORGANISATION_RECENT_CHANGES',
        name: 'Recent changes',
        title: 'Recent changes',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_CUSTOMERS_SETTLEMENT: {
        type: 'ORGANISATION_CUSTOMERS_SETTLEMENT',
        name: 'Customers settlement timeframe',
        title: 'Customers settlement timeframe',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_TASKS: {
        type: 'ORGANISATION_TASKS',
        name: 'Tasks',
        title: 'Tasks',
        w: 4,
        minW: 4,
        h: 4,
        minH: 4,
        maxH: 7,
    },
    ORGANISATION_TASKS_FOR_USERS: {
        type: 'ORGANISATION_TASKS_FOR_USERS',
        name: 'Active Tasks per user',
        title: 'Active Tasks per user',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
        maxH: 7,
    },
    ORGANISATION_TASK_COUNT_ACTIONED_BY_USER: {
        type: 'TASK_COUNT_ACTIONED_BY_USER',
        name: 'Tasks Actioned By Users',
        title: 'Tasks Actioned By Users',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
        maxH: 7,
    },
    ORGANISATION_OPEN_SUPPORT_TICKETS: {
        type: 'ORGANISATION_OPEN_SUPPORT_TICKETS',
        name: 'Assistance/Tickets',
        title: 'Assistance/Tickets',
        w: 2,
        minW: 2,
        h: 2,
        minH: 2,
        maxH: 6,
    },
    ORGANISATION_SALES: {
        type: 'ORGANISATION_SALES',
        name: 'Sales',
        title: 'Sales',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    ORGANISATION_CHANGE_STATISTICS: {
        type: 'ORGANISATION_CHANGE_STATISTICS',
        name: 'Statistics of changes',
        title: 'Statistics of changes',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_NOTIFICATION_SUCCESS: {
        type: 'ORGANISATION_NOTIFICATION_SUCCESS',
        name: 'Notification success',
        title: 'Notification success',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_INVOICING_AND_SETTLEMENTS: {
        type: 'ORGANISATION_INVOICING_AND_SETTLEMENTS',
        name: 'Invoicing and settlements',
        title: 'Invoicing and settlements',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    ORGANISATION_TICKETS: {
        type: 'ORGANISATION_TICKETS',
        name: 'Tickets',
        title: 'Tickets',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    ORGANISATION_TICKETS_FOR_USERS: {
        type: 'ORGANISATION_TICKETS_FOR_USERS',
        name: 'Users assigned to tickets',
        title: 'Users assigned to tickets',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    ORGANISATION_TOTAL_OWED: {
        type: 'ORGANISATION_TOTAL_OWED',
        name: 'Total owed',
        title: 'Total owed',
        w: 2,
        minW: 2,
        h: 2,
        minH: 2,
        maxH: 6,
    },
    ORGANISATION_NOTIFICATIONS: {
        type: 'ORGANISATION_NOTIFICATIONS',
        name: 'Notifications',
        title: 'Notifications',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    ORGANISATION_CURRENT_ATB: {
        type: 'ORGANISATION_CURRENT_ATB',
        name: 'Current ATB',
        title: 'Current ATB',
        w: 6,
        minW: 6,
        h: 4,
        minH: 4,
    },
    ORGANISATION_OPEN_INVOICES: {
        type: 'ORGANISATION_OPEN_INVOICES',
        name: 'Open invoices',
        title: 'Open invoices',
        w: 2,
        minW: 2,
        h: 2,
        minH: 2,
        maxH: 6,
    },
    ORGANISATION_COMMUNICATION_DELIVERY: {
        type: 'COMMUNICATION_DELIVERY',
        name: 'Communication Delivery',
        title: 'Communication Delivery',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_PAYMENTS: {
        type: 'ORGANISATION_PAYMENTS',
        name: 'Payments - General',
        title: 'Payments - General',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_PAYMENTS_ALLOCATION: {
        type: 'ORGANISATION_PAYMENTS_ALLOCATION',
        name: 'Payments - Allocation',
        title: 'Payments - Allocation',
        w: 6,
        minW: 4,
        h: 4,
        minH: 4,
        supportCashAllocationOnly: true
    },
    ORGANISATION_CASHFLOW_FORECASTING: {
        type: 'ORGANISATION_CASHFLOW_FORECASTING',
        name: 'Cashflow Forecasting',
        title: 'Cashflow Forecasting',
        w: 10,
        minW: 4,
        h: 4,
        minH: 4,
    },
    ORGANISATION_DAILY_SALES_OUT: {
        type: 'DAILY_SALES_OUT',
        name: 'Daily Sales Out',
        title: 'Daily Sales Out',
        w: 2,
        minW: 2,
        h: 2,
        minH: 2,
        maxH: 6,
    }
};

export const widgetsDescription = {
    TOP_INVOICES: 'Displays top overdue invoices.',
    TOP_CREDITS: 'Displays top available credits.',
    TOP_CUSTOMERS:
        'Displays top customers based on total owed. Credits are taken into account.',
    RECENT_CHANGES: 'Displays recent changes of system resources.',
    CUSTOMERS_SETTLEMENT: 'Based on customers and their average settlement timeframe.',
    TASKS: 'Distribution of the types of active tasks.',
    TASKS_FOR_USERS: 'Based on users assigned to active tasks.',
    TASK_COUNT_ACTIONED_BY_USER: 'Based on the total number of tasks actioned by a user',
    OPEN_SUPPORT_TICKETS:
        'Displays the current number of open assistance/tickets.',
    SALES: 'Display sum of paid and owed amount based on create date or due date of the date range selected.',
    CHANGE_STATISTICS: 'Display statistics of changes made by user or system.',
    NOTIFICATION_SUCCESS:
        'Based last reminder for open invoice, invoice total, invoice paid.',
    INVOICING_AND_SETTLEMENTS:
        'Based on invoice create date and invoice settle date, and total invoice amount.',
    TICKETS: 'Based on ticket create date, ticket resolution date.',
    TICKETS_FOR_USERS: 'Based on users assigned to tickets.',
    TOTAL_OWED: 'Displays the current number of owed invoices.',
    NOTIFICATIONS:
        'Based on the number of notifications sent out, and the type.',
    CURRENT_ATB:
        'Based on current company ATB (including all open invoices and credits).',
    OPEN_INVOICES: 'Displays the current number of open invoices.',
    COMMUNICATION_DELIVERY: 'Based on delivery status for each communication sent.',
    PAYMENTS: 'Display the list of payments.',
    PAYMENTS_ALLOCATION: 'Based on payments and allocated amounts.',
    CASHFLOW_FORECASTING: 'Displays the total invoice due amount per day per customer. ',
    DAILY_SALES_OUT: 'Based on paid amounts and original amounts.',

    // Organisation section
    ORGANISATION_TOTAL_OWED: 'Displays the current number of owed invoices.',
    ORGANISATION_NOTIFICATION_SUCCESS:
        'Based last reminder for open invoice, invoice total, invoice paid.',
    ORGANISATION_SALES:
        'Display sum of paid and owed amount based on create date or due date of the date range selected.',
    ORGANISATION_CHANGE_STATISTICS: 'Display statistics of changes made by user or system.',
    ORGANISATION_INVOICING_AND_SETTLEMENTS:
        'Based on invoice create date and invoice settle date, and total invoice amount.',
    ORGANISATION_NOTIFICATIONS:
        'Based on the number of notifications sent out, and the type.',
    ORGANISATION_CURRENT_ATB:
        'Based on current company ATB (including all open invoices and credits).',
    ORGANISATION_TOP_INVOICES: 'Displays top overdue invoices.',
    ORGANISATION_TOP_CREDITS: 'Displays top available credits.',
    ORGANISATION_TOP_CUSTOMERS:
        'Displays top customers based on total owed. Credits are taken into account.',
    ORGANISATION_RECENT_CHANGES: 'Displays recent changes of system resources.',
    ORGANISATION_CUSTOMERS_SETTLEMENT: 'Based on customers and their average settlement timeframe.',
    ORGANISATION_TASKS: 'Distribution of the types of active tasks.',
    ORGANISATION_TASKS_FOR_USERS: 'Based on users assigned to active tasks.',
    ORGANISATION_TASK_COUNT_ACTIONED_BY_USER: 'Based on the total number of tasks actioned by a user',
    ORGANISATION_OPEN_SUPPORT_TICKETS:
        'Displays the current number of open assistance/tickets.',
    ORGANISATION_TICKETS:
        'Based on ticket create date, ticket resolution date.',
    ORGANISATION_TICKETS_FOR_USERS: 'Based on users assigned to tickets.',
    ORGANISATION_OPEN_INVOICES: 'Displays the current number of open invoices.',
    ORGANISATION_COMMUNICATION_DELIVERY: 'Based on delivery status for each communication sent.',
    ORGANISATION_PAYMENTS: 'Display the list of payments.',
    ORGANISATION_PAYMENTS_ALLOCATION: 'Based on payments and allocated amounts.',
    ORGANISATION_CASHFLOW_FORECASTING: 'Displays the total invoice due amount per day per customer. ',
    ORGANISATION_DAILY_SALES_OUT: 'Based on paid amounts and original amounts.',
};

const widgetIcons = {
    TABLE: <FontAwesome icon={['fas', 'table']} />,
    PIE: <FontAwesome icon={['fas', 'chart-pie']} />,
    BOX_NUMBER: (
        <div className="fa-stack box-number-icon-container">
            <FontAwesome icon={['fas', 'square']} />
            <div className="fa-stack-1x box-widget-number">0</div>
        </div>
    ),
    BAR_CHART: <FontAwesome icon={['far', 'chart-bar']} />,
    TABLE_PIE: (
        <div className="fa-layers fa-fw widget-stack-icons-container">
            <FontAwesome icon={['fas', 'table']} />
            <FontAwesome icon={['fas', 'chart-pie']} transform="shrink-6" />
        </div>
    ),
};

export const WIDGET_TYPE_COMPONENTS: any = {
    TOP_INVOICES: {
        widget: TopInvoicesWidget,
        fields: TopInvoicesWidgetFields,
        icon: widgetIcons.TABLE,
    },
    TOP_CREDITS: {
        widget: TopCreditsWidget,
        fields: TopCreditsWidgetFields,
        icon: widgetIcons.TABLE,
    },
    TOP_CUSTOMERS: {
        widget: TopCustomersWidget,
        fields: TopCustomersWidgetFields,
        icon: widgetIcons.TABLE,
    },
    RECENT_CHANGES: {
        widget: RecentChangesWidget,
        fields: RecentChangesWidgetFields,
        icon: widgetIcons.TABLE,
    },
    CUSTOMERS_SETTLEMENT: {
        widget: CustomersSettlementWidget,
        fields: CustomersSettlementWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    TASKS: {
        widget: TasksWidget,
        fields: TasksWidgetFields,
        icon: widgetIcons.PIE,
    },
    TASKS_FOR_USERS: {
        widget: TasksForUsersWidget,
        fields: TasksForUsersWidgetFields,
        icon: widgetIcons.PIE,
    },
    TASK_COUNT_ACTIONED_BY_USER: {
        widget: TaskActionedPerUserWidget,
        fields: TaskActionedPerUserWidgetField,
        icon: widgetIcons.PIE,
    },
    OPEN_SUPPORT_TICKETS: {
        widget: OpenSupportTicketsWidget,
        fields: OpenSupportTicketsWidgetFields,
        icon: widgetIcons.BOX_NUMBER,
        downloadColor: 'white'
    },
    SALES: {
        widget: SalesWidget,
        fields: SalesWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    CHANGE_STATISTICS: {
        widget: ChangeStatisticsWidget,
        fields: ChangeStatisticsWidgetFields,
        icon: widgetIcons.PIE,
    },
    NOTIFICATION_SUCCESS: {
        widget: NotificationSuccessWidget,
        fields: NotificationSuccessWidgetFields,
        icon: widgetIcons.TABLE_PIE,
    },
    INVOICING_AND_SETTLEMENTS: {
        widget: InvoicingSettlementsWidget,
        fields: InvoicingSettlementsWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    TICKETS: {
        widget: TicketsWidget,
        fields: TicketsWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    TICKETS_FOR_USERS: {
        widget: TicketsForUsersWidget,
        fields: TicketsForUsersWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    TOTAL_OWED: {
        widget: TotalOwedWidget,
        fields: TotalOwedWidgetFields,
        icon: widgetIcons.BOX_NUMBER,
        downloadColor: 'white'
    },
    NOTIFICATIONS: {
        widget: NotificationsWidget,
        fields: NotificationsWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    CURRENT_ATB: {
        widget: CurrentATBWidget,
        fields: CurrentATBWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    OPEN_INVOICES: {
        widget: OpenInvoicesWidget,
        fields: OpenInvoicesWidgetFields,
        icon: widgetIcons.BOX_NUMBER,
        downloadColor: 'white'
    },
    COMMUNICATION_DELIVERY: {
        widget: CommunicationDeliveryWidget,
        fields: CommunicationDeliveryWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    PAYMENTS: {
        widget: PaymentsWidget,
        fields: PaymentsWidgetFields,
        icon: widgetIcons.TABLE,
    },
    PAYMENTS_ALLOCATION: {
        widget: PaymentsAllocationWidget,
        fields: PaymentsAllocationWidgetFields,
        icon: widgetIcons.TABLE,
    },
    CASHFLOW_FORECASTING : {
        widget: CashflowForecastingWidget,
        fields: CashflowForecastingWidgetField,
        icon: widgetIcons.TABLE
    },
    DAILY_SALES_OUT: {
        widget: DailySalesOutWidget,
        fields: DailySalesOutWidgetFields,
        icon: widgetIcons.BOX_NUMBER,
        downloadColor: 'white'
    },

    // Organisation section
    ORGANISATION_TOTAL_OWED: {
        widget: TotalOwedWidget,
        fields: TotalOwedWidgetFields,
        icon: widgetIcons.BOX_NUMBER,
        downloadColor: 'white'
    },
    ORGANISATION_NOTIFICATION_SUCCESS: {
        widget: NotificationSuccessWidget,
        fields: NotificationSuccessWidgetFields,
        icon: widgetIcons.TABLE_PIE,
    },
    ORGANISATION_SALES: {
        widget: SalesWidget,
        fields: SalesWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    ORGANISATION_CHANGE_STATISTICS: {
        widget: ChangeStatisticsWidget,
        fields: ChangeStatisticsWidgetFields,
        icon: widgetIcons.PIE,
    },
    ORGANISATION_INVOICING_AND_SETTLEMENTS: {
        widget: InvoicingSettlementsWidget,
        fields: InvoicingSettlementsWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    ORGANISATION_NOTIFICATIONS: {
        widget: NotificationsWidget,
        fields: NotificationsWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    ORGANISATION_CURRENT_ATB: {
        widget: CurrentATBWidget,
        fields: CurrentATBWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    ORGANISATION_TOP_INVOICES: {
        widget: TopInvoicesWidget,
        fields: TopInvoicesWidgetFields,
        icon: widgetIcons.TABLE,
    },
    ORGANISATION_TOP_CREDITS: {
        widget: TopCreditsWidget,
        fields: TopCreditsWidgetFields,
        icon: widgetIcons.TABLE,
    },
    ORGANISATION_TOP_CUSTOMERS: {
        widget: TopCustomersWidget,
        fields: TopCustomersWidgetFields,
        icon: widgetIcons.TABLE,
    },
    ORGANISATION_RECENT_CHANGES: {
        widget: RecentChangesWidget,
        fields: RecentChangesWidgetFields,
        icon: widgetIcons.TABLE,
    },
    ORGANISATION_CUSTOMERS_SETTLEMENT: {
        widget: CustomersSettlementWidget,
        fields: CustomersSettlementWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    ORGANISATION_TASKS: {
        widget: TasksWidget,
        fields: TasksWidgetFields,
        icon: widgetIcons.PIE,
    },
    ORGANISATION_TASKS_FOR_USERS: {
        widget: TasksForUsersWidget,
        fields: TasksForUsersWidgetFields,
        icon: widgetIcons.PIE,
    },
    ORGANISATION_TASK_COUNT_ACTIONED_BY_USER: {
        widget: TaskActionedPerUserWidget,
        fields: TaskActionedPerUserWidgetField,
        icon: widgetIcons.PIE,
    },
    ORGANISATION_OPEN_SUPPORT_TICKETS: {
        widget: OpenSupportTicketsWidget,
        fields: OpenSupportTicketsWidgetFields,
        icon: widgetIcons.BOX_NUMBER,
        downloadColor: 'white'
    },
    ORGANISATION_TICKETS: {
        widget: TicketsWidget,
        fields: TicketsWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    ORGANISATION_TICKETS_FOR_USERS: {
        widget: TicketsForUsersWidget,
        fields: TicketsForUsersWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    ORGANISATION_OPEN_INVOICES: {
        widget: OpenInvoicesWidget,
        fields: OpenInvoicesWidgetFields,
        icon: widgetIcons.BOX_NUMBER,
        downloadColor: 'white'
    },
    ORGANISATION_COMMUNICATION_DELIVERY: {
        widget: CommunicationDeliveryWidget,
        fields: CommunicationDeliveryWidgetFields,
        icon: widgetIcons.BAR_CHART,
    },
    ORGANISATION_PAYMENTS: {
        widget: PaymentsWidget,
        fields: PaymentsWidgetFields,
        icon: widgetIcons.TABLE,
    },
    ORGANISATION_PAYMENTS_ALLOCATION: {
        widget: PaymentsAllocationWidget,
        fields: PaymentsAllocationWidgetFields,
        icon: widgetIcons.TABLE,
    },
    ORGANISATION_CASHFLOW_FORECASTING  : {
        widget: CashflowForecastingWidget,
        fields: CashflowForecastingWidgetField,
        icon: widgetIcons.TABLE
    },
    ORGANISATION_DAILY_SALES_OUT: {
        widget: DailySalesOutWidget,
        fields: DailySalesOutWidgetFields,
        icon: widgetIcons.BOX_NUMBER,
        downloadColor: 'white'
    },

};

export const notQueryRelatedKeys = [
    'h',
    'i',
    'minH',
    'minW',
    'moved',
    'static',
    'maxH',
    'maxW',
    'title',
    'type',
    'w',
    'x',
    'y',
];

export const widgetDateRangeValues = {
    SENT_ANYTIME: 'SentAnytime',
    THIS_CALENDAR_YEAR: 'ThisCalendarYear',
    LAST_CALENDAR_YEAR: 'LastCalendarYear',
    LAST_SIX_MONTHS: 'LastSixMonths',
    THIS_FINANCIAL_YEAR_AU: 'ThisFinancialYearAU',
    LAST_FINANCIAL_YEAR_AU: 'LastFinancialYearAU',
    CUSTOM_DATE_RANGE: 'CustomDateRange',
};
export const salesDateRangeOptions = [
    {
        label: 'This calendar year',
        value: widgetDateRangeValues.THIS_CALENDAR_YEAR,
    },
    {
        label: 'Last calendar year',
        value: widgetDateRangeValues.LAST_CALENDAR_YEAR,
    },
    {
        label: 'This financial year (AU)',
        value: widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU,
    },
    {
        label: 'Last financial year (AU)',
        value: widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU,
    },
    {
        label: 'Custom date range',
        value: widgetDateRangeValues.CUSTOM_DATE_RANGE,
    },
];

export const salesDateRangeOptionsComplete = [
    {
        label: 'This calendar year',
        value: widgetDateRangeValues.THIS_CALENDAR_YEAR,
    },
    {
        label: 'Last calendar year',
        value: widgetDateRangeValues.LAST_CALENDAR_YEAR,
    },
    {
        label: 'Last 6 months',
        value: widgetDateRangeValues.LAST_SIX_MONTHS,
    },
    {
        label: 'This financial year (AU)',
        value: widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU,
    },
    {
        label: 'Last financial year (AU)',
        value: widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU,
    },
    {
        label: 'Custom date range',
        value: widgetDateRangeValues.CUSTOM_DATE_RANGE,
    },
];

export const salesDisplayOptionValues = {
    BOTH: 'Both',
    INVOICE: 'Invoice',
    CREDIT: 'Credit',
};
export const salesDisplayOptions = [
    {
        label: 'Both',
        value: salesDisplayOptionValues.BOTH,
    },
    {
        label: 'Invoice',
        value: salesDisplayOptionValues.INVOICE,
    },
    {
        label: 'Credit',
        value: salesDisplayOptionValues.CREDIT,
    },
];

export const salesGroupingOptions = [
    { label: 'Monthly', value: true },
    { label: 'Weekly', value: false },
];

export const salesViewOptions = [
    { label: 'Create date', value: true },
    { label: 'Due date', value: false },
];

export const currentATBViewOptions = [
    { label: 'Create date', value: true },
    { label: 'Due date', value: false },
];

export const dashboardRowHeight = 80;

/**
 * Notification success
 */

export const notificationSuccessDateRangeOptions = [
    {
        label: 'Sent anytime',
        value: widgetDateRangeValues.SENT_ANYTIME,
    },
    {
        label: 'This calendar year',
        value: widgetDateRangeValues.THIS_CALENDAR_YEAR,
    },
    {
        label: 'Last calendar year',
        value: widgetDateRangeValues.LAST_CALENDAR_YEAR,
    },
    {
        label: 'This financial year (AU)',
        value: widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU,
    },
    {
        label: 'Last financial year (AU)',
        value: widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU,
    },
    {
        label: 'Custom date range',
        value: widgetDateRangeValues.CUSTOM_DATE_RANGE,
    },
];

export const widgetDisplayTypeValues = {
    TABLE: 'Table',
    PIE_CHART: 'Pie chart',
    BAR_CHART: 'Bar chart',
    LINE_CHART: 'Line chart'
};

export const chartWidgetDisplayTypes = [
    {
        label: 'Table',
        value: widgetDisplayTypeValues.TABLE,
    },
    {
        label: 'Pie chart',
        value: widgetDisplayTypeValues.PIE_CHART,
    },
    {
        label: 'Bar chart',
        value: widgetDisplayTypeValues.BAR_CHART,
    }
];

export const chartWidgetDisplayNoPieTypes = [
    {
        label: 'Table',
        value: widgetDisplayTypeValues.TABLE,
    },
    {
        label: 'Bar chart',
        value: widgetDisplayTypeValues.BAR_CHART,
    }
];


export const historicalChartWidgetDisplayTypes = [
    {
        label: 'Table',
        value: widgetDisplayTypeValues.TABLE,
    },
    {
        label: 'Pie chart',
        value: widgetDisplayTypeValues.PIE_CHART,
    },
    {
        label: 'Bar chart',
        value: widgetDisplayTypeValues.BAR_CHART,
    },
    {
        label: 'Line chart',
        value: widgetDisplayTypeValues.LINE_CHART,
    }
];


export const FLEX_CONTAINER_WIDGETS = [
    'OPEN_SUPPORT_TICKETS',
    'TASKS',
    'TASKS_FOR_USERS',
    'TASK_COUNT_ACTIONED_BY_USER',
    'SALES',
    'CHANGE_STATISTICS',
    'INVOICING_AND_SETTLEMENTS',
    'TICKETS',
    'TICKETS_FOR_USERS',
    'TOTAL_OWED',
    'NOTIFICATIONS',
    'CURRENT_ATB',
    'OPEN_INVOICES',
    'COMMUNICATION_DELIVERY',
    'CUSTOMERS_SETTLEMENT',
    'DAILY_SALES_OUT',

    //Organisation section
    'ORGANISATION_TOTAL_OWED',
    'ORGANISATION_SALES',
    'ORGANISATION_CHANGE_STATISTICS',
    'ORGANISATION_INVOICING_AND_SETTLEMENTS',
    'ORGANISATION_NOTIFICATIONS',
    'ORGANISATION_CURRENT_ATB',
    'ORGANISATION_TASKS',
    'ORGANISATION_TASKS_FOR_USERS',
    'ORGANISATION_TASK_COUNT_ACTIONED_BY_USER',
    'ORGANISATION_OPEN_SUPPORT_TICKETS',
    'ORGANISATION_TICKETS',
    'ORGANISATION_TICKETS_FOR_USERS',
    'ORGANISATION_OPEN_INVOICES',
    'ORGANISATION_COMMUNICATION_DELIVERY',
    'ORGANISATION_CUSTOMERS_SETTLEMENT',
    'ORGANISATION_DAILY_SALES_OUT',
];
export const DYNAMIC_CONTAINER_WIDGETS: any = {
    NOTIFICATION_SUCCESS: {
        displayType: widgetDisplayTypeValues.PIE_CHART,
    },
    ORGANISATION_NOTIFICATION_SUCCESS: {
        displayType: widgetDisplayTypeValues.PIE_CHART,
    },
};

export const FULL_STYLE_WIDGETS = [
    'OPEN_SUPPORT_TICKETS',
    'TOTAL_OWED',
    'OPEN_INVOICES',
    'DAILY_SALES_OUT',

    // Organisation section
    'ORGANISATION_TOTAL_OWED',
    'ORGANISATION_OPEN_SUPPORT_TICKETS',
    'ORGANISATION_OPEN_INVOICES',
];

export const displayColumnOptions = {
    OpenSum: {
        label: 'Open Tickets',
        value: 'OpenSum',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    ClosedSum: {
        label: 'Closed Tickets',
        value: 'ClosedSum',
        defaultChecked: true,
        OrgViewOnly: false,
    },
};

export const filterByUserTypesOptions = [
    {
        label: 'Company Users',
        value: 'InternalUsers',
        defaultChecked: true,
        OrgViewOnly: false,
    },
    {
        label: 'External Users',
        value: 'ExternalUsers',
        defaultChecked: true,
        OrgViewOnly: false,
    },
];

export const groupByUserTypesOptions = [
    {
        label: 'User',
        value: 'User'
    },
    {
        label: 'User Type',
        value: 'UserType'
    },
];

export const groupBySourceTypesOptions = [
    {
        label: 'Source',
        value: 'Source'
    },
    {
        label: 'Source Type',
        value: 'SourceType'
    },
];

export const getRegionOptions = (orgCompanies: Company[]) => {
    const regionList: string[] = [];
    forEach(orgCompanies, (oc: Company) => {
        if (oc.Region && !includes(regionList, oc.Region)) {
            regionList.push(oc.Region);
        }
    });

    return orderBy(
        map(regionList, (rl) => ({ label: rl, value: rl })),
        ['label'],
        ['asc']
    );
};

const cleanTreeSelectWithAllPayload = (payload: []) => {
    return filter(payload, (p) => p !== allOptionsSelectedKey);
};

/**
 * Function for populating the organisation dashboard widgets payload
 */
export const populatePayloadForOrganisationWidgets = ({
    payload,
    widgetDetails,
}: {
    payload: DynamicObject;
    widgetDetails: DynamicObject;
}) => {
    let regionsPayload: string[] = cleanTreeSelectWithAllPayload(
        get(widgetDetails, commonOrgFormFields.REGIONS, [])
    );
    let countriesPayload: string[] = cleanTreeSelectWithAllPayload(
        get(widgetDetails, commonOrgFormFields.COUNTRIES, [])
    );
    let companyIdsPayload: string[] = cleanTreeSelectWithAllPayload(
        get(widgetDetails, commonOrgFormFields.COMPANIES, [])
    );

    if (
        isEmpty(regionsPayload) ||
        isEmpty(countriesPayload) ||
        isEmpty(companyIdsPayload)
    ) {
        const storeState = store.getState();

        const orgUserCompanies = get(
            storeState,
            'organisations.userCompanies.data'
        );

        const allRegions: string[] = [];
        const allCountries: string[] = [];
        const allCompanies: string[] = [];
        forEach(orgUserCompanies, (uc: DynamicObject) => {
            if (
                isEmpty(regionsPayload) &&
                !includes(allRegions, uc.Region) &&
                uc.Region
            )
                allRegions.push(uc.Region);
            if (
                isEmpty(countriesPayload) &&
                !includes(allCountries, uc.Country) &&
                uc.Country
            )
                allCountries.push(uc.Country);
            if (
                isEmpty(companyIdsPayload) &&
                !includes(allCompanies, uc.CompanyId) &&
                uc.CompanyId
            )
                allCompanies.push(uc.CompanyId);
        });

        if (isEmpty(regionsPayload)) regionsPayload = allRegions;
        if (isEmpty(countriesPayload)) countriesPayload = allCountries;
        if (isEmpty(companyIdsPayload)) companyIdsPayload = allCompanies;
    }

    const updatedPayload = {
        ...payload,
        Regions: !isEmpty(regionsPayload) ? regionsPayload : undefined,
        Countries: !isEmpty(countriesPayload) ? countriesPayload : undefined,
        CompanyIds: !isEmpty(companyIdsPayload) ? companyIdsPayload : undefined,
    };

    return updatedPayload;
};

/**
 * Function for populating the organisation regional dashboard widgets payload
 */
export const populatePayloadForOrganisationRegionalWidgets = ({
    payload,
    widgetDetails,
    organisationCompanies,
    organisationCurrenciesAll,
    currencyCodeUsed,
}: {
    payload: DynamicObject;
    widgetDetails: DynamicObject;
    organisationCompanies: Company[];
    organisationCurrenciesAll?: DynamicObject[];
    currencyCodeUsed?: string;
}) => {
    const regionPayload: string | undefined = get(
        widgetDetails,
        commonOrgFormFields.REGION
    );
    let countriesPayload: string[] = cleanTreeSelectWithAllPayload(
        get(widgetDetails, commonOrgFormFields.COUNTRIES, [])
    );
    let companyIdsPayload: string[] = cleanTreeSelectWithAllPayload(
        get(widgetDetails, commonOrgFormFields.COMPANIES, [])
    );

    let regionsValueUsed: string[] = regionPayload ? [regionPayload] : [];
    const currencyObjSel = find(organisationCurrenciesAll, [
        'Value',
        currencyCodeUsed,
    ]);

    if (isEmpty(regionsValueUsed)) {
        const regionsList = getRegionOptions(organisationCompanies);
        const firstRegionValue = get(regionsList, `0.value`);
        regionsValueUsed = firstRegionValue ? [firstRegionValue] : [];
    }

    if (isEmpty(countriesPayload) || isEmpty(companyIdsPayload)) {
        const storeState = store.getState();

        const orgUserCompanies = get(
            storeState,
            'organisations.companies.data'
        );

        const allCountries: string[] = [];
        const allCompanies: string[] = [];
        forEach(orgUserCompanies, (uc: DynamicObject) => {
            if (
                isEmpty(countriesPayload) &&
                !includes(allCountries, uc.Country) &&
                includes(regionsValueUsed, uc.Region) &&
                uc.Country
            ) {
                allCountries.push(uc.Country);
            }

            if (
                isEmpty(companyIdsPayload) &&
                !includes(allCompanies, uc.CompanyId) &&
                includes(regionsValueUsed, uc.Region) &&
                uc.Country &&
                uc.CompanyId
            ) {
                if (
                    isEmpty(countriesPayload) ||
                    includes(countriesPayload, uc.Country)
                )
                    allCompanies.push(uc.CompanyId);
            }
        });

        if (isEmpty(countriesPayload)) countriesPayload = allCountries;
        if (isEmpty(companyIdsPayload)) companyIdsPayload = allCompanies;
    }

    const updatedPayload = {
        ...payload,
        Regions: regionsValueUsed,
        Countries: !isEmpty(countriesPayload) ? countriesPayload : undefined,
        CompanyIds: !isEmpty(companyIdsPayload) ? companyIdsPayload : undefined,
        OrganisationCurrencies: map(
            organisationCurrenciesAll,
            ({ Value, Rate }) => ({
                Value: Value,
                Rate: Rate,
            })
        ),
        ConversionCurrency: currencyObjSel
            ? get(currencyObjSel, 'Rate')
            : undefined,
    };

    return updatedPayload;
};

export const dashboardWidgetsRegionTitle = [
    'ORGANISATION_TOP_INVOICES',
    'ORGANISATION_TOP_CUSTOMERS',
    'ORGANISATION_TASKS',
    'ORGANISATION_OPEN_SUPPORT_TICKETS',
    'ORGANISATION_DAILY_SALES_OUT',
    'ORGANISATION_TICKETS',
    'ORGANISATION_TICKETS_FOR_USERS',
];

export const dashboardWidgetsCurrencyTitle = [
    'ORGANISATION_TOP_INVOICES',
    'ORGANISATION_TOP_CUSTOMERS',
];

/**
 * Common function for populating widget title
 * @param widgetProps
 * @returns
 */
export const populateDashboardWidgetTitle = (
    widgetProps: DynamicObject,
    organisationCompanies: Company[],
    organisationCurrenciesAll?: DynamicObject[]
) => {
    let widgetTitle = get(widgetProps, 'title');

    if (!includes(widgetTitle, ' - ')) {
        const widgetType = get(widgetProps, 'type');
        if (includes(dashboardWidgetsRegionTitle, widgetType)) {
            let region = get(widgetProps, 'Region');
            if (!region) {
                const regionsList = getRegionOptions(organisationCompanies);
                region = get(regionsList, `0.value`);
            }
            if (region) {
                widgetTitle += ` - ${region}`;
            }
        }

        if (
            organisationCurrenciesAll &&
            includes(dashboardWidgetsCurrencyTitle, widgetType)
        ) {
            let currency = get(widgetProps, 'Currency');
            if (!currency) {
                currency = get(organisationCurrenciesAll, '0.Value');
            }
            if (currency) {
                widgetTitle += ` ${currency}`;
            }
        }
    }

    return widgetTitle;
};

/**
* Function for formatting the table currencies.
* @param dataSource
* @param formatCurrency - currency formatter function
*/
export const tableCurrencyFormatter = (dataSource: any, formatCurrency: any) => {
    dataSource.map((item: any) => {
        for (let property in item) {
            if (isNumber(item[property])) {
                set(item, property, formatCurrency(item[property]))
            }
        }
    })
    return dataSource;
};


/**
* Function for formatting the table currencies.
* @param dataSource
* @param formatNumber - number formatter function
*/
export const tableNumberFormatter = (dataSource: any, formatNumber: any) => {
    dataSource.map((item: any) => {
        for (let property in item) {
            if (isNumber(item[property])) {
                set(item, property, formatNumber(item[property]))
            }
        }
    })
    return dataSource;
};