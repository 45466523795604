import { filter, get, isUndefined } from 'lodash';
import React, { forwardRef, Ref } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { AppCommonState } from '../../store/common/types';

interface IProps {
    readonly onChange: (floatValue: undefined | number) => void;
    readonly currentValue: any;
    readonly ref?: Ref<any>;
    readonly initialValue?: any;
}

const CurrencyInput: React.FC<IProps> = forwardRef(
    ({ onChange, currentValue,initialValue }: IProps, ref: Ref<any>) => {
        const appState: AppCommonState = useSelector(
            (state: ApplicationState) => state.app
        );
        
        const currencyObj = Intl.NumberFormat(appState.locale, {
            style: 'currency',
            currency: appState.currencyCode,
        }).formatToParts(currentValue);

        let floatValue = 0;

        const prefix = get(currencyObj, `0.type`) === 'currency';
        const currencySign = get(
            filter(currencyObj, ['type', 'currency']),
            `0.value`
        );
        const literalSign = get(
            filter(currencyObj, ['type', 'literal']),
            `0.value`,
            ''
        );
        const decimalSeparator = get(
            filter(currencyObj, ['type', 'decimal']),
            `0.value`,
            '.'
        );

        const thousandSeparator = get(
            filter(currencyObj, ['type', 'group']),
            `0.value`,
            ''
        );

        const populatePlaceHolderText = () =>
            Intl.NumberFormat(appState.locale, {
                style: 'currency',
                currency: appState.currencyCode,
            }).format(0);

        return (
            <NumberFormat
                ref={ref}
                className="ant-input"
                thousandSeparator={thousandSeparator}
                decimalSeparator={decimalSeparator}
                decimalScale={2}
                fixedDecimalScale
                prefix={prefix ? `${currencySign}${literalSign}` : undefined}
                suffix={prefix ? undefined : `${literalSign}${currencySign}`}
                value={currentValue}
                onValueChange={(values) => {
                    floatValue = values.floatValue;
                }}
                onChange={() => {
                    if (isUndefined(floatValue) || floatValue > 0) {
                        onChange(floatValue);
                    }
                }}
                placeholder={populatePlaceHolderText()}
            />
        );
    }
);

export default React.memo(CurrencyInput);
