import React, { useRef, useEffect, CSSProperties, useState } from 'react';
import LuckyExcel from 'luckyexcel'
import { useDispatch, useSelector } from 'react-redux';
import { loadLuckysheetAction } from '../../store/common/actions';
import { Modal, Spin } from 'antd';
import { DANGER_COLOR } from '../../constants/common';
import { getLoadLuckysheet } from '../../store/common/sagas';
import { parseCsv } from '../../utils/commonFunctions';
import { read as readExcel, writeXLSX, utils as excelUtils } from "xlsx";
import './SheetViewer.css';

interface IProps {
    id: string;
    url?: string;
    style?: CSSProperties;
    isCsv?: boolean;
}

const asyncCall = (func: Function) => setTimeout(func, 0);

const DEFAULT_ERROR_MESSAGE = <>Something's wrong while rendering the requested sheet.<br />Please download the file instead!</>;
const FILE_TOO_BIG_ERROR = <>File too big! Maximum file size allowed for preview is 3MB.<br />Please download the file instead!</>;

const SheetViewer: React.FC<IProps> = ({
    id, url, isCsv, ...props
}: IProps) => {
    const dispatch = useDispatch();
    const loadLuckysheet = useSelector(getLoadLuckysheet);
    const workbookRef = useRef<HTMLDivElement>(null);
    const [errorMessage, setErrorMessage] = useState<any>();
    const [loading, setLoading] = useState(false);

    const workbookCreateAfter = () => {
        setLoading(false);
    }

    const loadWorkbook = (data: any) => {
        setLoading(true);
        const luckysheet = window.luckysheet;
        if (isCsv) {
            const csvResults = parseCsv(data);
            const cells: any[][] = [];
            csvResults.forEach(csvRow => {
                const row: any[] = [];
                csvRow.forEach(value => {
                    row.push({
                        ct: { fa: "General", t: "g" },
                        m: value,
                        v: value
                    });
                });
                cells.push(row);
            });
            const options = generateOptions(id, [{
                name: "Sheet1",
                status: "1",
                order: "0",
                data: cells,
                config: {},
                index: 0
            }], workbookCreateAfter);
            luckysheet.create(options);
        } else {
            LuckyExcel.transformExcelToLucky(data,
                (exportJson: any, luckysheetfile: any) => {
                    try {
                        if (!exportJson.sheets || exportJson.sheets.length === 0) {
                            throw new Error('No sheet found!');
                        }
                        const options = generateOptions(id, exportJson.sheets, workbookCreateAfter);
                        luckysheet.create(options);
                    } catch (e) {
                        setErrorMessage(DEFAULT_ERROR_MESSAGE);
                        setLoading(false);
                    }
                })
        }
    };

    const prepareLoadWorkbook = (data: any) => {
        if (window.luckysheet) {
            loadWorkbook(data);
        } else {
            setTimeout(() => prepareLoadWorkbook(data), 1000);
        }
    };

    useEffect(() => {
        if (!loadLuckysheet) {
            asyncCall(() => dispatch(loadLuckysheetAction()))
        }
    }, [loadLuckysheet]);

    useEffect(() => {
        setErrorMessage(undefined);
        if (url) {
            setLoading(true);
            fetch(url, {
                method: 'GET'
            }).then(async (resp) => {
                const contentLength = resp.headers.get('Content-Length');
                const exceedLimit = (contentLength && parseInt(contentLength) > 3e+6) ? true : false;
                if (exceedLimit) {
                    setErrorMessage(FILE_TOO_BIG_ERROR);
                    setLoading(false);
                    return;
                }
                let data: any;
                if (isCsv) data = await resp.text();
                else {
                    const workbook = readExcel(await resp.arrayBuffer(), {
                        cellDates: true
                    });
                    workbook.SheetNames.forEach(sN => {
                        const ws = workbook.Sheets[sN];
                        const range = excelUtils.decode_range(ws['!ref'] || '');
                        for (let i = 0; i <= range.e.c; i++) {
                            const col = excelUtils.encode_col(i);
                            const firstDataCell = ws[`${col}1`];
                            const secondDataCell = ws[`${col}2`];
                            if ((firstDataCell && firstDataCell.t === 'd') || (secondDataCell && secondDataCell.t === 'd')) {
                                for (let j = 0; j <= range.e.r; j++) {
                                    const shell = ws[`${col}${j + 1}`];
                                    if (shell && shell.t === 'd') {
                                        shell.t = 's';
                                        shell.v = shell.w;
                                    }
                                }
                            }
                        }
                    });
                    data = writeXLSX(workbook, {
                        type: 'buffer'
                    });
                }
                prepareLoadWorkbook(data);
            }).catch(err => {
                setLoading(false);
                Modal.error({
                    title: 'Error',
                    content: 'Cannot load requested sheet!',
                });
            })
        }
    }, [url]);

    return <Spin spinning={loading || !url}>
        {errorMessage
            ? <div style={{ color: DANGER_COLOR }}>{errorMessage}</div>
            : <div key={url} id={id} ref={workbookRef} {...(props as any)}></div>}
    </Spin>;
};

export default SheetViewer;

const generateOptions = (id: string, sheets: any, workbookCreateAfter?: () => void) => ({
    container: id,
    data: sheets,
    showtoolbar: false,
    sheetFormulaBar: false,
    showinfobar: false,
    allowUpdate: false,
    enableAddRow: false,
    showsheetbarConfig: {
        add: false, // add worksheet
        menu: false, // worksheet management menu
        sheet: true // worksheet display
    },
    cellRightClickConfig: {
        copy: true, // copy
        copyAs: true, // copy as
        paste: false, // paste
        insertRow: false, // insert row
        insertColumn: false, // insert column
        deleteRow: false, // delete the selected row
        deleteColumn: false, // delete the selected column
        deleteCell: false, // delete cell
        hideRow: false, // hide the selected row and display the selected row
        hideColumn: false, // hide the selected column and display the selected column
        rowHeight: false, // row height
        columnWidth: false, // column width
        clear: false, // clear content
        matrix: false, // matrix operation selection
        sort: false, // sort selection
        filter: false, // filter selection
        chart: false, // chart generation
        image: false, // insert picture
        link: false, // insert link
        data: false, // data verification
        cellFormat: false // Set cell format
    },
    sheetRightClickConfig: {
        delete: false, // delete
        copy: false, // copy
        rename: false, // rename
        color: false, // change color
        hide: false, // hide, unhide
        move: false, // move to the left, move to the right
    },
    showstatisticBarConfig: {
        count: false, // count bar
        view: false, // print view
        zoom: true // zoom
    },
    hook: {
        cellUpdateBefore: () => false,
        cellDeleteBefore: () => false,
        cellEditBefore: () => false,
        rangeMoveBefore: () => false,
        rangeEditBefore: () => false,
        rangePasteBefore: () => false,
        rangeCutBefore: () => false,
        rangeDeleteBefore: () => false,
        rangeClearBefore: () => false,
        rangePullBefore: () => false,
        workbookCreateAfter
    }
});