/**
 * Component for handling date formats (formatting date).
 */

import { get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    dateFormatDDMMYYYYSlash,
    dateFormatDMMMYYYYSpace,
    dateFormatYYYYMMDDTHHmmssDash,
} from '../../constants/dateFormats';
import { ApplicationState } from '../../store';

export const withDateFormatHandler = (WrappedComponent: any) => {
    const DateFormatHandler: React.FC<any> = (props: any) => {
        const companyTimezone = useSelector((state: ApplicationState) =>
            get(state.companies.selectedUserCompany, 'Company.Timezone')
        );
        const selectedTimezone = moment.tz.guess();

        /**
         * Function called for formatting date based on given format.
         * * Returns string.
         * @param date - date object, string, or number
         * @param fromFormat - format of the date given (optional as a default format will be used if not given)
         * @param toFormat - format to convert to (optional as a default format will be used if not given)
         */
        const formatDate = (
            date: any,
            fromFormat?: string | null,
            toFormat?: string | null
        ) => {
            const usedFromFormat = fromFormat || dateFormatDDMMYYYYSlash;
            const usedToFormat = toFormat || dateFormatDMMMYYYYSpace;
            return moment(date, usedFromFormat).format(usedToFormat);
        };

        /**
         * Function called for formatting local date based on given format (format by default is same with the one from db).
         * * Returns string.
         * @param date - date object, string, or number
         * @param fromFormat - format of the date given (optional as a default format will be used if not given)
         * @param toFormat - format to convert to (optional as a default format will be used if not given)
         */
        const formatDateLocal = (
            date: any,
            fromFormat?: string | null,
            toFormat?: string | null
        ) => {
            const usedFromFormat = fromFormat || dateFormatYYYYMMDDTHHmmssDash;
            const usedToFormat = toFormat || dateFormatDMMMYYYYSpace;
            return moment(date, usedFromFormat).format(usedToFormat);
        };

        /**
         * Function for formatting UTC date to local date.
         * Returns string.
         * @param date - date in UTC
         * @param fromFormat - format for the date to convert
         * @param toFormat - format to convert the date to
         */
        const formatDateUTCToLocal = (
            date: any,
            fromFormat?: string | null,
            toFormat?: string | null
        ) => {
            const usedFromFormat = fromFormat || dateFormatYYYYMMDDTHHmmssDash;
            const usedToFormat = toFormat || dateFormatDMMMYYYYSpace;
            const formattedDateTime = moment
                .utc(date, usedFromFormat)
                .tz(selectedTimezone)
                .format(usedToFormat);

            return formattedDateTime;
        };

        /**
         * Function that formats date (assumed local) to UTC date object.
         * Returns moment.Moment.
         * @param date - date in local date format
         * @param fromFormat - format of the date passed
         */
        const formatDateToDateObjectUTC = (
            date: any,
            fromFormat?: string | null,
            isLocalTime?: boolean
        ) => {
            const usedFromFormat = fromFormat || dateFormatYYYYMMDDTHHmmssDash;
            const localDatetime = moment(date, usedFromFormat).toDate();

            if (!isLocalTime && companyTimezone) {
                return moment
                    .tz(
                        moment(localDatetime).format(
                            dateFormatYYYYMMDDTHHmmssDash
                        ),
                        companyTimezone
                    )
                    .utc();
            } else {
                return moment.utc(localDatetime);
            }
        };

        /**
         * Function that gets the number of days between a certain date and today.
         * Returns number.
         * @param date - date to get the number of days from - in utc
         * @param dateFormat - format of the given date
         */
        const daysFromToday = (date: any, dateFormat?: string) => {
            if (date) {
                const usedFormat = dateFormat || dateFormatYYYYMMDDTHHmmssDash;
                // const dateGiven = moment.utc(date, usedFormat).tz(selectedTimezone);
                // const currentDate = moment.tz(selectedTimezone);
                const dateGivenUTC = moment.utc(date, usedFormat);
                const currentDateUTC = moment.utc();

                const hoursDifference = moment(currentDateUTC).diff(
                    moment(dateGivenUTC),
                    'hours'
                );

                if (hoursDifference < 0) {
                    const usedHours = hoursDifference * -1;

                    return Math.ceil(usedHours / 24) * -1;
                } else {
                    return Math.trunc(hoursDifference / 24);
                }
            } else {
                return null;
            }
        };

        /**
         * Function that checks if the given date is valid.
         * Returns boolean.
         * @param date - date
         * @param dateFormat
         */
        const isValidDate = (date: any, dateFormat?: string) => {
            const usedFormat = dateFormat || moment.ISO_8601;

            return moment(date, usedFormat, true).isValid();
        };

        return (
            <WrappedComponent
                {...props}
                formatDate={formatDate}
                formatDateLocal={formatDateLocal}
                formatDateUTCToLocal={formatDateUTCToLocal}
                formatDateToDateObjectUTC={formatDateToDateObjectUTC}
                daysFromToday={daysFromToday}
                isValidDate={isValidDate}
            />
        );
    };

    return DateFormatHandler;
};
