/**
 * Component for populating the Resolve customer assistance ticket panel when clicking on `Action` button for ticket task items.
 */

import {
    Button,
    Col,
    Form,
    Modal,
    Row,
    Checkbox
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEmpty, map,capitalize, get, filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import ModalWithSpinner from '../../common/ModalWithSpinner';
import { ApplicationState } from '../../../store';
import {
    taskActionPaymentVerificationTaskRequest,
} from '../../../store/tasks/actions';
import { Task } from '../../../store/tasks/types';
import { DynamicObject } from '../../../utils/commonInterfaces';
import TaskActionPaymentInputAutoComplete from './TaskActionPaymentInputAutoComplete';

interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly selectedTaskId: undefined | string;
    readonly customerLabel: string;
}
const TaskActionPaymentPanelContent: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    selectedTaskId,
    customerLabel
}: IProps) => {
    const [formValues, setFormValues] = useState<{
        checkbox: boolean | undefined;
        input: '';
    }>({
        checkbox: false,
        input: '',
    });

    const tasksList: Task[] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks.data
    );

    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [disableSearchbox, setDisableSearchbox] = useState<boolean>(false);
    const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState<boolean>(true);
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
    const { resetFields } = form;

    /**
     * Function for closing the panel.
     * When `Cancel` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Listener function if the drawer visibility changes.
     * If drawer closes, resets the field values.
     * If drawer opens, gets action options.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        } 
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when clicking the `Action item` button
     */
    const handleSubmitForm = () => {
        setSubmitLoading(true);

        const { checkbox: checkboxValue } = formValues;
        
        if (taskActionPaymentVerificationTaskRequest) {
            dispatch(
                taskActionPaymentVerificationTaskRequest({
                    task: {
                        Id: selectedTaskId
                    },
                    customer: selectedCustomerId,
                    multipleCustomer: checkboxValue,
                    callback: actionPaymentVerificationTaskResponseModal
                })
            );
        } else {
            if (closePanel) closePanel(true);
        }
    };

    /**
     * Function responsible for showing the response modal after adding comment.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const actionPaymentVerificationTaskResponseModal = ({
        IsSuccess,
        Messages,
        RefetchList = true,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
        RefetchList: boolean;
    }) => {
        setSubmitLoading(false);
        
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Payment verified successfully!',
                onOk: () => {
                    if (closePanel) closePanel(RefetchList);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to verify payment!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }
    };
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };


    /**
    * Common function for updating the formValues state while maintaining the other existing values.
    * @param formValuesObject
    */
    const updateFormValuesObject = (formValuesObject: DynamicObject) => {    
        setFormValues({
            ...formValues,
            ...formValuesObject,
        });
    };

    /**
    * Common function for updating the save button enable state when there's a change in the form.
    * @param formValuesObject
    */
    const onChangeForm = (searchField?: string, isChecked? : boolean) => {
        if(searchField != "" || isChecked){
            setIsSaveBtnDisabled(false)
        } else {
            setIsSaveBtnDisabled(true)
        }
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: CheckboxChangeEvent
    ) => {
        updateFormValuesObject({
            input: '',
            checkbox: e.target.checked,
        });
        setSelectedCustomerId("");
        onChangeForm("",e.target.checked)
        setDisableSearchbox(e.target.checked);
    };
    
    /**
     * Function to populate the loading content.
     * Shows a loading skeleton if the content is being populated (API not finished fetching data for action options).
     */
    const populatePanelContent = () => {

        const record = get(
            filter(tasksList, ['Id', selectedTaskId]),
            0
        );
        
        const stateValue = disableSearchbox ? '' : formValues.input; 

        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row>
                    <Col span={24}>
                        <Row className="mb-10" type="flex" align="middle">
                            <Col span={24}>
                                {capitalize('Payment Reference:')} <b>{record.Payment ? record.Payment.PaymentReference: ""}</b>
                            </Col>
                        </Row>
                        <Row className="mb-10" type="flex" align="middle">
                            <Col className="autocomplete-label">{capitalize(customerLabel)}: </Col>
                            <Col span={18}>
                                    {(
                                        <TaskActionPaymentInputAutoComplete
                                            updateField={(value: string) => {
                                                updateFormValuesObject({
                                                    input: value,
                                                });
                                            }}
                                            setSelectedCustomerId={setSelectedCustomerId}
                                            setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                                            stateValue={stateValue}
                                            queryName={'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                                            filterField={'Customer'}
                                            queryFilterName={'Customer'}
                                            sortField={'Company name'}
                                            responseName={'GetCustomersForCompany.Customers'}
                                            labelField={'DisplayName'}
                                            hasNoOkButton
                                            loading={false}
                                            readOnly={disableSearchbox}
                                            hasNoContainerRef
                                        />
                                    )}
                            </Col>
                        </Row>
                        <Row className="mb-16 multiple-customer-checkbox" type="flex" align="middle">
                            <Col span={4}/>
                            <Col span={20}>
                                <Checkbox 
                                    onChange={handleCheckboxClick}
                                >
                                    <span className="grey">
                                        Payment refers to multiple {`${capitalize(customerLabel)}s`}
                                    </span>
                                </Checkbox>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col className="dropdown-multiline-cont">
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                            disabled={isSaveBtnDisabled}
                        >
                            Save
                        </Button>
                        <Button onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <ModalWithSpinner
                    modalTitle="Actioning Payment Verification"
                    modalVisible={submitLoading}
                    displayMessage="Please wait while actioning the payment verification . . ."
                />
            )}
        </Row>
    );
};

const TaskResolveCustomerAssistanceTicketPanelContentForm = Form.create({
    name: 'task-resolve-ticket-panel-form',
})(TaskActionPaymentPanelContent);

export default withRouter(
    withDateFormatHandler(TaskResolveCustomerAssistanceTicketPanelContentForm)
);
