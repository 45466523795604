import { Reducer } from 'redux';
import { ChangesState } from './types';

// Type-safe initialState!
export const initialState: ChangesState = {
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<ChangesState> = (state = initialState, action) => {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as changesReducer };
