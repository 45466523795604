import { usersSortByOptions } from '../../constants/usersSortAndFilters';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Role } from '../roles/types';
import { Company } from '../companies/types';
import { PageData } from '../common/types';
import { ResponseModalObject } from '../../utils/commonInterfaces';

export enum UsersActionTypes {
    GET_USERS_REQUEST = '@@users/GET_USERS_REQUEST',
    GET_USERS_SUCCESS = '@@users/GET_USERS_SUCCESS',
    GET_USERS_ERROR = '@@users/GET_USERS_ERROR',
    CHANGE_USERS_ROLE_REQUEST = '@@users/CHANGE_USERS_ROLE_REQUEST',
    CHANGE_USERS_ROLE_RESPONSE = '@@users/CHANGE_USERS_ROLE_RESPONSE',
    UPDATE_USERS_FILTERS = '@@users/UPDATE_USERS_FILTERS',
    UPDATE_USERS_TABLE_FILTER = '@@users/UPDATE_USERS_TABLE_FILTER',
    UPDATE_USERS_SORT_BY = '@@users/UPDATE_USERS_SORT_BY',
    CLEAR_USERS_STATE_ALL_TABLE_FILTERS = '@@users/CLEAR_USERS_STATE_ALL_TABLE_FILTERS',
    CLEAR_USERS_STATE_DATA = '@@users/CLEAR_USERS_STATE_DATA',

    GET_USER_BY_NAME = '@@users/GET_USER_BY_NAME',
    GET_USER_BY_EMAIL = '@@users/GET_USER_BY_EMAIL',

    REMOVE_USERS_REQUEST = '@@users/REMOVE_USERS_REQUEST',
    GET_USER_LAST_LOGIN_DATE = '@@users/GET_USER_LAST_LOGIN_DATE',

    GET_USER_PREFERENCES_REQUEST = '@@users/GET_USER_PREFERENCES_REQUEST',
    GET_USER_PREFERENCES_SUCCESS = '@@users/GET_USER_PREFERENCES_SUCCESS',
}

export interface UsersState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly changeRolesLoading: boolean;
    readonly data: User[];
    readonly pageData: PageData;
    readonly filters: GetUsersRequestPayload['filters'];
    readonly sortBy: GetUsersRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly tableFilter: string | undefined;
    readonly userPreference: UserPreference | {};
}

export interface UserPreference {
    readonly UserId: string;
    readonly Preferences: [Preference];
}

export interface Preference {
    PageName: string;
    IsCompactView: boolean;
}
export interface User {
    readonly UserId: string;
    readonly GivenName: string;
    readonly FamilyName: string;
    readonly Email: string;
    readonly PhoneNumber: string;
    readonly Role: Role;
    readonly Company: Company;
    readonly CreatedDateTime: string;
    readonly UpdateDateTime: string;
}

export interface GetUsersRequestPayload {
    filters: {
        Name: string;
        Email: string;
        Role: CheckboxValueType[];
    };
    sortBy: typeof usersSortByOptions[number];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface ChangeUsersRoleRequestPayload {
    filter: {
        RoleIds: string[];
        Name: string;
        Email: string;
        SortField: string;
        Ascending: boolean;
        CurrentPage: number;
        PageSize: number;
    };
    userIds: string[] | number[];
    roleId: string;
    excludeUsers: boolean;
    callback?: (response: ResponseModalObject) => void;
}

export interface RemoveUsersRequestPayload {
    filter: {};
    userIds: string[] | number[];
    excludeUsers: boolean;
    callback?: (response: ResponseModalObject) => void;
}
