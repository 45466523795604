import { action } from 'typesafe-actions';
import { GetRemittanceAdvicesRequestPayload, RemittanceAdviceAddCommentRequestPayload, RemittanceAdvicesActionTypes, GetRemittanceAdviceChangesRequestPayload, RemittanceAdviceChanges, RemittanceAdvice } from './types';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData, Conversation, GetConversationRequestPayload, } from '../common/types';
import { GetInvoicesRequestPayload, Invoice } from '../invoices/types';
import { GetPaymentsRequestPayload, Payment } from '../payments/types';
import { Credit, GetCreditsRequestPayload } from '../credits/types';
import { Customer, GetCustomersRequestPayload } from '../customers/types';

/**
 * Function for getting the Remittance advices for a company.
 */
export const getRemittanceAdvicesRequestAction = (payload: GetRemittanceAdvicesRequestPayload) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICES_REQUEST, payload);

export const getRemittanceAdvicesSuccessAction = (payload: {
        data: DynamicObject[];
        pageData: PageData;
        mergeData?: boolean;
    }) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICES_SUCCESS, payload);

export const remittanceAdviceAddCommentRequestAction = (
    payload: RemittanceAdviceAddCommentRequestPayload
) => action(RemittanceAdvicesActionTypes.REMITTANCE_ADVICE_ADD_COMMENT_REQUEST, payload);

/**
 * Action for clearing the details of a selected remittance advice.
 */
export const clearRemittanceAdviceDataAction = () =>
    action(RemittanceAdvicesActionTypes.CLEAR_REMITTANCE_ADVICE_SELECTED_SUCCESS);

/**
 * Action called for changing the sort by, sort order, and state for Remittance Advice list.
 * @param tableSortAndState
 */
export const updateRemittanceAdvicesSortByAndStateAction = (tableSortAndState: {
    SortField: GetRemittanceAdvicesRequestPayload['SortField'];
    sortAscending: boolean;
}) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_SORT_BY_AND_STATE,
        tableSortAndState
    );
/**
 * Action called for updating the filters on remittance advice management page.
 * @param filters
 */
export const updateRemittanceAdvicesFiltersAction = (
    filters: GetRemittanceAdvicesRequestPayload['filters']
) => action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_FILTERS, filters);

/**
 * Action called when changing the remittance advice view filter.
 * @param RemittanceAdviceViewFilter
 */
export const updateRemittanceAdviceTableFilterAction = (RemittanceAdviceViewFilter: string) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_TABLE_FILTER_STATE,
        RemittanceAdviceViewFilter
    );

// Single Record
/**
 * Action sfor setting the selected remittance advice Id when clicking on a remittance advice table row item.
 * @param remittanceAdviceId
 * @param callback
 */
export const setRemittanceAdviceSelectedIdRequestAction = (
    remittanceAdviceId: string | null,
    callback: () => void
) =>
    action(RemittanceAdvicesActionTypes.SET_REMITTANCE_ADVICE_SELECTED_ID_REQUEST, {
        remittanceAdviceId,
        callback,
    });

export const setRemittanceAdviceSelectedIdSuccessAction = (remittanceAdviceId: string | null) =>
    action(RemittanceAdvicesActionTypes.SET_REMITTANCE_ADVICE_SELECTED_ID_SUCCESS, remittanceAdviceId);


/**
 * Actions for fetching the remittance advice details.
 */
export const getRemittanceAdviceDetails = (remittanceAdviceId: string) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_DETAILS_REQUEST, remittanceAdviceId);
export const getRemittanceAdviceDetailsSuccess = (payload: { record: {}, payment: {} }) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_DETAILS_SUCCESS, payload);
export const getRemittanceAdviceDetailsError = (payload: string[]) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_DETAILS_ERROR, payload);

/**
 * Actions for confirming remittance advice.
 */
export const confirmRemittanceAdviceRequest = (payload: {}) =>
    action(RemittanceAdvicesActionTypes.CONFIRM_REMITTANCE_ADVICE_REQUEST, payload);

/**
 * Actions for confirming remittance advice disregard tasks.
 */
export const confirmRemittanceAdviceDisregardTasksRequest = (payload: {}) =>
    action(RemittanceAdvicesActionTypes.CONFIRM_REMITTANCE_ADVICE_DISREGARD_TASKS_REQUEST, payload);
// Customer List Actions

export const getRemittanceAdviceCustomersRequestAction = (
    payload: GetCustomersRequestPayload
) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CUSTOMERS_REQUEST, payload);

export const getRemittanceAdviceCustomersSuccessAction = (payload: {
    data: Customer[];
    pageData: PageData;
}) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CUSTOMERS_SUCCESS, payload);

export const getRemittanceAdviceCustomersErrorAction = (payload: string[]) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CUSTOMERS_ERROR, payload);

export const updateRemittanceAdviceCustomersFiltersAction = (
    filters: GetCustomersRequestPayload['filters']
) => action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CUSTOMERS_FILTERS, filters);

export const updateRemittanceAdviceCustomersSortByAndStateAction = (tableSortAndState: {
    sortBy: GetCustomersRequestPayload['sortBy'];
    sortAscending: boolean;
    customerState: string;
}) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CUSTOMERS_SORT_BY_AND_STATE,
        tableSortAndState
    );

export const updateRemittanceAdviceCustomersTableFilterAction = (customer: string) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CUSTOMERS_TABLE_FILTER_STATE,
        customer
    );

// Invoice List Actions
export const getRemittanceAdviceInvoicesRequestAction = (
    payload: GetInvoicesRequestPayload
) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_INVOICES_REQUEST, payload);

export const getRemittanceAdviceInvoicesSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_INVOICES_SUCCESS, payload);
export const getRemittanceAdviceInvoicesErrorAction = (payload: string[]) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_INVOICES_ERROR, payload);

export const updateRemittanceAdviceInvoicesFiltersAction = (
    filters: GetInvoicesRequestPayload['filters']
) => action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_INVOICES_FILTERS, filters);

export const updateRemittanceAdviceInvoicesSortByAndStateAction = (tableSortAndState: {
    sortBy: GetInvoicesRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_INVOICES_SORT_BY_AND_STATE,
        tableSortAndState
    );

export const updateRemittanceAdviceInvoicesTableFilterAction = (invoiceState: string) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_INVOICES_TABLE_FILTER_STATE,
        invoiceState
    );

// Credit List Actions
export const getRemittanceAdviceCreditsRequestAction = (
    payload: GetCreditsRequestPayload
) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CREDITS_REQUEST, payload);
export const getRemittanceAdviceCreditsSuccessAction = (payload: {
    data: Credit[];
    pageData: PageData;
}) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CREDITS_SUCCESS, payload);
export const getRemittanceAdviceCreditsErrorAction = (payload: string[]) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CREDITS_ERROR, payload);

export const updateRemittanceAdviceCreditsFiltersAction = (
    filters: GetCreditsRequestPayload['filters']
) => action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CREDITS_FILTERS, filters);
export const updateRemittanceAdviceCreditsSortByAndStateAction = (tableSortAndState: {
    sortBy: GetCreditsRequestPayload['sortBy'];
    sortAscending: boolean;
    creditState: string;
}) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CREDITS_SORT_BY_AND_STATE,
        tableSortAndState
    );
export const updateRemittanceAdviceCreditsTableFilterAction = (creditState: string) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CREDITS_TABLE_FILTER_STATE,
        creditState
    );

// Conversation actions
export const getRemittanceAdviceConversationRequestAction = (
    payload: GetConversationRequestPayload
) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CONVERSATION_REQUEST, payload);

export const getRemittanceAdviceConversationSuccessAction = (payload: {
    data: Conversation[];
    pageData: PageData;
}) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CONVERSATION_SUCCESS, payload);

export const getRemittanceAdviceConversationErrorAction = (payload: string[]) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CONVERSATION_ERROR, payload);

export const updateRemittanceAdviceConversationFiltersAction = (
    filters: GetConversationRequestPayload['filters']
) => action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CONVERSATION_FILTERS, filters);

export const updateRemittanceAdviceConversationTableFilterAction = (
    conversationTableFilter: string
) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CONVERSATION_TABLE_FILTER,
        conversationTableFilter
    );

export const updateRemittanceAdviceConversationSortByAction = (tableSort: {
    sortBy: GetConversationRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CONVERSATION_SORT_BY, tableSort);

export const paymentAddCommentRequestAction = (
    payload: RemittanceAdviceAddCommentRequestPayload
) => action(RemittanceAdvicesActionTypes.REMITTANCE_ADVICE_ADD_COMMENT_REQUEST, payload);

// RemittanceAdviceChanges actions
/**
 * Action for getting the remittanceAdvice changes list.
 * @param payload
 */
export const getRemittanceAdviceChangesRequestAction = (
    payload: GetRemittanceAdviceChangesRequestPayload
) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CHANGES_REQUEST, payload);

export const getRemittanceAdviceChangesSuccessAction = (payload: {
    data: RemittanceAdviceChanges[];
    pageData: PageData;
}) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CHANGES_SUCCESS, payload);

export const getRemittanceAdviceChangesErrorAction = (payload: string[]) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_CHANGES_ERROR, payload);

export const updateRemittanceAdviceChangesFiltersAction = (
    filters: GetRemittanceAdviceChangesRequestPayload['filters']
) =>
    action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CHANGES_FILTERS, filters);

export const updateRemittanceAdviceChangesTableFilterAction = (
    remittanceAdviceChangesTableFilter: string
) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CHANGES_TABLE_FILTER,
        remittanceAdviceChangesTableFilter
    );

export const updateRemittanceAdviceChangesSortByAction = (tableSort: {
    sortBy: GetRemittanceAdviceChangesRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_CHANGES_SORT_BY,
        tableSort
    );
// Payment List Actions
export const getRemittanceAdvicePaymentsRequestAction = (
    payload: GetPaymentsRequestPayload
) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_PAYMENTS_REQUEST, payload);
export const getRemittanceAdvicePaymentsSuccessAction = (payload: {
    data: Payment[];
    pageData: PageData;
}) => action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_PAYMENTS_SUCCESS, payload);
export const getRemittanceAdvicePaymentsErrorAction = (payload: string[]) =>
    action(RemittanceAdvicesActionTypes.GET_REMITTANCE_ADVICE_PAYMENTS_ERROR, payload);

export const updateRemittanceAdvicePaymentsFiltersAction = (
    filters: GetPaymentsRequestPayload['filters']
) => action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_PAYMENTS_FILTERS, filters);
export const updateRemittanceAdvicePaymentsSortByAction = (tableSort: {
    sortBy: GetPaymentsRequestPayload['sortBy'];
    sortAscending: boolean;
}) => action(RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_PAYMENTS_SORT_BY, tableSort);
export const updateRemittanceAdvicePaymentsTableFilterAction = (paymentState: string) =>
    action(
        RemittanceAdvicesActionTypes.UPDATE_REMITTANCE_ADVICE_PAYMENTS_TABLE_FILTER,
        paymentState
    );

/**
* Function for getting the RA Custom Fields for a company.
* @param payload
*/
export const getRACustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(RemittanceAdvicesActionTypes.GET_RA_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });
export const reviewRemittanceAdviceRequest = ( payload: {}) =>
    action( RemittanceAdvicesActionTypes.REVIEW_REMITTANCE_ADVICE_REQUEST,  payload );

export const reviewRemittanceAdviceSuccess = ( payload: {
    AllocationErrors: DynamicObject[];
    AllocationItems: DynamicObject[];
    }) => action(RemittanceAdvicesActionTypes.REVIEW_REMITTANCE_ADVICE_SUCCESS, payload);
