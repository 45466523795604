import update from 'immutability-helper';
import { Reducer } from 'redux';
import { DashboardsActionTypes, DashboardsState } from './types';

// Type-safe initialState!
export const initialState: DashboardsState = {
    loading: false,
    errorMessages: [],
    data: [],
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
    },
    isEditing: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<DashboardsState> = (state = initialState, action) => {
    switch (action.type) {
        case DashboardsActionTypes.GET_DASHBOARDS_REQUEST: {
            return { ...state, loading: true };
        }
        case DashboardsActionTypes.GET_DASHBOARDS_SUCCESS: {
            let newDataState = [];
            newDataState = update(state.data, {
                $set: action.payload.data,
            });

            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case DashboardsActionTypes.GET_DASHBOARDS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case DashboardsActionTypes.CLEAR_DASHBOARDS_STATE_DATA: {
            return {
                ...initialState,
            };
        }

        //Single Record
        // case DashboardsActionTypes.SET_DASHBOARD_SELECTED_ID_REQUEST: {
        //     const newActiveData = update(state.activeData, {
        //         $merge: {
        //             selectedId: initialState.activeData.selectedId,
        //         },
        //     });

        //     return {
        //         ...state,
        //         activeData: newActiveData,
        //     };
        // }

        case DashboardsActionTypes.SET_DASHBOARD_SELECTED_ID_SUCCESS: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: action.payload,
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case DashboardsActionTypes.GET_DASHBOARD_DATA_REQUEST: {
            const newActiveData = update(state.activeData, {
                // record: { $set: initialState.activeData.record },
                loading: { $set: true },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case DashboardsActionTypes.GET_DASHBOARD_DATA_SUCCESS: {
            const newActiveData = update(state.activeData, {
                record: { $set: action.payload.record },
                loading: { $set: false },
                errorMessages: { $set: initialState.activeData.errorMessages },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case DashboardsActionTypes.GET_DASHBOARD_DATA_ERROR: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: false },
                errorMessages: { $set: action.payload },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case DashboardsActionTypes.SET_IS_EDITING_DASHBOARD: {
            return {
                ...state,
                isEditing: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as dashboardsReducer };
