/**
 * File responsible for the content when clicking `Specialised Reports` in invoice management pages.
 */

import { Button, Col, Form, Row, Select, Skeleton } from 'antd';
import { get, map, times } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { SpecialisedReports } from '../../store/common/types';

const { Option } = Select;
const { Item: FormItem } = Form;

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly form?: any;
    readonly closePanel?: () => void;
    readonly downloadToExcelCallBack?: (reportType: string) => void;
    readonly reportOptions: {
        options: SpecialisedReports[];
        loading: boolean;
    };
}

const SpecialisedReportsPanel: React.FC<IProps> = ({
    visible,
    form,
    closePanel,
    downloadToExcelCallBack,
    reportOptions
}: IProps) => {
    const [isSelectedReportType, setCurrentReportType] = useState<boolean>(false);
    const { getFieldDecorator, validateFields, resetFields } = form;

    /**
     * Function called when `Cancel` button is clicked inside specialised reports panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Handle side effects logic when selecting a report type
     * @param value selected value
     */
     const handleOnSelectReportType = (value: string | any) => {
        const selectedReportType = reportOptions.options.find(option => option.ReportType === value);
        setCurrentReportType(!!(selectedReportType && selectedReportType.ReportType));
    };

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                if (downloadToExcelCallBack) {
                    downloadToExcelCallBack(get(values, 'ReportType'))
                } 

                if (closePanel) {
                    closePanel();
                }
            }
        });
    };

    /**
     * Function called for populating the specialised reports to panel content.
     * Loads a skeleton with loading prop while data is still fetched.
     */
    const populatePanelContent = () => {
        if (reportOptions.loading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {
            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <FormItem>
                                {getFieldDecorator(
                                    'ReportType',
                                    {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select the report to run!'
                                            }
                                        ]
                                    }
                                )(
                                    <Select
                                        placeholder="Select a report"
                                        style={{ width: '100%' }}
                                        loading={reportOptions.loading}
                                        onSelect={handleOnSelectReportType}
                                    >
                                        {map(
                                            reportOptions.options,
                                            ({ ReportType }) => (
                                                <Option key={ReportType} value={ReportType}>
                                                    {ReportType}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            );
        }
    };

    return (
        <Row>
            <Col>
                <div>Please select the report to run</div>
                <div>{populatePanelContent()}</div>
                <br />
                <div>Please note, the report uses the currently applied filters to allow you to target a specific subset of information.</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            disabled ={!isSelectedReportType}
                            onClick={handleSubmitForm}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const SpecialisedReportsPanelForm = Form.create({
    name: 'specialised-reports-panel-form',
})(SpecialisedReportsPanel);

export default withRouter(SpecialisedReportsPanelForm);
