export enum InviteActionTypes {
    COMPANY_INVITE_CREATE = '@@invite/COMPANY_INVITE_CREATE',
    COMPANY_INVITE_VALIDATE = '@@invite/COMPANY_INVITE_VALIDATE',
    COMPANY_INVITE_LINK = '@@invite/COMPANY_INVITE_LINK',
    COMPANY_INVITE_LINKING_IN_PROGRESS_UPDATE = '@@invite/COMPANY_INVITE_LINKING_IN_PROGRESS_UPDATE',
}

export interface InvitesState {
    readonly companyInviteLinkingInProgress: boolean;
}

interface StatusCodeWithMessageResponse {
    IsSuccess: boolean;
    Messages: string[];
}

export interface CompanyInviteCreatePayload {
    RoleId: string;
    EmailAddress: string[];
}

export interface CompanyInviteCreateResponse
    extends StatusCodeWithMessageResponse {}

export interface CompanyInviteValidateResponse {
    IsValid: boolean;
    ErrorMessages: string[];
    CompanyName: string;
}

export interface CompanyInviteLinkResponse
    extends StatusCodeWithMessageResponse {}
