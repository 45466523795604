import { reportSortByOptions } from "../../constants/reportSortAndFilters";
import { PageData } from "../common/types";
import { Company } from "../companies/types";
import { User } from "../users/types";

export enum ReportActionTypes {
    GET_REPORTS_REQUEST = '@@report/GET_REPORTS_REQUEST',
    GET_REPORTS_SUCCESS = '@@report/GET_REPORTS_SUCCESS',
    GET_REPORTS_ERROR = '@@report/GET_REPORTS_ERROR',
    GET_REPORTS_FOR_ORGANISATION_REQUEST = '@@report/GET_REPORTS_FOR_ORGANISATION_REQUEST',

    UPDATE_REPORTS_TABLE_FILTER_STATE = '@@report/UPDATE_REPORTS_TABLE_FILTER_STATE',

    UPDATE_REPORTS_SORT_BY_AND_STATE = '@@report/UPDATE_REPORTS_SORT_BY_AND_STATE',

    UPDATE_REPORTS_FILTERS = '@@report/UPDATE_REPORTS_FILTERS',
    DOWNLOAD_COMPLETE_REPORTS = '@@report/DOWNLOAD_COMPLETE_REPORTS',
    SET_REPORT_SELECTED_ID_REQUEST = '@@report/SET_REPORT_SELECTED_ID_REQUEST',
    SET_REPORT_SELECTED_ID = '@@report/SET_REPORT_SELECTED_ID',
    GET_REPORT_DATA_REQUEST = '@@report/GET_REPORT_DATA_REQUEST',
    GET_REPORT_DATA_FOR_ORGANISATION_REQUEST = '@@report/GET_REPORT_DATA_FOR_ORGANISATION_REQUEST',
    GET_REPORT_DATA_SUCCESS = '@@report/GET_REPORT_DATA_SUCCESS',
    GET_REPORT_DATA_ERROR = '@@report/GET_REPORT_DATA_ERROR',
    CLEAR_REPORT_DATA = '@report/CLEAR_REPORT_DATA'
}

export interface ReportState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: ReportHistory[];
    readonly pageData: PageData;
    readonly filters: GetReportsRequestPayload['filters'];
    readonly sortBy: GetReportsRequestPayload['sortBy'];
    readonly tableFilter: string | undefined;
    readonly sortAscending: boolean;
    readonly activeData: {
        loading: boolean;
        record: {};
        selectedId: string | null;
        errorMessages: string[];
    };
}

export interface ReportHistory {
    readonly Id: string;
    readonly ReportJobId: string;
    readonly CompanyId?: string;
    readonly OrganisationId?: string;
    readonly Region?: string;
    readonly Company: Company;
    readonly User: User;
    readonly Title: string;
    readonly Description: string;
    readonly Type: string;
    readonly Status: string;
    readonly CreatedDateTime: string;
    readonly CompletedDateTime: string;
    readonly ReportAppliedFilters: ReportAppliedFilters[]
}

export interface ReportAppliedFilters {
    readonly Name: string;
    readonly Value: string;
}

export interface ReportTypeFilter {
    Type: number;
    Descriptions: string[];
}

export interface GetReportsRequestPayload {
    filters: {
        ReportJobId?: string
        UserId?: string;
        CreatedDateMin?: string
        CreatedDateMax?: string
        ReportTypeFilters?: ReportTypeFilter[];
        Status?: number;
    };
    excludeReports?: boolean;
    sortBy: typeof reportSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    Region?: string;
}
