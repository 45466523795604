import { map } from 'lodash';
import moment from 'moment-timezone';

export const invoicesSortValues = {
    INVOICE_NUMBER: 'Invoice number',
    CUSTOMER_CODE: 'Customer code',
    COMPANY_NAME: 'Company name',
    CREATED_DATE: 'Invoice created date',
    DUE_DATE: 'Invoice due date',
    AMOUNT_OWING: 'Amount owing',
};

export const invoicesAmountOperatorOptions = [
    {
        label: 'Greater or matching',
        value: '>=',
    },
    {
        label: 'Greater than',
        value: '>',
    },
    {
        label: 'Lesser or matching',
        value: '<=',
    },
    {
        label: 'Less than',
        value: '<',
    },
    {
        label: 'Equal to',
        value: '=',
    },
];
export const invoicesAmountType = [
    { label: 'Owing', value: 'Owing' },
    { label: 'Total', value: 'Total' },
];
export const dateSelectOptions = {
    THIS_MONTH: 'This month',
    NEXT_MONTH: 'Next month',
    LAST_MONTH: 'Last month',
    LAST_7_DAYS: 'Last 7 days',
    LAST_30_DAYS: 'Last 30 days',
    NEXT_7_DAYS: 'Next 7 days',
    NEXT_30_DAYS: 'Next 30 days',
    CUSTOM_DATE_RANGE: 'Custom date range',
    CUSTOM_DAYS_RANGE: 'Custom days range',
    NOW: 'Now',
    WITHIN_THIS_WEEK: 'Within this week',
    WITHIN_NEXT_7_DAYS: 'Within next 7 days',
    LAST_WEEK_UP_TO_NOW: 'Last week up to now',
    TODAY: 'Today',
    CREATE_DATE_ATB_CURRENT: 'Create date ATB: Current',
    CREATE_DATE_ATB_30_DAYS: 'Create date ATB: 30 days',
    CREATE_DATE_ATB_60_DAYS: 'Create date ATB: 60 days',
    CREATE_DATE_ATB_90_PLUS_DAYS: 'Create date ATB: 90+ days',
    CREATE_DATE_ATB_1_MONTH: 'Create date ATB: 1 month',
    CREATE_DATE_ATB_2_MONTHS: 'Create date ATB: 2 months',
    CREATE_DATE_ATB_3_PLUS_MONTHS: 'Create date ATB: 3+ months',
    DUE_DATE_ATB_NOT_DUE: 'Due date ATB: Not due',
    DUE_DATE_ATB_CURRENT: 'Due date ATB: Current',
    DUE_DATE_ATB_30_DAYS: 'Due date ATB: 30 days',
    DUE_DATE_ATB_60_DAYS: 'Due date ATB: 60 days',
    DUE_DATE_ATB_90_PLUS_DAYS: 'Due date ATB: 90+ days',
    DUE_DATE_ATB_1_MONTH: 'Due date ATB: 1 month',
    DUE_DATE_ATB_2_MONTHS: 'Due date ATB: 2 months',
    DUE_DATE_ATB_3_PLUS_MONTHS: 'Due date ATB: 3+ months',
    AVAILABLE_DATE_ATB_NOT_AVAILABLE: 'Available date ATB: Not available',
    AVAILABLE_DATE_ATB_CURRENT: 'Available date ATB: Current',
    AVAILABLE_DATE_ATB_30_DAYS: 'Available date ATB: 30 days',
    AVAILABLE_DATE_ATB_60_DAYS: 'Available date ATB: 60 days',
    AVAILABLE_DATE_ATB_90_PLUS_DAYS: 'Available date ATB: 90+ days',
    AVAILABLE_DATE_ATB_1_MONTH: 'Available date ATB: 1 month',
    AVAILABLE_DATE_ATB_2_MONTHS: 'Available date ATB: 2 months',
    AVAILABLE_DATE_ATB_3_PLUS_MONTHS: 'Available date ATB: 3+ months',
    UNDEFINED: undefined
};

const {
    THIS_MONTH,
    NEXT_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    NEXT_7_DAYS,
    NEXT_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
    CREATE_DATE_ATB_CURRENT,
    CREATE_DATE_ATB_30_DAYS,
    CREATE_DATE_ATB_60_DAYS,
    CREATE_DATE_ATB_90_PLUS_DAYS,
    CREATE_DATE_ATB_1_MONTH,
    CREATE_DATE_ATB_2_MONTHS,
    CREATE_DATE_ATB_3_PLUS_MONTHS,
    DUE_DATE_ATB_NOT_DUE,
    DUE_DATE_ATB_CURRENT,
    DUE_DATE_ATB_30_DAYS,
    DUE_DATE_ATB_60_DAYS,
    DUE_DATE_ATB_90_PLUS_DAYS,
    DUE_DATE_ATB_1_MONTH,
    DUE_DATE_ATB_2_MONTHS,
    DUE_DATE_ATB_3_PLUS_MONTHS,
    UNDEFINED
} = dateSelectOptions;

export const conversationCreatedDateOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const invoicesCreatedInOptions = (isCalendarView: boolean) => [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CREATE_DATE_ATB_CURRENT,
    isCalendarView ? CREATE_DATE_ATB_1_MONTH : CREATE_DATE_ATB_30_DAYS,
    isCalendarView ? CREATE_DATE_ATB_2_MONTHS : CREATE_DATE_ATB_60_DAYS,
    isCalendarView ? CREATE_DATE_ATB_3_PLUS_MONTHS : CREATE_DATE_ATB_90_PLUS_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const invoicesDueInOptions = (isCalendarView: boolean) => [
    THIS_MONTH,
    NEXT_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    NEXT_7_DAYS,
    NEXT_30_DAYS,
    DUE_DATE_ATB_NOT_DUE,
    DUE_DATE_ATB_CURRENT,
    isCalendarView ? DUE_DATE_ATB_1_MONTH : DUE_DATE_ATB_30_DAYS,
    isCalendarView ? DUE_DATE_ATB_2_MONTHS : DUE_DATE_ATB_60_DAYS,
    isCalendarView ? DUE_DATE_ATB_3_PLUS_MONTHS : DUE_DATE_ATB_90_PLUS_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const customersSettlementSettledInOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const invoicesSortByOptions = [
    { label: 'Invoice number', value: invoicesSortValues.INVOICE_NUMBER },
    { label: 'Customer code', value: invoicesSortValues.CUSTOMER_CODE },
    { label: 'Company name', value: invoicesSortValues.COMPANY_NAME },
    { label: 'Invoice created date', value: invoicesSortValues.CREATED_DATE },
    { label: 'Invoice due date', value: invoicesSortValues.DUE_DATE },
    { label: 'Amount owing', value: invoicesSortValues.AMOUNT_OWING },
];


export const customersSettlementSortByOptions = [
    { label: 'Customer', value: 'display name' },
    { label: 'Settlement average days', value: 'settlement average' },
];

export const preConfiguredFilters = {
    ALL_INVOICES: 'All invoices',
};

export const invoicesTableFilterOptions = [
    // { label: 'All open invoices', value: 'Open' },
    { label: 'All invoices', value: preConfiguredFilters.ALL_INVOICES },
];

export const invoicesStateFilterOptions = [
    { label: 'All open invoices', value: 'Open' },
    { label: 'All invoices', value: 'All' },
];

export const invoiceStateMap = {
    OPEN: 'Open',
    ALL: 'All'
}

export const invoicesPausedStateOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

export const invoicesFilterBarFilters = (customerValuesOption?: any, customerLabel?: string, isCalendarView?: boolean) => {
    const filters = [
        {
            filterName: 'Invoice number',
            filterStateName: 'InvoiceNumber',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_INVOICES_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: invoicesSortValues.INVOICE_NUMBER,
            filterResponse: 'GetInvoicesForCompany.Invoices',
            filterLabelField: 'Number',
        },
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: 'Created in',
            filterStateName: 'CreatedDate',
            filterElement: 'select',
            filterType: 'text',
            filterMaxDate: moment(),
            filterOptions: invoicesCreatedInOptions(!!isCalendarView),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'last-days-range',
            },
            // offset: 1,
            // span: 5,
        },
        {
            filterName: 'Due in',
            filterStateName: 'DueDate',
            filterElement: 'select',
            filterType: 'text',
            filterOptions: invoicesDueInOptions(!!isCalendarView),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range',
            },
            // span: 5,
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    AmountType: invoicesAmountType,
                },
                {
                    AmountOperator: invoicesAmountOperatorOptions,
                },
            ],
            // offset: 1,
        },
        {
            filterName: 'Paused',
            filterStateName: 'IsBlocked',
            filterElement: 'radio-group',
            filterPlaceholder: 'Select pause state',
            filterType: 'text',
            filterOptions: invoicesPausedStateOptions,
        },
        {
            filterName: 'Country',
            filterStateName: 'CustomerCountry',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerCountry',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'Country',
        },
        {
            filterName: 'Postal State',
            filterStateName: 'CustomerState',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerState',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'State',
        },
        // {
        //     filterName: 'Campaign',
        //     filterElement: 'input',
        //     filterType: 'text',
        // },
        // {
        //     filterName: 'PO',
        //     filterElement: 'input',
        //     filterType: 'text',
        //     offset: 1,
        // },
        // {
        //     filterName: 'Credit Status',
        //     filterStateName: 'CreditStatus',
        //     filterElement: 'input',
        //     filterType: 'text',
        //     offset: 1,
        // },
        // {
        //     filterName: 'Sales Rep',
        //     filterStateName: 'SalesRep',
        //     filterElement: 'input',
        //     filterType: 'text',
        //     offset: 1,
        // },
    ];
    
    if (customerValuesOption && customerLabel) {
        filters.splice(2, 0, {
            filterName: customerLabel,
            filterStateName: 'CustomerFirstLetter',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: customerValuesOption
        });
    }

    return filters;
};

const conversationFilterTypeOptions = [
    { label: 'Comment', value: 1 },
    { label: 'Communication', value: 4 },
    { label: 'Support ticket', value: 2 },
    { label: 'Payment plans', value: 8 },
];

export const invoicesConversationFilterBarFilters = [
    {
        filterName: 'Type',
        filterElement: 'checkbox-group',
        filterType: 'array',
        filterOptions: conversationFilterTypeOptions,
    },
    // {
    //     filterName: 'User',
    //     filterStateName: 'UserInputData',
    //     filterElement: 'input-checkbox-group',
    //     filterType: 'text',
    //     filterOptions: {
    //         input: {
    //             name: 'Name',
    //         },
    //         checkboxGroup: {
    //             name: 'FromCustomer',
    //             options: [{ label: 'From the customer', value: 1 }],
    //         },
    //     },
    // },
    {
        filterName: 'Created',
        filterStateName: 'CreatedDate',
        filterElement: 'select',
        filterType: 'text',
        filterOptions: conversationCreatedDateOptions,
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'last-days-range',
        },
    },
];

const taksFilterTypeOptions = map(
    ['Approval', 'Communication', 'Query'],
    (option: string) => ({
        label: option,
        value: option,
    })
);

export const invoicesTasksFilterBarFilters = [
    {
        filterName: 'Type',
        filterElement: 'checkbox-group',
        filterType: 'array',
        filterOptions: taksFilterTypeOptions,
    },
    {
        filterName: 'User',
        filterElement: 'input',
        filterType: 'text',
    },
    {
        filterName: 'Created',
        filterElement: 'select',
        filterType: 'text',
        filterOptions: [
            'This month',
            'Last month',
            'Last 7 days',
            'Last 30 days',
            'Custom date range',
            'Custom days range',
        ],
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'last-days-range',
        },
    },
];

export const commonInvoiceListInvoicesSortOptions = [
    {
        label: 'Due date',
        value: invoicesSortValues.DUE_DATE,
    },
    {
        label: 'Amount',
        value: invoicesSortValues.AMOUNT_OWING,
    },
    {
        label: 'Invoice number',
        value: invoicesSortValues.INVOICE_NUMBER,
    },
    // {
    //     label: 'Status',
    //     value: 'Status',
    // },
];

export const invoiceBlockAction = {
    PAUSE: 'Pause',
    RESUME: 'Resume',
};

/**
 * For Invoice changes
 */

export const invoiceChangesFilters = {
    ALL_CHANGES: 'All changes',
};

export const invoiceChangeTypes = {
    NewResource: 'NewResource',
    UpdatedResource: 'UpdatedResource',
};

export const invoiceChangesTableFilterOptions = [
    {
        label: 'All changes',
        value: invoiceChangesFilters.ALL_CHANGES,
    },
];

export const invoicesWidgetFilters = (isCalendarView: boolean) => [
    {
        filterName: 'Customer',
        filterStateName: 'Customer',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'Company name',
        filterResponse: 'GetCustomersForCompany.Customers',
        filterLabelField: 'DisplayName',
    },
    {
        filterName: 'Paused',
        filterStateName: 'IsBlocked',
        filterElement: 'radio-group',
        filterPlaceholder: 'Select Pause State',
        filterType: 'text',
        filterOptions: invoicesPausedStateOptions,
    },
    {
        filterName: 'Country',
        filterStateName: 'CustomerCountry',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'CustomerCountry',
        filterResponse: 'GetCustomersForCompany.Customers',
        filterLabelField: 'Country',
    },
    {
        filterName: 'Postal State',
        filterStateName: 'CustomerState',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'CustomerState',
        filterResponse: 'GetCustomersForCompany.Customers',
        filterLabelField: 'State',
    },
    {
        filterName: 'Amount',
        filterStateName: 'Amount',
        filterElement: 'select-multiple-and-input-amount',
        filterType: 'text',
        filterOptions: [
            {
                amountType: invoicesAmountType,
                filterOptionName: 'AmountType',
                filterPlaceholder: 'Select Amount Type'
            },
            {
                amountOperator: invoicesAmountOperatorOptions,
                filterOptionName: 'AmountOperator',
                filterPlaceholder: 'Select Operator',
            },
        ],
        // offset: 1,
    },
    {
        filterName: 'Created in',
        filterStateName: 'CreatedDate',
        filterElement: 'select',
        filterType: 'text',
        filterMaxDate: moment(),
        filterOptions: invoicesCreatedInOptions(isCalendarView),
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'last-days-range',
        },
        filterPlaceholder: 'Select Date Range',
        // offset: 1,
        // span: 5,
    },
    {
        filterName: 'Due in',
        filterStateName: 'DueDate',
        filterElement: 'select',
        filterType: 'text',
        filterOptions: invoicesDueInOptions(isCalendarView),
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'all-days-range',
        },
        filterPlaceholder: 'Select Date Range',
        // span: 5,
    },
];

export const customersSettlementFilters = [
    {
        filterName: 'Customer',
        filterStateName: 'Customer',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'Company name',
        filterResponse: 'GetCustomersForCompany.Customers',
        filterLabelField: 'DisplayName',
    },
    {
        filterName: 'Country',
        filterStateName: 'CustomerCountry',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'Country',
        filterResponse: 'GetCustomersForCompany.Customers',
        filterLabelField: 'Country',
    },
    {
        filterName: 'Postal State',
        filterStateName: 'CustomerState',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'State',
        filterResponse: 'GetCustomersForCompany.Customers',
        filterLabelField: 'State',
    },
    {
        filterName: 'Settled in',
        filterStateName: 'SettlementDate',
        filterElement: 'select',
        filterType: 'text',
        filterMaxDate: moment(),
        filterOptions: customersSettlementSettledInOptions,
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'all-days-range-from-to-last',
        },
        filterPlaceholder: 'Select Date Range',
        // offset: 1,
        // span: 5,
    },
    {
        filterName: 'Number of days',
        filterStateName: 'NumberOfDays',
        filterElement: 'select-multiple-and-input-days',
        filterType: 'text',
        filterOptions: [
            {
                amountOperator: invoicesAmountOperatorOptions,
                filterOptionName: 'DaysOperator',
                filterPlaceholder: 'Select Operator',
            },
        ],
    },
];
