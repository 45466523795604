export interface TargetContentConstant {
    value: string;
    templateKeyName: string;
    label: string;
    fileType: string;
}

export const targetContentMap: { [key: string]: TargetContentConstant } = {
    EmailBodyContent: {
        value: 'EmailBodyContent',
        templateKeyName: 'EmailBodyTemplateKey',
        label: 'Email body',
        fileType: 'Email body'
    },
    EmailSubjectContent: {
        value: 'EmailSubjectContent',
        templateKeyName: 'EmailSubjectTemplateKey',
        label: 'Email subject',
        fileType: 'Email subject',
    },
    LetterContent: {
        value: 'LetterContent',
        templateKeyName: 'LetterTemplateKey',
        label: 'Letter',
        fileType: 'Letter'
    },
    SMSContent: {
        value: 'SMSContent',
        templateKeyName: 'SMSTemplateKey',
        label: 'SMS',
        fileType: 'SMS'
    },
};

export const CONTENT_CUSTOMIZATION_EDITOR_FORMAT = 'content-customization';

export const defaultContentValues = {
    wrapElement: 'p'
}
