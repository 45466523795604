/**
 * Component drawer for Create credit manually panel.
 */

import { Drawer } from 'antd';
import React from 'react';
import { populatePopoverContainer } from '../../utils/commonFunctions';
import CreateCreditManuallyPanel, { IProps } from './CreateCreditManuallyPanel';

interface ICreateCreditManuallyProps extends IProps {
    visible: boolean;
    containerRef?: any;
}

const CreateCreditManuallyDrawer: React.FC<ICreateCreditManuallyProps> = ({
    visible,
    containerRef,
    ...props
}: ICreateCreditManuallyProps) => {
    return (
        <Drawer
            getContainer={populatePopoverContainer(containerRef)}
            visible={visible}
            width={750}
            onClose={() => props.closePanel && props.closePanel(true)}
            title="Create credits/rebates"
            maskClosable={false}
            closable={false}
        >
            {visible && <CreateCreditManuallyPanel
                containerRef={containerRef}
                {...props}
            />}
        </Drawer>
    );
};

export default CreateCreditManuallyDrawer;
