/**
 * Component for the Sales > Invoices page. `/app/sales/invoices`
 */

import { Button, Col, Row, Select, Typography } from 'antd';
import {
    capitalize,
    debounce,
    difference,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
    some,
    toLower,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withAccountingSystemHandler } from '../../components/common/AccountingSystemHandler';
import ActionBar from '../../components/common/ActionBar';
import { customFieldIndicator } from '../../components/common/FilterBar';
import FontAwesome from '../../components/common/FontAwesome';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import SpecialisedReportsDrawer from '../../components/common/SpecialisedReportsDrawer';
import VirtualizedList from '../../components/common/VirtualizedList';
import InvoiceItemComponent from '../../components/sales/InvoiceItemComponent';
import {
    INVOICES_PAGE,
    PAGE_NAMES_FOR_VIEW,
} from '../../config/tableAndPageConstants';
import { SpecialisedReports } from '../../constants/common';
import {
    defaultPageSizeForReport,
    invoicesOrganisationSummaryPageQuery,
    invoicesOrganisationSummaryQueryName,
} from '../../constants/downloadToExcel';
import {
    invoicesFilterBarFilters,
    invoicesSortByOptions,
    invoicesStateFilterOptions,
} from '../../constants/invoicesSortAndFilters';
import { ApplicationState } from '../../store';
import {
    downloadToExcelAction,
} from '../../store/common/actions';
import {
    CompaniesState,
    Company,
    CompanyCustomFieldConfigure,
} from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    getInvoicesForOrganisationRequestAction,
    getOrganisationInvoiceDataRequestAction,
    setSelectedInvoiceIdRequestAction,
    updateInvoicesFiltersAction,
    updateInvoicesSortByAndStateAction,
    updateInvoicesTableFilterAction,
} from '../../store/invoices/actions';
import { initialState } from '../../store/invoices/reducer';
import { getSelectedInvoiceId } from '../../store/invoices/sagas';
import {
    GetInvoicesRequestPayload,
    InvoiceOrganisationCurrency,
    InvoicesState,
} from '../../store/invoices/types';
import {
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getIfIsLg,
    getSortFieldsWithCustomFields,
    getUsedSortByOptionsIfCloud,
    insertAt,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    useCompanyFlagValue,
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { Currency, Organisation } from '../../store/organisations/types';
import { AtbViewType } from '../../constants/settings';

const InvoiceItemDetailsDrawerComponent = lazy(
    () => import('../../components/sales/InvoiceItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

interface IProps {
    readonly region: string;
    readonly functionRefObj: any;
    readonly isUsingCloudImportType: boolean;
    readonly conversationRate?: number,
    readonly usingCustomerWorkflow: boolean,
    readonly companies: Company[];
    readonly currencies?: Currency[],
    readonly customFieldsFilterList: [];
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}
let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchInvoicesRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = INVOICES_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.INVOICES_PAGE;
let initialTableFilter: undefined | string = undefined;
const OrganisationInvoicesContent: React.FC<IProps> = ({
    region,
    functionRefObj,
    customFieldsFilterList,
    isUsingCloudImportType,
    conversationRate,
    currencies,
    usingCustomerWorkflow,
    companies,
    handlePageViewSelection,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);
    const dispatch = useDispatch();
    
    const userCompanies: CompaniesState['userCompanies'] = useSelector(
        (state: ApplicationState) => state.companies.userCompanies
    );
    const orgUserCompanies = filter(userCompanies, uc => companies.map(c => c.CompanyId).includes(uc.Company.CompanyId));
    const usingMultipleWorkflow: boolean = some(orgUserCompanies, uc => uc.Company.UsingMultipleWorkflow);
    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);
    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();
    const [filterItemList, setFilterItemList] = useState<DynamicObject[]>([]);

    const selectedId = useSelector(getSelectedInvoiceId);

    const [usedRowHeight, setUsedRowHeight] = useState<number>(
        INVOICES_PAGE.rowHeight
    );

    const [invoiceDetailsDrawer, setInvoiceDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
    }>({
        visible: false,
        activeTab: undefined,
    });

    const invoicesState: InvoicesState = useSelector(
        (state: ApplicationState) => state.invoices
    );

    const [invoicesTableFilter, setInvoicesTableFilter] = useState<
        string | undefined
    >(
        isEqual(invoicesState.filters, initialState.filters)
            ? initialState.tableFilter
            : invoicesState.tableFilter
    );

    const organisationLoading: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.loading
    );

    const [tableFilters, setTableFilters] = useState<any>(
        invoicesState.filters
    );

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
        selectedPausedKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
        selectedPausedKeys: [],
    });

    /**
     * Function that checks if there are filters applied and saved in redux.
     * Returns a boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(invoicesState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        addCommentDrawer: boolean;
        allSelected: boolean;
        raiseTicketDrawer: boolean;
        downloadToExcel: boolean;
        specialisedReportsPanel: boolean;
        pauseResumeDrawer: boolean;
        createNewPaymentPlanDrawer: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        addCommentDrawer: false,
        allSelected: false,
        raiseTicketDrawer: false,
        downloadToExcel: false,
        specialisedReportsPanel: false,
        pauseResumeDrawer: false,
        createNewPaymentPlanDrawer: false
    });

    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        sortAscending: boolean;
        invoiceState: string;
    }>({
        sortBy: invoicesState.sortBy,
        sortAscending: invoicesState.sortAscending,
        invoiceState: invoicesState.invoiceState,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(invoicesState, 'pageData.currentPage', 0)
    );

    /**
     * Function to get used sort options
     */
    const getInvoicesSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            invoicesSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!region) return;
        const usedSortByOptions = getInvoicesSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        forEach(
            customFieldsFilterList,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                usedSortValues.push(
                    `${customFieldIndicator}${Type}--${FieldName}`
                );
            }
        );
        if (!includes(usedSortValues, invoicesState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateInvoicesSortByAndStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        region,
        invoicesState.sortBy,
    ]);

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {        
        let allFilters = {
            ...invoicesState.filters
        };

        if(companies && isEmpty(allFilters.CompanyIds)) {
            allFilters.CompanyIds = companies.map(c => c.CompanyId);
        }

        const payload: GetInvoicesRequestPayload = {
            filters: allFilters,
            sortBy: invoicesState.sortBy,
            sortAscending: invoicesState.sortAscending,
            pageSize,
            currentPage: currentPage,
            invoiceState: invoicesState.invoiceState,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
            region: region,
            conversionCurrency: conversationRate,
            organisationCurrencies: currencies ? currencies.map(item => ({
                Value: item.Value,
                Rate: item.Rate
            })) : []
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for fetching the Invoices data.
     * @param currentPage - current page to fetch data from
     * @param pageSize - number of items in page
     */
    const fetchInvoices = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {

        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getInvoicesForOrganisationRequestAction(payload));
    };

    /**
     * Function called in preparation for fetching the invoices data.
     * Manages the page to be called.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(region) ||
            invoicesState.loading
        )
            return;

        if (!invoicesState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchInvoices(nextPage);
    };
    
    /**
     * Listener function for calling the fetch invoices function.
     * Can be triggered upon changes on selected user company, filter values, sort values, and invoice state filter update.
     */
    const callFetchInvoices = () => {
        if (!region) return;

        resetAllSelectedRowKeys();
        checkAllTableFiltersOnCompanySwitch();
        resetTableScrollAndPageData();

        if (!organisationLoading) fetchInvoices(0);
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending, invoiceState } = invoicesState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending ||
            invoiceState !== tableSortState.invoiceState
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending,
                invoiceState,
            });
        }
    };

    useEffect(callFetchInvoices, [
        invoicesState.sortBy,
        invoicesState.sortAscending,
        invoicesState.filters,
        invoicesState.invoiceState,
        organisationLoading
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = invoicesState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchInvoicesRequired) {
                fetchInvoices(0);
                fetchInvoicesRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [invoicesState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Listener function responsible for showing the state of checkboxes (checked/unchecked) upon data update.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(invoicesState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [invoicesState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function called when invoice table filter (upper-left dropdown next to refresh button) is updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeInvoicesTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== invoicesState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setInvoicesTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === invoicesState.tableFilter) {
            handleInvoiceFilterRefresh();
        } else {
            dispatch(updateInvoicesTableFilterAction(filter));
        }
    };

    /**
     * Function called when the refresh button is clicked.
     */
    const handleInvoiceFilterRefresh = (
        showConditionsObject?: DynamicObject
    ) => {
        fetchInvoicesRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys(showConditionsObject);
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchInvoices(0);
    };

    /**
     * Function for getting the selected invoices values.
     */
    const getSelectedInvoicesValues = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        let allExcept = false;
        let keysToUse = selectedRowKeys || [
            ...tableRowSelection.selectedRowKeys,
        ];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = unselectedRowKeys || [
                ...tableRowSelection.unselectedRowKeys,
            ];
        }

        const filterObject = {
            ...tableFilters,
            InvoiceState: tableSortState.invoiceState,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    /**
     * Function for populating the dropdown render of the table filter (upper-left).
     * @param menu - menu items component
     */
    const populateInvoiceFilterSelectDropdownRender = (menu: any) => (
        <div>
            {menu}
            {/* <Divider className="action-bar-divider" />
            <div
                className="pa-12 cursor-p"
                onMouseDown={() => console.log('Add Custom View Clicked')}
            >
                Add custom view
            </div> */}
        </div>
    );

    /**
     * Function for populating the `Select/Deselect all` button label.
     */
    const populateSelectDeselectAllLabel = () => {
        const allDataLoaded = invoicesState.pageData.hasNextPage === false;

        let selectDeselectLabel = '';
        if (
            !isEmpty(invoicesState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                    invoicesState.data.length ===
                        tableRowSelection.selectedRowKeys.length))
        ) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return `${selectDeselectLabel} all`;
    };

    /**
     * Function triggered when the select/deselect all button is clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length <
                invoicesState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(invoicesState.data, selectedKeysName);

            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });

        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
                selectedPausedKeys: [],
            });
        }
    };

    /**
     * Function called when the sort order button link is clicked (inside the ellipsis popover).
     * @param sortAscending - boolean indicator if sort order is ascending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateInvoicesSortByAndStateAction({
                ...tableSortState,
                sortAscending,
            })
        );
    };

    const downloadDisabled =
        invoicesState.loading || isEmpty(invoicesState.data);

    /**
     * Function for populating the upper section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                Filter
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            invoicesState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                Change sort order
            </Button>
            <br />
            <Button
                type="link"
                onClick={() => downloadToExcelHandler()}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                Download to Excel
            </Button>
            <br />
            <Button
                type="link"
                onClick={() => {
                    updateShowConditionsObject({
                        filterEllipsis: false,
                        specialisedReportsPanel: true
                    })
                }}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                Specialised Reports
            </Button>
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = (specializedReportType?: string) => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
        } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice'
        );

        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const variables = {
            ...cleanFilters,
            InvoiceState: invoiceState,
            // SortField: sortBy,
            ...sortObject,
            Ascending: sortAscending,
            IsCloudImportType: isUsingCloudImportType,
            IsMultipleWorkflow: usingMultipleWorkflow,
            UsingCustomerWorkflow: usingCustomerWorkflow,
        };

        const payload = {
            Query: invoicesOrganisationSummaryPageQuery,
            OperationName: invoicesOrganisationSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.INVOICES_ORGANISATION_PAGE,
            SpecializedReport: specializedReportType
        };

        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function for populating the lower section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => (
        <div className="pop-action-content">
            <div className="mb-10">
                <span>Sort by</span>
            </div>
            <div>
                <Select
                    onChange={(sortBySelected: string) =>
                        updateTableSortStateObject({
                            sortBy: sortBySelected,
                        })
                    }
                    value={tableSortState.sortBy}
                >
                    {map(
                        getInvoicesSortByOptions(),
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: string;
                        }) => {
                            const labelUsed = label
                                .replace('Customer', capitalize(customerLabel))
                                .replace('customer', toLower(customerLabel));
                            return (
                                <Option key={value} value={value}>
                                    {labelUsed}
                                </Option>
                            );
                        }
                    )}
                    {map(
                        customFieldsFilterList,
                        ({
                            Type,
                            Number: CFNumber,
                            FieldName,
                        }: CompanyCustomFieldConfigure) => (
                            <Option
                                key={Type + CFNumber + FieldName}
                                value={`${customFieldIndicator}${Type}--${FieldName}`}
                            >
                                {FieldName}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="mb-10">
                <span>State of invoices</span>
            </div>
            <div>
                <Select
                    onChange={(stateSelected: string) =>
                        updateTableSortStateObject({
                            invoiceState: stateSelected,
                        })
                    }
                    value={tableSortState.invoiceState}
                >
                    {map(
                        invoicesStateFilterOptions,
                        ({ label, value }: any) => (
                            <Option key={value} value={value}>
                                {label}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="ta-right">
                <Button
                    type="primary"
                    disabled={
                        invoicesState.loading ||
                        (tableSortState.sortBy === invoicesState.sortBy &&
                            tableSortState.invoiceState ===
                                invoicesState.invoiceState)
                    }
                    onClick={applySortedByAndState}
                >
                    Apply
                </Button>
            </div>
        </div>
    );

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (invoicesState.tableFilter !== initialTableFilter) {
            setInvoicesTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeInvoicesTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called when applying the filters.
     * Set the filters in state and redux.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     * @param fromQuickSearch - boolean indicator if called from the quick search component
     */
    const applyFilters = async (
        filters?: GetInvoicesRequestPayload['filters'],
        fromFilterBar?: boolean,
        fromQuickSearch?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    invoicesTableFilter,
                    fromFilterBar,
                    () => {
                        changeInvoicesTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortState(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateInvoicesFiltersAction(appliedFilters));
        const showConditionsObject = fromQuickSearch ? { filterBar: true } : {};
        await resetAllSelectedRowKeys(showConditionsObject);
    };
    functionRefObj.applyFilters = applyFilters;

    /**
     * Function called when `Apply` button is clicked inside the ellipsis popover,
     * also hides the ellipsis popover after `Save` is clicked.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortState;
        await dispatch(updateInvoicesSortByAndStateAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions((prevState) => ({
            ...prevState,
            ...showConditionObject
        }));
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function called to close the invoice details drawer.
     * Clears the data saved during drawer close.
     */
    const closeInvoiceDetailsDrawer = () => {
        setInvoiceDetailsDrawer({
            visible: false,
            activeTab: undefined,
        });
    };

    /**
     * Function to update the `tableSortState` state.
     * @param tableSortStateObject - must conform to `tableSortState`
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when clicking on checkbox.
     * @param record - record for the row where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     * @param activeTab - kind of panel to show based on the row clicked
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setSelectedInvoiceIdRequestAction(get(record, 'Id'), () => {
                setInvoiceDetailsDrawer({
                    visible: true,
                    activeTab,
                });
            })
        );
    };

    /**
     * Function for populating the table loading text.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } invoices`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected (left side checkbox is ticked).
     * @param record - data for the selected row
     * @param selected - boolean indicator if the row is selected/unselected
     * @param selectedRows - selected row keys
     * @param nativeEvent - event fired when the row is selected
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        const selectedPausedKeys = [...tableRowSelection.selectedPausedKeys];

        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );

            if (record.IsBlocked) {
                selectedPausedKeys.push(record.key);
            }
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];

            if (record.IsBlocked) {
                remove(
                    selectedPausedKeys,
                    (pausedKey) => pausedKey === record.key
                );
            }
        }


        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
            selectedPausedKeys,
        });
    };

    /**
     * Function to control the visibility of popovers.
     * @param name - name of popover to change the visibility
     * @param condition - boolean indicator to apply the condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'raiseTicketDrawer' ||
                    name === 'addCommentDrawer' ||
                    name === 'pauseResumeDrawer' ||
                    name === 'createNewPaymentPlanDrawer'
                ) {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for resetting the table scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all the selected/unselected row keys.
     */
    const resetAllSelectedRowKeys = (
        showConditionsObject: DynamicObject = {}
    ) => {
        const usedConditions = {
            ...showConditionsObject,
            allSelected: false,
        };
        updateShowConditionsObject(usedConditions);

        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
            selectedPausedKeys: [],
        });
    };

    /**
     * Function for closing the filter bar section.
     * Resets the applied filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        const isLg = getIfIsLg();
        const rowHeight = isLg
            ? INVOICES_PAGE.rowHeight + 20
            : INVOICES_PAGE.rowHeight;
        setUsedRowHeight(rowHeight);
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    /**
     * Function called when click download specialised reports is closed.
     */
    const closeSpecialisedReportsPanel = () => {
        updateShowConditionsObject({
            specialisedReportsPanel: false
        });
    };

    /**
     * Function for getting the filter values to be used in FilterBar component.
     */
    const populateInvoiceFilterBarFilters = () => {
        let invoicesBarFilters = invoicesFilterBarFilters(undefined, undefined, isCalendarView);

        const invoiceCompanyFilterIdx = 3;
        let invoicesFilter = insertAt(
            invoicesBarFilters,
            invoiceCompanyFilterIdx,
            [
                {
                    filterName: 'Company',
                    filterStateName: 'CompanyIds',
                    filterElement: 'checkbox-group',
                    filterType: 'array',
                    filterOptions: !companies ? [] : companies.map(c => {
                        return {
                            label: c.Name,
                            value: c.CompanyId
                        }
                    })
                }
            ]
        );

        setFilterItemList(invoicesFilter);
    };

    useEffect(populateInvoiceFilterBarFilters, [
        usingCustomerWorkflow
    ]);

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <div className="spacer-5" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={invoicesState.loading}
                                    applyFilters={applyFilters}
                                    filters={filterItemList}
                                    filterValues={invoicesState.filters}
                                    colDivision={4}
                                    closeAllPopovers={
                                        invoiceDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={invoicesTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    customFieldsFilters={customFieldsFilterList}
                                    tableSortState={{
                                        sortBy: invoicesState.sortBy,
                                        sortAscending:
                                            invoicesState.sortAscending,
                                        invoiceState:
                                            invoicesState.invoiceState,
                                    }}
                                />
                            </Suspense>
                        </div>
                    )}
                    <SpecialisedReportsDrawer
                        visible={showConditions.specialisedReportsPanel}
                        onClose={closeSpecialisedReportsPanel}
                        downloadToExcelCallBack={downloadToExcelHandler}
                        reportOptions={{
                            options: SpecialisedReports,
                            loading: false
                        }}
                    /> 
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={invoicesState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'invoice-filter',
                                actionType: 'select-with-button',
                                selectValue: invoicesTableFilter,
                                selectDropdownRender:
                                    populateInvoiceFilterSelectDropdownRender,
                                onSelectChange: changeInvoicesTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>Refresh</span>
                                    </>
                                ),
                                buttonDisabled: invoicesState.loading,
                                onButtonClick: handleInvoiceFilterRefresh,
                            }
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={invoicesState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                usedRowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={invoicesState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(invoicesState.errorMessages)
                                    ? get(invoicesState, 'errorMessages.0')
                                    : 'No invoices found'
                            }
                            hasNextPage={invoicesState.pageData.hasNextPage}
                            itemComponent={InvoiceItemComponent}
                            itemHeight={usedRowHeight}
                            selectedId={selectedId}
                            usingCustomerWorkflow={usingCustomerWorkflow}
                            customerLabel={customerLabel}
                            extraData={{
                                addCommentDrawerVisible:
                                    showConditions.addCommentDrawer,
                            }}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <InvoiceItemDetailsDrawerComponent
                            visible={invoiceDetailsDrawer.visible}
                            activeTab={invoiceDetailsDrawer.activeTab}
                            closeDrawer={closeInvoiceDetailsDrawer}
                            refetchList={refetchListAndResetScroll}
                            region={region}
                            getInvoiceDataRequestAction={
                                (selectedId: string, isUsingCloudImportType: boolean) =>
                                    getOrganisationInvoiceDataRequestAction(selectedId, region, isUsingCloudImportType)
                            }
                        />
                    </Suspense>
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateDownloadToExcelModalTitle()}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={populateDownloadToExcelModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    withAccountingSystemHandler(OrganisationInvoicesContent)
);
