/**
 * Component for the Banking > Remittance Advices page. `/app/banking/remittanceadvices`
 */

import { Button, Col, Row, Select, Typography } from 'antd';
import {
    capitalize,
    difference,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
    toLower,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RemittanceAdviceItemComponent from '../../components/banking/RemittanceAdviceItemComponent';
import { withAccountingSystemHandler } from '../../components/common/AccountingSystemHandler';
import ActionBar from '../../components/common/ActionBar';
import AddCommentPanel from '../../components/common/AddCommentPanel';
import { customFieldIndicator } from '../../components/common/FilterBar';
import FontAwesome from '../../components/common/FontAwesome';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import VirtualizedList from '../../components/common/VirtualizedList';
import {
    CUSTOM_FIELD_TYPES,
    PAGE_NAMES_FOR_VIEW,
    REMITTANCE_ADVICES_PAGE,
} from '../../config/tableAndPageConstants';
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import {
    defaultPageSizeForReport,
    remittanceAdvicessSummaryPageQuery,
    remittanceAdvicesSummaryQueryName,
} from '../../constants/downloadToExcel';
import {
    getRemittanceAdvicesFilterBarFilters,
    remittanceAdvicesSortByOptions,
} from '../../constants/remittanceAdvicesSortAndFilter';
import { ApplicationState } from '../../store';
import {
    downloadToExcelAction,
    getCustomFieldsFilterListByTypes,
} from '../../store/common/actions';
import {
    CompanyCustomFieldConfigure,
    CompanyUserRole,
} from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { 
    getRemittanceAdvicesRequestAction, 
    remittanceAdviceAddCommentRequestAction,
    updateRemittanceAdvicesSortByAndStateAction,
    updateRemittanceAdvicesFiltersAction,
    updateRemittanceAdviceTableFilterAction,
    setRemittanceAdviceSelectedIdRequestAction,
    getRACustomFieldsForCompanyRequestAction
} from '../../store/remittanceAdvices/actions';
import { getCustomerFirstLetterValuesRequestAction } from '../../store/customers/actions';
import { initialState } from '../../store/remittanceAdvices/reducer';
import { getRemittanceAdviceSelectedId } from '../../store/remittanceAdvices/sagas';
import {
    GetRemittanceAdvicesRequestPayload,
    RemittanceAdvicesState,
} from '../../store/remittanceAdvices/types';
import { getCurrentUser } from '../../store/users/sagas';
import {
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getSortFieldsWithCustomFields,
    getUsedSortByOptionsIfCloud,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';

const RemittanceAdviceItemDetailsDrawerComponent = lazy(
    () => import('../../components/banking/RemittanceAdviceItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}
let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchRemittanceAdvicesRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = REMITTANCE_ADVICES_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.REMITTANCE_ADVICES_PAGE;
let initialTableFilter: undefined | string = undefined;
const RemittanceAdvicesManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
    handlePageViewSelection,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);
    const dispatch = useDispatch();

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const currentUser = useSelector(getCurrentUser);
    const selectedId = useSelector(getRemittanceAdviceSelectedId);

    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
        []
    );

    const [customFieldValuesList, setCustomFieldValuesList] = useState<DynamicObject[]>(
        []
    );

    const SupportCashAllocation = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.SupportCashAllocation')
    );

    const [remittanceAdviceDetailsDrawer, setRemittanceAdviceDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
        isMultipleCustomer: boolean;
    }>({
        visible: false,
        activeTab: undefined,
        isMultipleCustomer: false,
    });

    const remittanceAdvicesState: RemittanceAdvicesState = useSelector(
        (state: ApplicationState) => state.remittanceAdvices
    );

    const [remittanceAdvicesTableFilter, setRemittanceAdvicesTableFilter] = useState<
        string | undefined
    >(
        isEqual(remittanceAdvicesState.filters, initialState.filters)
            ? initialState.tableFilter
            : remittanceAdvicesState.tableFilter
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const selectedUserCompanyLoading: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.loading
    );

    const IsSearchCustomFieldsByCheckBox: boolean = get(
        selectedUserCompany,
        'Company.IsSearchCustomFieldsByCheckbox'
    );

    const [tableFilters, setTableFilters] = useState<any>(
        remittanceAdvicesState.filters
    );

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    /**
     * Function that checks if there are filters applied and saved in redux.
     * Returns a boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(remittanceAdvicesState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        addCommentDrawer: boolean;
        allSelected: boolean;
        downloadToExcel: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        addCommentDrawer: false,
        allSelected: false,
        downloadToExcel: false,
    });

    const [tableSortState, setTableSortState] = useState<{
        SortField: string;
        sortAscending: boolean;
    }>({
        SortField: remittanceAdvicesState.SortField,
        sortAscending: remittanceAdvicesState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(remittanceAdvicesState, 'pageData.currentPage', 0)
    );

    const [customerValuesOption, setCustomerValuesList] = useState<DynamicObject[]>(
        []
    );

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetRemittanceAdvicesRequestPayload = {
            filters: remittanceAdvicesState.filters,
            SortField: remittanceAdvicesState.SortField,
            sortAscending: remittanceAdvicesState.sortAscending,
            pageSize,
            currentPage: currentPage,
            isUsingCloudImportType
        };

        return payload;
    };

    /**
     * Function to get used sort options
     */
    const getRemittanceAdvicesSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            remittanceAdvicesSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!selectedUserCompany) return;
        const usedSortByOptions = getRemittanceAdvicesSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        forEach(
            customFieldsFilterList,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                usedSortValues.push(
                    `${customFieldIndicator}${Type}--${FieldName}`
                );
            }
        );
        if (!includes(usedSortValues, remittanceAdvicesState.SortField)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    SortField: initialState.SortField,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateRemittanceAdvicesSortByAndStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        selectedUserCompany,
        remittanceAdvicesState.SortField,
    ]);

    /**
     * Function that calls an action which triggers a saga for fetching the Payments data.
     * @param currentPage - current page to fetch data from
     * @param pageSize - number of items in page
     */
    const fetchRemittanceAdvices = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;

        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getRemittanceAdvicesRequestAction(payload));
    };

    /**
     * Function called in preparation for fetching the remittance advvice data.
     * Manages the page to be called.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserCompany) ||
            remittanceAdvicesState.loading
        )
            return;

        if (!remittanceAdvicesState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchRemittanceAdvices(nextPage);
    };

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomFieldsFilterListByTypes(
                    [CUSTOM_FIELD_TYPES.CUSTOMER], // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    /**
     * Function that fetches the payment custom field value list for checkbox
     */
    const getCustomFieldsValueList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let customFieldsFilterOptions: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getRACustomFieldsForCompanyRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if(response){
                            if (response.IsSuccess) {
                                customFieldsFilterOptions = await getCustomFieldsOptionsByKey(customFieldsFilterOptions,response.CustomFieldValues);
                                setCustomFieldValuesList(customFieldsFilterOptions)
                            }
                        }
                        
                    }
                    
                )
            ); 
        }
          
    };

    /**
     * Function for populating the custom field options by key used (affected when UsingCustomerWorkflow is changed).
     * @param workflowKey
     * @param actionFilterOptions
     */
    const getCustomFieldsOptionsByKey = async (
        customFieldsFilterOptions: DynamicObject[],
        customFieldValuesList: DynamicObject[]
    ) => {
        forEach(
            customFieldsFilterList,
            (customFieldsOption,index) => {                
                const customFieldName = get(customFieldsOption, 'FieldName');
                const customFieldLists = customFieldValuesList.filter(x => x.Name == customFieldName && x.Value != null);

                const childCustomFieldValues = map(customFieldLists, (cfl,index) => ({
                    label: cfl.Value,
                    value: cfl.Value,
                }));

                customFieldsFilterOptions.push({
                    Number:index,
                    Type: get(customFieldsOption, 'Type'),
                    FieldName: get(customFieldsOption, 'FieldName'),
                    children: childCustomFieldValues,
                    filterLoading: remittanceAdvicesState.loading
                });
            }
        );

        return customFieldsFilterOptions;
    };

    useEffect(getCustomFieldsFilterList, [selectedUserCompany]);
    useEffect(getCustomFieldsValueList, [customFieldsFilterList]);

    /**
     * Listener function for calling the fetch payments function.
     * Can be triggered upon changes on selected user company, filter values, sort values, and payment state filter update.
     */
    const callfetchRemittanceAdvices = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }
            if (!selectedUserCompanyLoading) fetchRemittanceAdvices(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, SortField, sortAscending } = remittanceAdvicesState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            SortField !== tableSortState.SortField ||
            sortAscending !== tableSortState.sortAscending
        ) {
            updateTableSortStateObject({
                SortField,
                sortAscending,
            });
        }
    };

    useEffect(callfetchRemittanceAdvices, [
        remittanceAdvicesState.SortField,
        remittanceAdvicesState.sortAscending,
        remittanceAdvicesState.filters,
        selectedUserCompany,
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = remittanceAdvicesState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchRemittanceAdvicesRequired) {
                fetchRemittanceAdvices(0);
                fetchRemittanceAdvicesRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [remittanceAdvicesState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Listener function responsible for showing the state of checkboxes (checked/unchecked) upon data update.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(remittanceAdvicesState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [remittanceAdvicesState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function called when payment table filter (upper-left dropdown next to refresh button) is updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeRemittanceAdvicesTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== remittanceAdvicesState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setRemittanceAdvicesTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === remittanceAdvicesState.tableFilter) {
            handleRemittanceAdviceFilterRefresh();
        } else {
            dispatch(updateRemittanceAdviceTableFilterAction(filter));
        }
    };

    /**
     * Function called when the refresh button is clicked.
     */
    const handleRemittanceAdviceFilterRefresh = () => {
        fetchRemittanceAdvicesRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();


    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchRemittanceAdvices(0);
    };

    /**
     * Function for getting the selected payments values.
     */
    const getSelectedRemittanceAdvicesValues = () => {
        let allExcept = false;
        let keysToUse = [...tableRowSelection.selectedRowKeys];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = [...tableRowSelection.unselectedRowKeys];
        }
        const filterObject = {
            ...tableFilters,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    /**
     * Function for populating the dropdown render of the table filter (upper-left).
     * @param menu - menu items component
     */
    const populateRemittanceAdviceFilterSelectDropdownRender = (menu: any) => (
        <div>
            {menu}
        </div>
    );

    /**
     * Function for populating the `Select/Deselect all` button label.
     */
    const populateSelectDeselectAllLabel = () => {
        const allDataLoaded = remittanceAdvicesState.pageData.hasNextPage === false;

        let selectDeselectLabel = '';
        if (
            !isEmpty(remittanceAdvicesState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                    remittanceAdvicesState.data.length ===
                        tableRowSelection.selectedRowKeys.length))
        ) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return `${selectDeselectLabel} all`;
    };

    /**
     * Function triggered when the select/deselect all button is clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length <
                remittanceAdvicesState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(remittanceAdvicesState.data, selectedKeysName);

            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
            });
        }
    };

    /**
     * Function called when the sort order button link is clicked (inside the ellipsis popover).
     * @param sortAscending - boolean indicator if sort order is ascending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateRemittanceAdvicesSortByAndStateAction({
                ...tableSortState,
                sortAscending,
            })
        );
    };

    const downloadDisabled =
        remittanceAdvicesState.loading || isEmpty(remittanceAdvicesState.data);

    /**
     * Function for populating the upper section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                Filter
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            remittanceAdvicesState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                Change sort order
            </Button>
            <br />
            <Button
                type="link"
                onClick={downloadToExcelHandler}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                Download to Excel
            </Button>
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = () => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const { filters, SortField, sortAscending } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true, 'remittanceAdvices');

        const sortObject = getSortFieldsWithCustomFields(SortField);

        const variables = {
            ...cleanFilters,
            ...sortObject,
            Ascending: sortAscending,
        };

        const payload = {
            Query: remittanceAdvicessSummaryPageQuery,
            OperationName: remittanceAdvicesSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.REMITTANCE_ADVICES_PAGE,
        };

        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function for populating the lower section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => (
        <div className="pop-action-content">
            <div className="mb-10">
                <span>Sort by</span>
            </div>
            <div>
                <Select
                    onChange={(sortBySelected: string) =>
                        updateTableSortStateObject({
                            SortField: sortBySelected,
                        })
                    }
                    value={tableSortState.SortField}
                >
                    {map(
                        getRemittanceAdvicesSortByOptions(),
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: string;
                        }) => {
                            const labelUsed = label
                                .replace('Customer', capitalize(customerLabel))
                                .replace('customer', toLower(customerLabel));
                            return (
                                <Option key={value} value={value}>
                                    {labelUsed}
                                </Option>
                            );
                        }
                    )}
                    {map(
                        customFieldsFilterList,
                        ({
                            Type,
                            Number: CFNumber,
                            FieldName,
                        }: CompanyCustomFieldConfigure) => (
                            <Option
                                key={Type + CFNumber + FieldName}
                                value={`${customFieldIndicator}${Type}--${FieldName}`}
                            >
                                {FieldName}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="ta-right">
                <Button
                    type="primary"
                    disabled={
                        remittanceAdvicesState.loading ||
                        tableSortState.SortField === remittanceAdvicesState.SortField
                    }
                    onClick={applySortedByAndState}
                >
                    Apply
                </Button>
            </div>
        </div>
    );

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (remittanceAdvicesState.tableFilter !== initialTableFilter) {
            setRemittanceAdvicesTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeRemittanceAdvicesTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called when applying the filters.
     * Set the filters in state and redux.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetRemittanceAdvicesRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    remittanceAdvicesTableFilter,
                    fromFilterBar,
                    () => {
                        changeRemittanceAdvicesTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortState(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateRemittanceAdvicesFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys();
    };

    /**
     * Function called when `Apply` button is clicked inside the ellipsis popover,
     * also hides the ellipsis popover after `Save` is clicked.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
       
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'SortField')
            ? savedValues
            : tableSortState;
        await dispatch(updateRemittanceAdvicesSortByAndStateAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function called to close the payment details drawer.
     * Clears the data saved during drawer close.
     */
    const closeremittanceAdviceDetailsDrawer = () => {
        setRemittanceAdviceDetailsDrawer({
            visible: false,
            activeTab: undefined,
            isMultipleCustomer: false
        });
    };

    /**
     * Function to update the `tableSortState` state.
     * @param tableSortStateObject - must conform to `tableSortState`
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when clicking on checkbox.
     * @param record - record for the row where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     * @param activeTab - kind of panel to show based on the row clicked
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {

        const { MultipleCustomer } = record;

        dispatch(
            setRemittanceAdviceSelectedIdRequestAction(get(record, 'Id'), () => {
                setRemittanceAdviceDetailsDrawer({
                    visible: true,
                    activeTab,
                    isMultipleCustomer: MultipleCustomer
                });
            })
        );
    };

    /**
     * Function for populating the table loading text.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } remitance advice`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected (left side checkbox is ticked).
     * @param record - data for the selected row
     * @param selected - boolean indicator if the row is selected/unselected
     * @param selectedRows - selected row keys
     * @param nativeEvent - event fired when the row is selected
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        const customerIds: string[] = [];
        forEach(remittanceAdvicesState.data, (paymentData) => {
            if (includes(selectedRowKeys, paymentData.Id)) {
                const customerId = get(paymentData, 'Customer.Id');
                if (!includes(customerIds, customerId))
                    customerIds.push(customerId);
            }
        });

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };

    /**
     * Function to control the visibility of popovers.
     * @param name - name of popover to change the visibility
     * @param condition - boolean indicator to apply the condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (name === 'addCommentDrawer') {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for resetting the table scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all the selected/unselected row keys.
     */
    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        updateShowConditionsObject({
            allSelected: false,
        });
    };

    /**
     * Function for closing the filter bar section.
     * Resets the applied filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    // /**
    //  * Function called to refresh the payment list after an update.
    //  */
    // const refetchRemittanceAdvicesListAfterEditing = () => {
    //     isRefetching = true;
    //     const pageSizeUsed =
    //         remittanceAdvicesState.data.length < tablePageSize
    //             ? remittanceAdvicesState.data.length
    //             : tablePageSize;
    //     fetchRemittanceAdvices(0, pageSizeUsed);
    // };

    /**
     * Function called to close the add comment panel
     * @param refreshList
     */
    const closeAddCommentPanel = (refreshList?: boolean) => {
        updateShowConditionsObject({
            addCommentDrawer: false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
        }
    };

    /**
     * Function for populating the payload needed for payment add comment.
     */
    const getAddCommentFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
        getSelectedRemittanceAdvicesValues();

        return {
            filter: filterObject,
            remittanceAdviceIds: keysToUse,
            excludeRemittanceAdvices: allExcept,
        };
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
    * Function that fetches the customer first letter values
    */
    const getCustomerFirstLatterValuesOptions = async (
        options: DynamicObject[],
        valuesList: DynamicObject[]
    ) => {
        forEach(
            valuesList,
            (value, index) => {
                options.push({
                    label: value,
                    value: value
                });
            }
        );
        return options;
    };
    const getCustomerFirstLetterValuesList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let options: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomerFirstLetterValuesRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            options = await getCustomerFirstLatterValuesOptions(options, response.Values);
                            setCustomerValuesList(options)
                        }
                    }
                )
            );
        }
    };
    useEffect(getCustomerFirstLetterValuesList, [selectedUserCompany]);

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>Remittance Advices</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={remittanceAdvicesState.loading}
                                    applyFilters={applyFilters}
                                    filters={getRemittanceAdvicesFilterBarFilters(
                                        SupportCashAllocation,
                                        customerValuesOption,
                                        capitalize(customerLabel) + ' A-Z'
                                    )}
                                    filterValues={remittanceAdvicesState.filters}
                                    colDivision={4}
                                    closeAllPopovers={
                                        remittanceAdviceDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={remittanceAdvicesTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    customFieldsFilters={IsSearchCustomFieldsByCheckBox ? customFieldValuesList : customFieldsFilterList}
                                    tableSortState={{
                                        SortField: remittanceAdvicesState.SortField,
                                        sortAscending:
                                            remittanceAdvicesState.sortAscending,
                                    }}
                                />
                            </Suspense>
                        </div>
                    )}
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={remittanceAdvicesState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'remittance-advice-filter',
                                actionType: 'select-with-button',
                                selectValue: remittanceAdvicesTableFilter,
                                selectDropdownRender:
                                populateRemittanceAdviceFilterSelectDropdownRender,
                                onSelectChange: changeRemittanceAdvicesTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>Refresh</span>
                                    </>
                                ),
                                buttonDisabled: remittanceAdvicesState.loading,
                                onButtonClick: handleRemittanceAdviceFilterRefresh,
                            },
                            {
                                actionKey: 'remittance-advice-select-all',
                                actionType: 'protected-button',
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span>
                                            {populateSelectDeselectAllLabel()}
                                        </span>
                                    </>
                                ),
                            },
                            {
                                actionKey: 'remittance-advice-add-comment',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.addCommentDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'addCommentDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: 'Add a comment',
                                popoverContent: (
                                    <AddCommentPanel
                                        visible={
                                            showConditions.addCommentDrawer
                                        }
                                        closePanel={closeAddCommentPanel}
                                        filterPayload={getAddCommentFilterPayload()}
                                        dispatchAction={
                                            remittanceAdviceAddCommentRequestAction
                                        }
                                    />
                                ),
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'comment']}
                                        />
                                        <span>Add comment</span>
                                    </>
                                ),
                                drawerWidth: 500,
                            },
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={remittanceAdvicesState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                REMITTANCE_ADVICES_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={remittanceAdvicesState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(remittanceAdvicesState.errorMessages)
                                    ? get(remittanceAdvicesState, 'errorMessages.0')
                                    : 'No remittance advices found'
                            }
                            hasNextPage={remittanceAdvicesState.pageData.hasNextPage}
                            itemComponent={RemittanceAdviceItemComponent}
                            itemHeight={REMITTANCE_ADVICES_PAGE.rowHeight}
                            selectedId={selectedId}
                            extraData={{
                                addCommentDrawerVisible:
                                    showConditions.addCommentDrawer,
                            }}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <RemittanceAdviceItemDetailsDrawerComponent
                            visible={remittanceAdviceDetailsDrawer.visible}
                            activeTab={remittanceAdviceDetailsDrawer.activeTab}
                            closeDrawer={closeremittanceAdviceDetailsDrawer}
                            refetchList={refetchListAndResetScroll}
                            isMultipleCustomer={remittanceAdviceDetailsDrawer.isMultipleCustomer}
                        />
                    </Suspense>
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateDownloadToExcelModalTitle()}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={populateDownloadToExcelModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    withAccountingSystemHandler(RemittanceAdvicesManagementPage)
);
