/**
 * Component drawer for specialised reports panel.
 */

import { Drawer } from 'antd';
import React, { useRef } from 'react';
import SpecialisedReportsPanel from './SpecialisedReportsPanel';

interface IProps {
    visible: boolean;
    onClose: () => void;
    downloadToExcelCallBack: Function;
    reportOptions: {};
}

const SpecialisedReportsDrawer: React.FC<IProps> = ({
    visible,
    onClose,
    downloadToExcelCallBack,
    reportOptions,
}: IProps) => {
    const drawerRef: any = useRef(null);

    return (
        <Drawer
            visible={visible}
            width={420}
            onClose={onClose}
            title="Specialised Reports"
            maskClosable={false}
            closable={false}
        >
            <div id="drawer-container" ref={drawerRef}>
                <SpecialisedReportsPanel
                    containerRef={drawerRef}
                    visible={visible}
                    closePanel={onClose}
                    downloadToExcelCallBack={downloadToExcelCallBack}
                    reportOptions={reportOptions}
                />
            </div>
        </Drawer>
    );
};

export default SpecialisedReportsDrawer;