/**
 * File for the `Open invoices` widget.
 */

import { Col, Row, Spin } from 'antd';
import { clone, find, forEach, get, includes } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { ATBStateOptions } from '../../constants/customersSortAndFilters';
import { populatePayloadForOrganisationWidgets } from '../../constants/dashboards';
import {
    dateFormatYYYYMMDDDash,
    dateFormatYYYYMMDDTHHmmssDash,
} from '../../constants/dateFormats';
import { invoicesStateFilterOptions } from '../../constants/invoicesSortAndFilters';
import { getDashboardOpenInvoicesRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    formatDateToDateObjectUTC,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => React.ReactNode;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly widgetTitle?: string;
    readonly functionRefObj?: any;
}

const OpenInvoicesWidget: React.FC<IProps> = ({
    widgetDetails,
    formatNumber,
    isOrgView,
    organisationCurrenciesAll,
    widgetTitle,
    functionRefObj
}: /*customFieldsFilterList,*/
IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();
    const [dataState, setDataState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        invoiceCount: number;
    }>({
        lastWidgetDetails: {},
        loading: false,
        invoiceCount: 0,
    });

    let currencyValueUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        const region = widgetDetails.Region;
        const currencySelectedParsed = region
            ? find(organisationCurrenciesAll, ['Name', region])
            : undefined;

        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `dataState` state.
     * @param dataStateObject
     */
    const updateDataStateObject = (dataStateObject: {}) => {
        setDataState({
            ...dataState,
            ...dataStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        let customerFieldValue: string = '';
        const customFieldFilters: DynamicObject = {};
        const customFieldsUsed = [
            CUSTOM_FIELD_TYPES.CUSTOMER,
            CUSTOM_FIELD_TYPES.INVOICE,
        ];

        let amountFilters: any = {
            AmountType: '',
            AmountValue: 0,
            AmountOperator: '',
        };
        let dueDateMax: string = '';
        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );

                if (includes(customFieldsUsed, customFieldType)) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }
            
            else if (includes(wdKey, 'Customer')) {
                customerFieldValue = wdValue;
            }

            if (wdKey === 'invoiceOpenState') {
                if (wdValue === ATBStateOptions[1].value) {
                    amountFilters = {
                        AmountOperator: '>',
                        AmountType: 'Total overdue',
                        AmountValue: 0,
                    };
                    const dateMaxWithTime = moment()
                        .subtract(1, 'day')
                        .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
                    dueDateMax = formatDateToDateObjectUTC(
                        dateMaxWithTime,
                        dateFormatYYYYMMDDTHHmmssDash
                    ).format(dateFormatYYYYMMDDTHHmmssDash);
                }
            }
        });

        let payload: DynamicObject = {
            filters: {
                DueDateMin: '',
                DueDateMax: dueDateMax,
                ...amountFilters,
                ...customFieldFilters,
            },
            invoiceState: get(invoicesStateFilterOptions, '0.value'),
            Customer: customerFieldValue, 
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationWidgets({
                payload,
                widgetDetails,
            });
        }

        dispatch(
            getDashboardOpenInvoicesRequestAction(
                payload,
                isOrgView,
                (invoiceCount: number) => {
                    if (unmountedRef.current) return;

                    updateDataStateObject({
                        loading: false,
                        invoiceCount: invoiceCount || 0,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            dataState.lastWidgetDetails,
            widgetDetails
        );

        if (bypassAPIFetching) return;

        updateDataStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    const { loading: stateLoading, invoiceCount: stateOpenSum } = dataState;

    return (
        <div className="box-number-widget">
            <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                <Row className="box-number-widget-row">
                    <Col span={24} className="box-number-widget-title">
                        {widgetTitle || widgetDetails.title}
                    </Col>
                    <Col span={24} className="box-number-widget-count">
                        {stateOpenSum &&
                            formatNumber(stateOpenSum, 0, localeUsed)}
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default withNumberFormatHandler(OpenInvoicesWidget);
