/**
 * File for the `Customer assistance tickets` widget.
 */

import { Col, Row, Spin } from 'antd';
import { clone, find, get, isUndefined, map, forEach, includes } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_SUPPORT_TICKETS } from '../../config/tableAndPageConstants';
import { populatePayloadForOrganisationRegionalWidgets } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getDashboardOpenSupportTicketsRequestAction } from '../../store/dashboards/actions';
import { dashboardBypassAPIFetch, getDateFilterValues } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    ticketFieldIndicator
} from '../common/FilterBar';
import {
    dateSelectOptions
} from '../../constants/invoicesSortAndFilters';

interface IProps {
    widgetDetails: DynamicObject;
    /*customFieldsFilterList: DynamicObject[];*/
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => React.ReactNode;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly widgetTitle?: string;
    readonly functionRefObj?: any;
}

const OpenSupportTicketsWidget: React.FC<IProps> = ({
    widgetDetails,
    formatNumber,
    isOrgView,
    organisationCurrenciesAll,
    widgetTitle,
    functionRefObj
}: /*customFieldsFilterList,*/
IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );
    const [dataState, setDataState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        openSum: number;
    }>({
        lastWidgetDetails: {},
        loading: false,
        openSum: 0,
    });

    let currencyValueUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        const region = widgetDetails.Region;
        const currencySelectedParsed = region
            ? find(organisationCurrenciesAll, ['Name', region])
            : undefined;

        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `dataState` state.
     * @param dataStateObject
     */
    const updateDataStateObject = (dataStateObject: {}) => {
        setDataState({
            ...dataState,
            ...dataStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const customFieldFilters: DynamicObject = {};
        let Customer: string = '';
        let CustomerCountry: string = '';
        let CustomerState: string = '';
        let TicketOptionReasons: any = [];
        let createdDateFilters: any = {
            value: undefined,
            From: { Last: undefined },
            To: { Last: undefined },
            Last: undefined,
            Next: undefined
        }
        let closedDateFilters: any = {
            value: undefined,
            From: { Last: undefined },
            To: { Last: undefined },
            Last: undefined,
            Next: undefined
        }
        let CreatedDateMin: any = undefined;
        let CreatedDateMax: any = undefined;
        let ClosedDateMin: any = undefined;
        let ClosedDateMax: any = undefined;

        createdDateFilters.value = get(widgetDetails, "TicketField---CreatedDate--DateType");
        closedDateFilters.value = get(widgetDetails, "TicketField---ClosedDate--DateType");

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {

            if (includes(wdKey, customFieldIndicator)) {
                customFieldFilters[wdKey + appliedFilterIndicator] = wdValue;
            }

            if (includes(wdKey, ticketFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "CustomerCountry")) {
                    CustomerCountry = wdValue;
                }
                else if (includes(wdKey, "CustomerState")) {
                    CustomerState = wdValue;
                }
                else if (includes(wdKey, "Customer")) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "ReasonType")) {
                    TicketOptionReasons = map(
                        get(widgetDetails, wdKey),
                        (options: string) => options
                    );
                }
                else if (includes(wdKey, "CreatedDate")) {
                    if (createdDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            createdDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            createdDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (createdDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last--From")) {
                            createdDateFilters.From.Last = wdValue;
                        }
                        else if (includes(wdKey, "Last--To")) {
                            createdDateFilters.To.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(createdDateFilters);
                    CreatedDateMin = minDate;
                    CreatedDateMax = maxDate;
                }
                else if (includes(wdKey, "ClosedDate")) {
                    if (closedDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            closedDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            closedDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (closedDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last--From")) {
                            closedDateFilters.From.Last = wdValue;
                        }
                        else if (includes(wdKey, "Last--To")) {
                            closedDateFilters.To.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(closedDateFilters);
                    ClosedDateMin = minDate;
                    ClosedDateMax = maxDate;
                }
            }
        });
        TicketOptionReasons = map(
            get(widgetDetails, 'ticketTypes'),
            (ticketType: string) => ticketType
        );

        let payload: DynamicObject = {
            filters: {
                Status: OPEN_SUPPORT_TICKETS.STATUS,
                DisplayName: Customer,
                CustomerCode: Customer,
                CreatedDateMin,
                CreatedDateMax,
                ClosedDateMin,
                ClosedDateMax,
                CustomerCountry,
                CustomerState,
                TicketOptionReasons,
                ...customFieldFilters
            },
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
            });
        }

        dispatch(
            getDashboardOpenSupportTicketsRequestAction(
                payload,
                isOrgView,
                (openSum: number) => {
                    if (unmountedRef.current) return;

                    updateDataStateObject({
                        loading: false,
                        openSum: openSum || 0,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            dataState.lastWidgetDetails,
            widgetDetails
        );

        if (bypassAPIFetching) return;

        updateDataStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    const { loading: stateLoading, openSum: stateOpenSum } = dataState;

    return (
        <div className="box-number-widget">
            <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                <Row className="box-number-widget-row">
                    <Col span={24} className="box-number-widget-title">
                        {widgetTitle || widgetDetails.title}
                    </Col>
                    <Col span={24} className="box-number-widget-count">
                        {stateOpenSum &&
                            formatNumber(stateOpenSum, 0, localeUsed)}
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default withNumberFormatHandler(OpenSupportTicketsWidget);
