import { Reducer } from 'redux';
import update from 'immutability-helper';
import { NotificationsState, NotificationsActionTypes } from './types';
import { get } from 'lodash';

// Type-safe initialState!
export const initialState: NotificationsState = {
    categorizedNotifications: {
        loading: false,
        errorMessages: [],
        data: [],
    },
    notificationsByCategory: {
        loading: false,
        errorMessages: [],
        data: [],
    },
    refetchNewNotifications: false, // initially try to fire fetching of new notifications on page load
    unreadNotificationCategories: [],
    announcementsFetching: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<NotificationsState> = (state = initialState, action) => {
    switch (action.type) {
        case NotificationsActionTypes.GET_CATEGORIZED_NOTIFICATIONS_REQUEST: {
            let newCategorizedNotificationsState = update(
                state.categorizedNotifications,
                {
                    loading: { $set: !action.payload.noState },
                }
            );

            return {
                ...state,
                categorizedNotifications: newCategorizedNotificationsState,
            };
        }
        case NotificationsActionTypes.GET_CATEGORIZED_NOTIFICATIONS_SUCCESS: {
            let newCategorizedNotificationsState = update(
                state.categorizedNotifications,
                {
                    data: { $set: action.payload.data },
                    loading: { $set: false },
                    errorMessages: {
                        $set: initialState.categorizedNotifications
                            .errorMessages,
                    },
                }
            );

            return {
                ...state,
                categorizedNotifications: newCategorizedNotificationsState,
                refetchNewNotifications: get(
                    action.payload,
                    'refetchNewNotif',
                    false
                ),
            };
        }
        case NotificationsActionTypes.GET_CATEGORIZED_NOTIFICATIONS_ERROR: {
            let newCategorizedNotificationsState = update(
                state.categorizedNotifications,
                {
                    data: { $set: initialState.categorizedNotifications.data },
                    loading: { $set: false },
                    errorMessages: { $set: action.payload },
                }
            );

            return {
                ...state,
                categorizedNotifications: newCategorizedNotificationsState,
            };
        }

        case NotificationsActionTypes.GET_NOTIFICATIONS_BY_CATEGORY_REQUEST: {
            let newNotificationsByCategoryState = update(
                state.notificationsByCategory,
                {
                    loading: { $set: true },
                }
            );

            return {
                ...state,
                notificationsByCategory: newNotificationsByCategoryState,
            };
        }
        case NotificationsActionTypes.GET_NOTIFICATIONS_BY_CATEGORY_SUCCESS: {
            let newNotificationsByCategoryState = update(
                state.notificationsByCategory,
                {
                    data: { $set: action.payload.data },
                    loading: { $set: false },
                    errorMessages: {
                        $set: initialState.notificationsByCategory
                            .errorMessages,
                    },
                }
            );

            return {
                ...state,
                notificationsByCategory: newNotificationsByCategoryState,
            };
        }
        case NotificationsActionTypes.GET_NOTIFICATIONS_BY_CATEGORY_ERROR: {
            let newNotificationsByCategoryState = update(
                state.notificationsByCategory,
                {
                    data: { $set: initialState.notificationsByCategory.data },
                    loading: { $set: false },
                    errorMessages: { $set: action.payload },
                }
            );

            return {
                ...state,
                notificationsByCategory: newNotificationsByCategoryState,
            };
        }

        case NotificationsActionTypes.CLEAR_CATEGORIZED_NOTIFICATIONS_STATE_DATA: {
            return {
                ...initialState,
                categorizedNotifications: initialState.categorizedNotifications,
            };
        }

        case NotificationsActionTypes.CLEAR_NOTIFICATIONS_BY_CATEGORY_STATE_DATA: {
            return {
                ...initialState,
                notificationsByCategory: initialState.notificationsByCategory,
            };
        }

        case NotificationsActionTypes.REFETCH_NEW_NOTIFICATIONS_TRIGGER: {
            const newState = update(state, {
                $merge: {
                    refetchNewNotifications: action.payload,
                },
            });

            return newState;
        }

        case NotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_FOR_USER_SUCCESS: {
            const newState = update(state, {
                $merge: {
                    unreadNotificationCategories: action.payload,
                },
            });

            return newState;
        }

        case NotificationsActionTypes.SET_LOGIN_PRODUCT_ANNOUNCEMENT_LOADING: {
            return {
                ...state,
                announcementsFetching: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as notificationsReducer };
