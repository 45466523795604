import React, { Fragment, useEffect, forwardRef, Ref } from 'react';
import ColorPicker from '../../../../components/common/ColorPicker';
import { Col } from 'antd';
import { debounce, isEqual, get, map } from 'lodash';

declare global {
    interface Window {
        less: any;
    }
}

interface IProps {
    ref: any;
    disabled: boolean;
    initialColors: object;
    onColorChange: (varname: string, color: any) => void;
    colorPickers: { name: string; propertyName: string; styles: {} }[];
    changeLessVariables: (initialColors: object) => void;
    showModalUpdateTheme: () => void;
}
let skipColorChange: boolean = false;
let appliedColors: any = {};
let loadInitialDone: boolean = false;
const ThemeSelection: React.FC<IProps> = forwardRef(
    (
        {
            disabled,
            initialColors,
            onColorChange,
            colorPickers,
            changeLessVariables,
            showModalUpdateTheme,
        }: IProps,
        ref: Ref<any>
    ) => {
        React.useImperativeHandle(ref, () => ({
            skipColorVariableChange: () => {
                skipColorChange = false;
            },
        }));

        const getColorPicker = (varName: string) => (
            <Fragment key={varName}>
                <ColorPicker
                    type="sketch"
                    small
                    color={get(initialColors, varName)}
                    position="left"
                    presetColors={[
                        '#F5222D',
                        '#FA541C',
                        '#FA8C16',
                        '#FAAD14',
                        '#FADB14',
                        '#A0D911',
                        '#52C41A',
                        '#13C2C2',
                        '#1890FF',
                        '#2F54EB',
                        '#722ED1',
                        '#EB2F96',
                    ]}
                    onChangeComplete={(color: any) => {
                        handleColorChange(varName, color);
                    }}
                    disabled={disabled}
                />
            </Fragment>
        );

        const changeLessColors = () => {
            if (skipColorChange || !loadInitialDone) return;

            if (!isEqual(appliedColors, initialColors)) {
                appliedColors = initialColors;

                changeLessVariables(initialColors);
            }

            return () => {
                appliedColors = initialColors;
            };
        };

        useEffect(changeLessColors, [initialColors]);

        const initialLoadingFunction = () => {
            loadInitialDone = true;
            appliedColors = initialColors;

            return () => {
                skipColorChange = true;
                loadInitialDone = false;
            };
        };

        useEffect(initialLoadingFunction, []);

        const colorChangeDebounced = debounce((varname, color) => {
            if (onColorChange) {
                onColorChange(varname, color);
            }
        }, 500);

        const handleColorChange = (varname: string, color: any) => {
            if (appliedColors[varname] !== color) {
                showModalUpdateTheme();
                skipColorChange = false;
                if (!skipColorChange) {
                    colorChangeDebounced(varname, color);
                }
            }
        };

        return (
            <div>
                {map(
                    colorPickers,
                    ({
                        name,
                        propertyName,
                        styles,
                    }: {
                        name: string;
                        propertyName: string;
                        styles: {};
                    }) => (
                        <div className="center-flex" key={propertyName}>
                            <Col
                                xxl={14}
                                xl={16}
                                lg={10}
                                style={get(styles, 'name')}
                            >
                                {name}
                            </Col>
                            <Col
                                xxl={10}
                                xl={8}
                                lg={14}
                                className="color-picker-container"
                            >
                                {getColorPicker(propertyName)}
                            </Col>
                        </div>
                    )
                )}
            </div>
        );
    }
);

export default ThemeSelection;
