/**
 * Component responsible for populating the Edit Task panel.
 */

import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Skeleton,
} from 'antd';
import { forEach, get, isEmpty, map, omitBy, startsWith, times } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    dateFormatDDMMYYYYSlash,
    dateFormatYYYYMMDDDash,
} from '../../../constants/dateFormats';
import { ApplicationState } from '../../../store';
import {
    getTaskEditPropertiesRequestAction,
    getWorkflowStepOptionsRequestAction,
} from '../../../store/tasks/actions';
import { getPopoverContainer } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import SelectReadonly from '../../FormComponents/SelectReadonly';

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;
let unmounted: boolean = false;
interface IProps {
    onSaveClick: (values: DynamicObject) => void;
    onCancelClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    containerRef?: any;
    form: any;
    visible: boolean;
    getSelectedTasksValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
        sortBy: string,
        sortAscending: boolean,
        recordLimit?: number,
    };
    getWorkflowFilterOptions: () => any[];
}
const TaskEditDrawerContent: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();

    const isPaymentPlanEnabled: boolean = useSelector(
        (state: ApplicationState) =>
            get(
                state.companies.selectedUserCompany,
                'Company.CompanyPaymentPlan.IsEnabled'
            )
    );

    const [taskEditProperties, setTaskEditProperties] = useState<{
        loading: boolean;
        workflowStepLoading: boolean;
        data: DynamicObject;
    }>({
        loading: false,
        workflowStepLoading: false,
        data: {},
    });

    /**
     * Function for updating the `taskEditProperties` state.
     * @param taskEditPropertiesObject - must conform to `taskEditProperties` state
     */
    const updateTaskEditProperties = (taskEditPropertiesObject: {}) => {
        setTaskEditProperties({
            ...taskEditProperties,
            ...taskEditPropertiesObject,
        });
    };
    
    /**
     * Function that populates the workflow options.
     */
    const populateWorkflowOptions = () => {
        const workflowFilterOptions: DynamicObject[] =
            props.getWorkflowFilterOptions();
        return map(
            workflowFilterOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );
    };

    /**
     * Function that populates the workflow step options.
     */
    const populateWorkflowStepOptions = () => {
        return map(
            get(taskEditProperties.data, 'WorkflowSteps'),
            (step: string) => (
                <Option key={step} value={step}>
                    {step}
                </Option>
            )
        );
    };

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmounted = false;

        //will unmount
        return () => {
            unmounted = true;
        };
    };

    useEffect(setInitialLoad, []);

    const {
        getFieldDecorator,
        getFieldsValue,
        getFieldValue,
        setFieldsValue,
        validateFields,
        resetFields,
    } = props.form;

    /**
     * Function that initializes the values (if drawer is opened, and resets if drawer is closed).
     * Fetches the task edit properties and displays to UI accordingly.
     */
    const initializeValues = () => {
        if (props.visible === false) {
            resetFields();
        } else {
            const { allExcept, keysToUse, filterObject, recordLimit, sortAscending, sortBy } = props.getSelectedTasksValues();
            updateTaskEditProperties({
                loading: true,
            });
            dispatch(
                getTaskEditPropertiesRequestAction({
                    filter: filterObject,
                    taskIds: keysToUse,
                    excludeTasks: allExcept,
                    sortBy, 
                    sortAscending, 
                    recordLimit,
                    isPaymentPlanEnabled,
                    callback: (taskEditPropertiesResponse: DynamicObject) => {
                        if (unmounted) return;

                        updateTaskEditProperties({
                            loading: false,
                            data: taskEditPropertiesResponse,
                        });
                    },
                })
            );
        }
    };

    useEffect(initializeValues, [props.visible]);

    /**
     * Function for initializing the workflow step options based on workflowId
     * @param workflowId
     */
    const getWorkflowStepOptionsOnWorkflowChange = (workflowId: any) => {
        updateTaskEditProperties({
            workflowStepLoading: true,
        });
        if (workflowId) {
            dispatch(
                getWorkflowStepOptionsRequestAction({
                    workflowId,
                    callback: (res: any[]) => {
                        if (unmounted) return;
                        const existingData: DynamicObject = {
                            ...taskEditProperties.data,
                        };
                        existingData.WorkflowSteps = res;
                        updateTaskEditProperties({
                            workflowStepLoading: false,
                            data: existingData,
                        });
                        if (taskEditProperties.data.AllowWorkflowStep)
                            setFieldsValue({ WorkflowStateName: undefined });
                    },
                })
            );
        } else {
            const existingData: DynamicObject = {
                ...taskEditProperties.data,
            };
            existingData.WorkflowSteps = [];
            updateTaskEditProperties({
                workflowStepLoading: false,
                data: existingData,
            });
            if (taskEditProperties.data.AllowWorkflowStep)
                setFieldsValue({ WorkflowStateName: undefined });
        }
    };

    /**
     * Common function for getting the default workflow id baseed on taskEditProperties.data.WorkflowIds
     * (when there's only one - meaning all selected tasks have common workflow).
     */
    const getDefaultWorkflowId = () => {
        const workflowIds = get(taskEditProperties.data, 'WorkflowIds');

        const defaultWorkflowId =
            workflowIds && workflowIds.length === 1
                ? get(workflowIds, 0)
                : undefined;

        return defaultWorkflowId;
    };

    const initializeWorkflowStepOptions = () => {
        const defaultWorkflowId = getDefaultWorkflowId();
        if (
            defaultWorkflowId &&
            get(taskEditProperties.data, 'AllowWorkflowStep')
        ) {
            getWorkflowStepOptionsOnWorkflowChange(defaultWorkflowId);
        }
    };

    useEffect(initializeWorkflowStepOptions, [
        get(taskEditProperties.data, 'WorkflowIds'),
    ]);

    /**
     * Function called when the `Save` button is clicked.
     * @param e
     */
    const handleFormSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        validateFields((err: any, values: DynamicObject) => {
            if (!err) {
                if (values.ActionDate)
                    values.ActionDate = moment(values.ActionDate).format(
                        dateFormatYYYYMMDDDash
                    );
                // Commenting this out to remove the nullable workflowid setting
                // if (!get(taskEditProperties.data, 'AllowWorkflowName')) {
                //     values.WorkflowId = null;
                // }
                if (
                    !values.WorkflowId &&
                    taskEditProperties.data.WorkflowIds.length === 1
                ) {
                    values.WorkflowId = get(
                        taskEditProperties.data.WorkflowIds,
                        0
                    );
                }

                props.onSaveClick(values);
            }
        });
    };

    const allValues = omitBy(getFieldsValue(), isEmpty);
    const isSaveBtnDisabled = isEmpty(allValues);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const commentFormItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    let hasEditableProperty = false;
    forEach(taskEditProperties.data, (editProp: boolean, idx: string) => {
        if (startsWith(idx, 'Allow') && editProp) {
            hasEditableProperty = true;
        }
    });

    /**
     * Function that populates the editable properties section based on API response.
     */
    const getEditablePropertiesContent = () => {
        if (hasEditableProperty) {
            const defaultWorkflowId = getDefaultWorkflowId();
            const workflowStepDisabled =
                get(taskEditProperties.data, 'AllowWorkflowName') &&
                isEmpty(get(taskEditProperties.data, 'WorkflowSteps'));
            const selectedWorkflowId = getFieldValue('WorkflowId');
            const workflowStepInstruction =
                workflowStepDisabled && !selectedWorkflowId;
            return (
                <div>
                    <div>
                        {get(taskEditProperties.data, 'AllowPriority') && (
                            <Row type="flex" align="middle">
                                <Col span={24}>
                                    <FormItem label="Priority">
                                        {getFieldDecorator('Priority')(
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Unchanged"
                                                allowClear
                                            ></Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        )}
                        {get(taskEditProperties.data, 'AllowWorkflowName') && (
                            <Row type="flex" align="middle">
                                <Col
                                    span={24}
                                    className="dropdown-multiline-cont"
                                >
                                    <FormItem label="Workflow">
                                        {getFieldDecorator('WorkflowId', {
                                            initialValue: defaultWorkflowId,
                                        })(
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Unchanged"
                                                allowClear
                                                onChange={
                                                    getWorkflowStepOptionsOnWorkflowChange
                                                }
                                            >
                                                {populateWorkflowOptions()}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        )}
                        {workflowStepInstruction && (
                            <Row>
                                <Col span={8}></Col>
                                <Col span={16}>
                                    Select a <b>Workflow</b> first to enable the{' '}
                                    <b>Workflow step</b> field
                                </Col>
                            </Row>
                        )}
                        {get(taskEditProperties.data, 'AllowWorkflowStep') && (
                            <Row type="flex" align="middle">
                                <Col span={24}>
                                    <FormItem label="Workflow step">
                                        {getFieldDecorator('WorkflowStateName')(
                                            <SelectReadonly
                                                placeholder="Unchanged"
                                                loading={
                                                    taskEditProperties.workflowStepLoading
                                                }
                                                allowClear
                                                disabled={
                                                    taskEditProperties.workflowStepLoading ||
                                                    workflowStepDisabled
                                                }
                                                style={{ width: '100%' }}
                                            >
                                                {populateWorkflowStepOptions()}
                                            </SelectReadonly>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        )}
                        {get(taskEditProperties.data, 'AllowActionDate') && (
                            <Row type="flex" align="middle">
                                <Col span={24}>
                                    <FormItem label="Action date">
                                        {getFieldDecorator('ActionDate', {
                                            rules: [
                                                {
                                                    type: 'object',
                                                    message:
                                                        'The date selected is not valid!',
                                                },
                                            ],
                                        })(
                                            <DatePicker
                                                format={dateFormatDDMMYYYYSlash}
                                                placeholder="Unchanged"
                                                style={{ width: '100%' }}
                                                getCalendarContainer={
                                                    props.containerRef
                                                        ? () =>
                                                              getPopoverContainer(
                                                                  props.containerRef
                                                              )
                                                        : undefined
                                                }
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        )}
                        {get(taskEditProperties.data, 'AllowAssignedTo') && (
                            <Row className="mb-10" type="flex" align="middle">
                                <Col span={24}>
                                    <FormItem label="Assigned to">
                                        {getFieldDecorator('AssignedTo')(
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Unchanged"
                                                allowClear
                                            ></Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        )}
                        <Row className="mb-10" type="flex" align="middle">
                            <Col className="ta-right pr-8" md={8}>
                                Comment:
                            </Col>
                            <Col md={16}></Col>
                        </Row>
                    </div>
                    <div>
                        <FormItem {...commentFormItemLayout}>
                            {getFieldDecorator('Comment', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Comment required!',
                                    },
                                    {
                                        max: 1024,
                                        message:
                                            'Up to 1024 characters only can be saved!',
                                    },
                                ],
                            })(
                                <TextArea
                                    autoSize={{
                                        minRows: 4,
                                    }}
                                    placeholder="Please provide some comment here"
                                    allowClear
                                />
                            )}
                        </FormItem>
                    </div>
                </div>
            );
        } else {
            return (
                <Row>
                    <Col>
                        There are no common properties to change for the
                        selected tasks. Please close this panel and change your
                        selection to include one type of task.
                    </Col>
                </Row>
            );
        }
    };

    return (
        <Form className="form-inline-mb-8" {...formItemLayout}>
            {taskEditProperties.loading ? (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            ) : (
                <div>{getEditablePropertiesContent()}</div>
            )}
            <div className="mt-10 ta-right">
                {hasEditableProperty && (
                    <Button
                        className="mr-10"
                        onClick={handleFormSave}
                        disabled={isSaveBtnDisabled}
                        type="primary"
                    >
                        Save
                    </Button>
                )}
                <Button className="buttonGrey" onClick={props.onCancelClick}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

const TaskEditDrawerContentForm = Form.create({
    name: 'edit-active-tasks-form',
})(TaskEditDrawerContent);

export default withRouter(TaskEditDrawerContentForm);
