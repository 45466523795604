import { action } from 'typesafe-actions';
import { ConversationLineActionTypes } from './types';
import {
    ResponseModalObject,
    DynamicObject,
} from '../../utils/commonInterfaces';

/**
 * Action that fetches the Communication Resource Response Details page (public) data.
 * @param conversationLineId
 * @param callback
 */
export const fetchCommunicationResourceResponseDetailsAction = (
    conversationLineId: string,
    callback: (response: DynamicObject) => void
) =>
    action(ConversationLineActionTypes.FETCH_COMMUNICATION_RESOURCE_RESPONSE_DETAILS, {
        conversationLineId,
        callback,
    });

/**
* Action that sends a request to the Communication Resource Response.
* @param conversationLineId
* @param callback
*/
export const sendCommunicationResourceResponseAction = (
    payload: DynamicObject,
    callback: (response: DynamicObject) => void
) =>
    action(ConversationLineActionTypes.SEND_COMMUNICATION_RESOURCE_RESPONSE_REQUEST, {
        payload,
        callback
    });
/**
* Action that sends a request to the ResourceEmailResponse.
* @param payload
* @param callback
*/
export const publicAttachToResourceRequestAction = (
    payload: DynamicObject,
    callback: (response: DynamicObject) => void
) =>
    action(ConversationLineActionTypes.PUBLIC_ATTACH_TO_RESOURCE_REQUEST, {
        payload,
        callback
    });
