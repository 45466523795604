import AutoComplete from 'antd/lib/auto-complete';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input/Input';
import { SelectValue } from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import { API } from 'aws-amplify';
import { forEach, get, includes, isEmpty, isString, some, isUndefined } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import queries from '../../graphql/queries.graphql';
import { populatePopoverContainer } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { customFieldIndicator } from './FilterBar';
import { Form } from 'antd';

const { Item: FormItem } = Form;

const FILTER_SEARCH_DEBOUNCE_TIME = 2000; //ms
let unmounted: boolean = false;
interface IProps {
    updateField: (value: string) => void;
    onSelect?: (value: any) => void;
    stateValue: string;
    queryName: string;
    filterField: string | undefined;
    queryFilterName?: string;
    sortField: string;
    responseName: string;
    keyField?: string;
    labelField: any;
    updateFiltersFunction?: () => void;
    onPressEnter?: () => void;
    hasNoOkButton?: boolean;
    queryFilterNameSub?: string;
    filterSubChecking?: (inputVal: string | undefined | null) => boolean;
    loading?: boolean;
    hasNoContainerRef?: boolean;
    readOnly?: boolean;
    minimumLength?: number;
    placeholder?: string;
    extraQueryVariables?: {};
    getFieldDecorator: any;
    widgetDetails: DynamicObject;
    filterFieldKey: string;
}
let timeoutHandle: any = null;

const InputAutoCompleteWithButtonDashboard: React.FC<IProps> = ({
    updateField,
    onSelect,
    stateValue,
    queryName,
    filterField,
    queryFilterName,
    sortField,
    responseName,
    keyField,
    labelField,
    updateFiltersFunction,
    onPressEnter,
    hasNoOkButton,
    queryFilterNameSub,
    filterSubChecking,
    loading,
    hasNoContainerRef,
    readOnly,
    minimumLength,
    placeholder,
    extraQueryVariables,
    getFieldDecorator,
    widgetDetails,
    filterFieldKey
}: IProps) => {
    const containerRef = useRef(null);
    const [inputState, setInputState] = useState<{
        fetching: boolean;
        data: {
            raw: any,
            option: any
        }[];
        value: string | undefined;
    }>({
        fetching: false,
        data: [],
        value: stateValue || undefined,
    });

    /**
     * Common function for updating the input state.
     * @param inputStateObject - must conform to inputState object
     */
    const updateInputState = (inputStateObject: {}) => {
        setInputState({
            ...inputState,
            ...inputStateObject,
        });
    };

    const checkStateValueUpdate = () => {
        if (!stateValue) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            unmounted = true;
            updateInputState({
                fetching: false,
                data: [],
                value: stateValue || undefined,
            });
        } else if (stateValue !== value) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            updateInputState({
                fetching: false,
                data: [],
                value: stateValue || undefined,
            });
        }
    };

    useEffect(checkStateValueUpdate, [stateValue]);

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmounted = false;

        //will unmount
        return () => {
            unmounted = true;
        };
    };

    useEffect(setInitialLoad, []);

    const actionIfLoading = () => {
        if (loading) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
        }
    };

    useEffect(actionIfLoading, [loading]);

    const fetchOptions = (value: string) => {
        unmounted = false;
        updateField(value);
        if (value.length >= (minimumLength || 3)) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            let skipCheck = false;
            let filterNameInQueryUsed = queryFilterName;
            if (loading || (filterSubChecking && !queryFilterNameSub)) {
                skipCheck = true;
            } else if (filterSubChecking && queryFilterNameSub) {
                if (filterSubChecking(value))
                    filterNameInQueryUsed = queryFilterNameSub;
            }

            if (!skipCheck) {
                timeoutHandle = setTimeout(() => {
                    if (unmounted) return;
                    updateInputState({ value, data: [], fetching: true });

                    getDataOptions(value, filterNameInQueryUsed);
                }, FILTER_SEARCH_DEBOUNCE_TIME);
            }
        } else {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            updateInputState({ value, data: [], fetching: false });
        }
    };

    const getDataOptions = (value: string, usedFilterNameInQuery?: string) => {
        const usedQueryFilter = usedFilterNameInQuery || filterField;
        if (!usedQueryFilter) return;
        let queryVariables: DynamicObject = {
            PageSize: 10,
            Skip: 0,
            Ascending: true,
        };
        let usedCustomFieldName = '';
        const isCustomField =
            includes(filterField, customFieldIndicator) ||
            labelField === customFieldIndicator;
        if (isCustomField) {
            const cusFieldValueUsed = filterField || usedQueryFilter;
            const usedKeyValue = cusFieldValueUsed.replace(
                customFieldIndicator,
                ''
            );
            const customFieldKeyValArray = usedKeyValue.split('--');
            usedCustomFieldName = get(customFieldKeyValArray, 1);
            queryVariables = {
                // Type: get(customFieldKeyValArray, 0),
                FieldName: usedCustomFieldName,
                Value: value,
            };
        } else {
            queryVariables[usedQueryFilter] = value;
            queryVariables.SortField = sortField;
        }

        if (extraQueryVariables) {
            queryVariables = { ...queryVariables, ...extraQueryVariables };
        }

        const apiQuery = API.graphql({
            query: queries[queryName],
            variables: queryVariables,
        }) as Promise<any>;

        apiQuery
            .then((res: any) => {
                if (unmounted) return;

                const data: any[] = [];
                forEach(get(res, `data.${responseName}`), (opt: any) => {
                    let optValue = '';

                    if (isCustomField) {
                        optValue = opt;
                    } else {
                        if (isString(labelField)) {
                            optValue = opt[labelField];
                        } else {
                            let label = '';
                            forEach(labelField, (lf: string) => {
                                if (label !== '') label += ' ';
                                label += get(opt, lf) || '';
                            });
                            optValue = label;
                        }
                    }

                    const key = keyField ? opt[keyField] : optValue;

                    if (optValue && !some(data, d => d.option.key === key)) {
                        data.push({
                            raw: opt,
                            option: {
                                key: key,
                                value: key,
                                text: optValue,
                            }
                        });
                    }
                });

                updateInputState({ value, data, fetching: false });
            })
            .catch(() => {
                if (unmounted) return;

                updateInputState({ value, data: [], fetching: false });
            });
    };

    const getSelectedItem = (value: SelectValue) => {
        const { data } = inputState;

        if (data.length === 1) {
            return {
                rawData: data[0],
                value: data[0].option.text,
            };
        }

        if (data.length) {
            const item = data.find(d => d.option.value === value);
            return {
                rawData: item,
                value: (item && item.option.text) || value
            }
        }
        return { rawData: undefined, value }
    };

    const handleChange = (value: SelectValue) => {
        const selectedItem = getSelectedItem(value);
        updateInputState({
            value: selectedItem.value,
            data: [],
            fetching: false,
        });
        onSelect && onSelect((selectedItem.rawData && selectedItem.rawData.raw) || selectedItem.value);
    };

    const handleSelect = (value: SelectValue) => {
        const selectedItem = getSelectedItem(value);
        updateField(selectedItem.value);
        onSelect && onSelect((selectedItem.rawData && selectedItem.rawData.raw) || selectedItem.value);
    };

    const handleBlur = (value: SelectValue) => {
        const selectedItem = getSelectedItem(value);
        if (!isUndefined(selectedItem.rawData)) {
            updateField(selectedItem.value);
            onSelect && onSelect((selectedItem.rawData && selectedItem.rawData.raw) || selectedItem.value);
        }
    };

    const onOkClick = () => {
        unmounted = true;
        if (timeoutHandle) clearTimeout(timeoutHandle);
        updateInputState({
            fetching: false,
            data: [],
            value: value || undefined,
        });
        if (updateFiltersFunction) updateFiltersFunction();
    };

    const onInputPressEnter = () => {
        unmounted = true;
        if (isEmpty(inputState.data)) {
            if (onPressEnter) onPressEnter();
        }
    };
    const { fetching, data, value } = inputState;

    return (
        <div
            className={hasNoOkButton ? '' : 'pop-action-content'}
            ref={hasNoContainerRef ? undefined : containerRef}
        >
            <div>
                <FormItem>
                    {getFieldDecorator(filterFieldKey, {
                        initialValue: get(
                            widgetDetails,
                            filterFieldKey
                        ),
                    })(
                        <AutoComplete
                            className="autocomplete-input-dashboard"
                            dataSource={data.map(d => d.option)}
                            notFoundContent={null}
                            filterOption={false}
                            onSearch={fetchOptions}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            // onBlur={handleBlur}
                            dropdownClassName={
                                hasNoOkButton ? '' : 'autocomplete-input-dropdown'
                            }
                            getPopupContainer={
                                hasNoContainerRef
                                    ? undefined
                                    : populatePopoverContainer(containerRef)
                            }
                        >
                            <Input
                                prefix={
                                    fetching ? (
                                        <Spin
                                            indicator={
                                                <Icon
                                                    type="loading"
                                                    style={{ fontSize: 12 }}
                                                    spin
                                                />
                                            }
                                        />
                                    ) : (
                                        <Icon type="search" />
                                    )
                                }
                                placeholder={placeholder || "Search"}
                                allowClear
                                onPressEnter={onInputPressEnter}
                                readOnly={readOnly}
                            />
                        </AutoComplete>
                    )}
                </FormItem>
            </div>
            {!hasNoOkButton && (
                <div className="ok-button-container">
                    <Button type="primary" onClick={onOkClick}>
                        Ok
                    </Button>
                </div>
            )}
        </div>
    );
};

export default InputAutoCompleteWithButtonDashboard;
