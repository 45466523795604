import update from 'immutability-helper';
import { concat } from 'lodash';
import { Reducer } from 'redux';
import { ImportActionTypes, ImportState } from './types';

// Type-safe initialState!
export const initialState: ImportState = {
    history: {
        loading: false,
        errorMessages: [],
        data: [],
        activeData: {
            record: {},
            errorsAndWarnings: {
                data: [],
                pageData: {
                    currentPage: 0,
                    pageSize: 0,
                    hasNextPage: false,
                },
                loading: false,
                errorMessages: [],
            },
            loading: false,
            selectedId: null,
            errorMessages: [],
        },
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<ImportState> = (state = initialState, action) => {
    switch (action.type) {
        case ImportActionTypes.GET_IMPORT_HISTORY_REQUEST: {
            const newHistory = update(state.history, {
                $merge: {
                    loading: true,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }
        case ImportActionTypes.GET_IMPORT_HISTORY_SUCCESS: {
            let newDataState: any = update(state.history.data, {
                $set: action.payload.data,
            });

            const newHistory = update(state.history, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    errorMessages: initialState.history.errorMessages,
                    // pageData: action.payload.pageData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }
        case ImportActionTypes.GET_IMPORT_HISTORY_ERROR: {
            const newHistory = update(state.history, {
                $merge: {
                    loading: false,
                    data: initialState.history.data,
                    errorMessages: action.payload,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        //Single Record
        case ImportActionTypes.SET_IMPORT_HISTORY_SELECTED_ID_REQUEST: {
            const newActiveData = update(state.history.activeData, {
                $merge: {
                    selectedId: initialState.history.activeData.selectedId,
                },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        case ImportActionTypes.SET_IMPORT_HISTORY_SELECTED_ID_SUCCESS: {
            const newActiveData = update(state.history.activeData, {
                $merge: {
                    selectedId: action.payload,
                },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        case ImportActionTypes.GET_IMPORT_HISTORY_DATA_REQUEST: {
            const newActiveData = update(state.history.activeData, {
                record: { $set: initialState.history.activeData.record },
                loading: { $set: true },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        case ImportActionTypes.GET_IMPORT_HISTORY_DATA_SUCCESS: {
            const newActiveData = update(state.history.activeData, {
                record: { $set: action.payload.record },
                loading: { $set: false },
                errorMessages: {
                    $set: initialState.history.activeData.errorMessages,
                },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        case ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_REQUEST: {
            const newErrorsAndWarningsState = update(
                state.history.activeData.errorsAndWarnings,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newActiveData = update(state.history.activeData, {
                $merge: {
                    errorsAndWarnings: newErrorsAndWarningsState,
                },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        case ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_SUCCESS: {
            let newDataState;
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(
                    state.history.activeData.errorsAndWarnings.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newDataState = concat(
                    state.history.activeData.errorsAndWarnings.data,
                    action.payload.data
                );
            }
            const newErrorsAndWarningsData = update(
                state.history.activeData.errorsAndWarnings,
                {
                    data: { $set: newDataState },
                    pageData: { $set: action.payload.pageData },
                    loading: { $set: false },
                    errorMessages: {
                        $set:
                            initialState.history.activeData.errorsAndWarnings
                                .errorMessages,
                    },
                }
            );

            const newActiveData = update(state.history.activeData, {
                $merge: {
                    errorsAndWarnings: newErrorsAndWarningsData,
                },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        case ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_ERROR: {
            const newErrorsAndWarningsData = update(
                state.history.activeData.errorsAndWarnings,
                {
                    data: {
                        $set:
                            initialState.history.activeData.errorsAndWarnings
                                .data,
                    },
                    loading: { $set: false },
                    errorMessages: { $set: action.payload },
                }
            );

            const newActiveData = update(state.history.activeData, {
                $merge: {
                    errorsAndWarnings: newErrorsAndWarningsData,
                },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        case ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERROR: {
            const newActiveData = update(state.history.activeData, {
                record: { $set: initialState.history.activeData.record },
                loading: { $set: false },
                errorMessages: { $set: action.payload },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        case ImportActionTypes.CLEAR_IMPORT_HISTORY_DATA_SUCCESS: {
            const {
                record,
                loading,
                selectedId,
                errorMessages,
            } = initialState.history.activeData;
            const newActiveData = update(state.history.activeData, {
                record: { $set: record },
                loading: { $set: loading },
                selectedId: { $set: selectedId },
                errorMessages: { $set: errorMessages },
            });

            const newHistory = update(state.history, {
                $merge: {
                    activeData: newActiveData,
                },
            });
            return {
                ...state,
                history: newHistory,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as importReducer };
