import { Col, Divider, Row, Select, Tree } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { forEach, get, includes, isEmpty, map, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    dashboardWidgetsCurrencyTitle,
    dashboardWidgetsRegionTitle,
    getRegionOptions,
} from '../../../constants/dashboards';
import { ApplicationState } from '../../../store';
import { Company } from '../../../store/companies/types';
import { populatePopoverContainer } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import SelectReadonly from '../../FormComponents/SelectReadonly';

const { TreeNode } = Tree;
const { Option } = Select;
interface IProps {
    readonly getFieldDecorator: any;
    readonly setFieldsValue: any;
    readonly getFieldValue: any;
    readonly widgetDetails: DynamicObject;
    readonly drawerRef?: any;
    readonly isRegional?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly noTopDivider?: boolean;
}
export const commonOrgFormFields: DynamicObject = {
    REGIONS: 'Regions',
    COUNTRIES: 'Countries',
    COMPANIES: 'Companies',
    REGION: 'Region',
    CURRENCY: 'Currency',
};
export const allOptionsSelectedKey = 'all---selected';
const RegionsCountriesFilter: React.FC<IProps> = ({
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    widgetDetails,
    drawerRef,
    isRegional,
    organisationCurrenciesAll,
    noTopDivider,
}: IProps) => {
    const [usedWidgetDetails, setUsedWidgetDetails] =
        useState<DynamicObject>(widgetDetails);
    const [optionsState, setOptionsState] = useState<{
        regions: DynamicObject[];
        countries: DynamicObject[];
        companies: DynamicObject[];
        currencies: DynamicObject[];
    }>({
        regions: [],
        countries: [],
        companies: [],
        currencies: [],
    });

    const organisationUserCompanies = useSelector(
        (app: ApplicationState) => app.organisations.userCompanies.data
    );

    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const organisationCompaniesUsed = isRegional
        ? organisationCompanies
        : organisationUserCompanies;

    const getCountryOptions = (cusRegions?: string[]) => {
        const countryList: string[] = [];
        let regionsValueUsed: string[] | undefined = undefined;
        if (isRegional) {
            if (cusRegions && !isEmpty(cusRegions)) {
                regionsValueUsed = cusRegions;
            } else {
                const regionValue =
                    getFieldValue(commonOrgFormFields.REGION) ||
                    get(widgetDetails, commonOrgFormFields.REGION);
                regionsValueUsed = regionValue ? [regionValue] : [];
            }
        }

        if (isEmpty(regionsValueUsed)) {
            const regionOptions = getRegionOptions(organisationCompaniesUsed);
            regionsValueUsed = map(regionOptions, ({ value }) => value);
        }

        forEach(organisationCompaniesUsed, (oc: Company) => {
            if (
                oc.Country &&
                !includes(countryList, oc.Country) &&
                includes(regionsValueUsed, oc.Region)
            ) {
                countryList.push(oc.Country);
            }
        });

        return orderBy(
            map(countryList, (cl) => ({ label: cl, value: cl })),
            ['label'],
            ['asc']
        );
    };

    const getCompaniesSelectOptions = (
        cusRegions?: string[],
        cusCountries?: string[]
    ) => {
        let regionsValueUsed: string[] | undefined = undefined;

        if (isRegional) {
            if (cusRegions && !isEmpty(cusRegions)) {
                regionsValueUsed = cusRegions;
            } else {
                const regionValue =
                    getFieldValue(commonOrgFormFields.REGION) ||
                    get(widgetDetails, commonOrgFormFields.REGION);
                regionsValueUsed = regionValue ? [regionValue] : [];
            }
        }
        // else {
        //     regionsValueUsed = getFieldValue(commonOrgFormFields.REGIONS);
        // }

        if (isEmpty(regionsValueUsed)) {
            const regionOptions = getRegionOptions(organisationCompaniesUsed);
            regionsValueUsed = map(regionOptions, ({ value }) => value);
        }
        let countriesValueUsed: string[] | undefined = undefined;
        if (isRegional) {
            if (cusCountries && !isEmpty(cusCountries)) {
                countriesValueUsed = cusCountries;
            } else {
                const countriesValue = getFieldValue(
                    commonOrgFormFields.COUNTRIES
                );
                countriesValueUsed = countriesValue;
            }
        }
        if (isEmpty(countriesValueUsed)) {
            const countryOptions = getCountryOptions();
            countriesValueUsed = map(countryOptions, ({ value }) => value);
        }

        const companyOptions: DynamicObject[] = [];
        forEach(organisationCompaniesUsed, (oc: Company) => {
            if (
                includes(regionsValueUsed, oc.Region) &&
                includes(countriesValueUsed, oc.Country)
            ) {
                companyOptions.push({
                    label: oc.Name,
                    value: oc.CompanyId,
                });
            }
        });

        return orderBy(companyOptions, ['label'], ['asc']);
    };

    const getCurrencyOptions = () => {
        return map(organisationCurrenciesAll, (cur: DynamicObject) => ({
            label: get(cur, 'Value'),
            value: get(cur, 'Value'),
        }));
    };

    const initializeOptions = () => {
        if (!isEmpty(organisationCompaniesUsed)) {
            const regionOpts = getRegionOptions(organisationCompaniesUsed);
            const regionOptUsed = isRegional
                ? get(widgetDetails, commonOrgFormFields.REGION) || [
                      get(regionOpts, `0.value`),
                  ]
                : undefined;
            const regionsUsed = isRegional ? regionOptUsed : undefined;
            const countryOpts = getCountryOptions(regionsUsed);
            const countriesUsed = isRegional
                ? map(countryOpts, ({ value }) => value)
                : undefined;
            const companyOpts = getCompaniesSelectOptions(
                regionsUsed,
                countriesUsed
            );
            const currencyOpts = getCurrencyOptions();

            setOptionsState({
                regions: regionOpts,
                countries: countryOpts,
                companies: companyOpts,
                currencies: currencyOpts,
            });
        }
    };

    useEffect(initializeOptions, [organisationCompaniesUsed]);

    const overflowStyle: DynamicObject = {
        // maxHeight: 180,
        overflowX: 'auto',
    };

    /**
     * Function for populating the tree node
     * @param data - item which the tree node will be based on
     */
    const renderTreeNodes = (data: any) =>
        map(data, (item: DynamicObject) => {
            if (item.children) {
                return (
                    <TreeNode
                        title={item.label}
                        key={item.value}
                        dataRef={item}
                        selectable={false}
                    >
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }

            return (
                <TreeNode
                    key={get(item, 'value')}
                    title={get(item, 'label')}
                    selectable={false}
                />
            );
        });

    const populateTreeFormItemByType = (
        typeUsed: 'Regions' | 'Countries' | 'Companies'
    ) => {
        let treeData: DynamicObject[] = [];
        let optionsUsed: DynamicObject[] = [];
        if (typeUsed === commonOrgFormFields.REGIONS) {
            const regionOptions = optionsState.regions;
            optionsUsed = regionOptions;
            treeData = [
                {
                    label: 'All locations',
                    value: allOptionsSelectedKey,
                    children: regionOptions,
                },
            ];
        } else if (typeUsed === commonOrgFormFields.COUNTRIES) {
            const countryOptions = optionsState.countries;
            optionsUsed = countryOptions;
            treeData = [
                {
                    label: 'All locations',
                    value: allOptionsSelectedKey,
                    children: countryOptions,
                },
            ];
        } else if (typeUsed === commonOrgFormFields.COMPANIES) {
            const companyOptions = optionsState.companies;
            optionsUsed = companyOptions;
            treeData = [
                {
                    label: 'All companies',
                    value: allOptionsSelectedKey,
                    children: companyOptions,
                },
            ];
        }

        return (
            <FormItem className="custom-tree-cont">
                {getFieldDecorator(typeUsed, {
                    rules: [{ type: 'array' }],
                    initialValue: get(
                        usedWidgetDetails,
                        typeUsed,
                        map(optionsUsed, ({ value }) => value)
                    ),
                })(
                    <Tree
                        checkable
                        defaultExpandedKeys={[allOptionsSelectedKey]}
                        defaultCheckedKeys={get(
                            usedWidgetDetails,
                            typeUsed,
                            map(optionsUsed, ({ value }) => value)
                        )}
                        onCheck={(checkedKeys: any) => {
                            setFieldsValue({
                                [typeUsed]: checkedKeys,
                            });
                            if (
                                isRegional &&
                                typeUsed === commonOrgFormFields.COUNTRIES
                            ) {
                                onCountriesValueChange(checkedKeys);
                            }
                        }}
                    >
                        {renderTreeNodes(treeData)}
                    </Tree>
                )}
            </FormItem>
        );
    };

    /**
     * Common function for populating the select options based on given list.
     */
    const populateSelectOptions = (optionsUsed: DynamicObject[]) =>
        map(optionsUsed, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {label}
            </Option>
        ));

    const getNewWidgetTitle = (
        value: any,
        changeType: 'region' | 'currency'
    ) => {
        let newWidgetTitle = '';
        if (isRegional) {
            const widgetType = get(widgetDetails, 'type');
            if (includes(dashboardWidgetsRegionTitle, widgetType)) {
                const widgetTitleForm = getFieldValue('title');
                let usedWidgetTitle = widgetTitleForm;
                if (includes(widgetTitleForm, ' - ')) {
                    const baseWidgetTitle = get(
                        usedWidgetTitle.split(' - '),
                        0
                    );
                    const regionVal =
                        changeType === 'region'
                            ? value
                            : getFieldValue(commonOrgFormFields.REGION);
                    usedWidgetTitle = `${baseWidgetTitle} - ${regionVal}`;
                    if (includes(dashboardWidgetsCurrencyTitle, widgetType)) {
                        const currencyVal =
                            changeType === 'currency'
                                ? value
                                : getFieldValue(commonOrgFormFields.CURRENCY);
                        usedWidgetTitle += ` ${currencyVal}`;
                    }
                    newWidgetTitle = usedWidgetTitle;
                }
            }
        }

        return newWidgetTitle;
    };

    const onRegionValueChange = (value: any) => {
        const regionsUsed = [value];
        const countryOpts = getCountryOptions(regionsUsed);
        const countriesUsed = map(countryOpts, ({ value }) => value);

        const companyOpts = getCompaniesSelectOptions(
            regionsUsed,
            countriesUsed
        );
        const companiesUsed = map(companyOpts, ({ value }) => value);

        const newWidgetTitle = getNewWidgetTitle(value, 'region');

        const updatedFieldsValue: DynamicObject = {
            [commonOrgFormFields.COUNTRIES]: countriesUsed,
            [commonOrgFormFields.COMPANIES]: companiesUsed,
        };

        if (newWidgetTitle !== '') {
            updatedFieldsValue.title = newWidgetTitle;
        }

        setFieldsValue(updatedFieldsValue);

        setUsedWidgetDetails({
            ...usedWidgetDetails,
            [commonOrgFormFields.COUNTRIES]: countriesUsed,
            [commonOrgFormFields.COMPANIES]: companiesUsed,
        });

        setOptionsState({
            ...optionsState,
            countries: [],
            companies: [],
        });

        setTimeout(() => {
            setOptionsState({
                ...optionsState,
                countries: countryOpts,
                companies: companyOpts,
            });
        }, 300);
    };

    const onCountriesValueChange = (value: any) => {
        const regionValue = getFieldValue(commonOrgFormFields.REGION);
        const regionsUsed = [regionValue];
        const companyOpts = getCompaniesSelectOptions(regionsUsed, value);
        const companiesUsed = map(companyOpts, ({ value }) => value);

        setFieldsValue({
            [commonOrgFormFields.COMPANIES]: companiesUsed,
        });

        setUsedWidgetDetails({
            ...usedWidgetDetails,
            [commonOrgFormFields.COMPANIES]: companiesUsed,
        });

        setOptionsState({
            ...optionsState,
            companies: [],
        });

        setTimeout(() => {
            setOptionsState({
                ...optionsState,
                companies: companyOpts,
            });
        }, 300);
    };

    const onCurrencyValueChange = (value: string) => {
        const newWidgetTitle = getNewWidgetTitle(value, 'currency');

        if (newWidgetTitle !== '') {
            setFieldsValue({
                title: newWidgetTitle,
            });
        }
    };

    /**
     * Function for populating regional settings
     */
    const populateRegionalSettings = () => {
        const regionOptions = optionsState.regions;
        const currencyOptions = optionsState.currencies;

        return (
            <>
                <Row className="mb-5" gutter={10}>
                    <Col span={12}>
                        <FormItem label="Hosting location">
                            {getFieldDecorator(commonOrgFormFields.REGION, {
                                initialValue:
                                    get(
                                        usedWidgetDetails,
                                        commonOrgFormFields.REGION
                                    ) || get(regionOptions, '0.value'),
                                rules: [
                                    {
                                        required: true,
                                        message: 'Region required!',
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={false}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    onChange={onRegionValueChange}
                                >
                                    {populateSelectOptions(regionOptions)}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                    {!isEmpty(organisationCurrenciesAll) && (
                        <Col span={12}>
                            <FormItem label="Currency">
                                {getFieldDecorator(
                                    commonOrgFormFields.CURRENCY,
                                    {
                                        initialValue:
                                            get(
                                                usedWidgetDetails,
                                                commonOrgFormFields.CURRENCY
                                            ) ||
                                            (get(currencyOptions, '0.value')
                                                ? get(
                                                      currencyOptions,
                                                      '0.value'
                                                  )
                                                : undefined),
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Currency required!',
                                            },
                                        ],
                                    }
                                )(
                                    <SelectReadonly
                                        readOnly={false}
                                        style={{ width: '100%' }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                        onChange={onCurrencyValueChange}
                                    >
                                        {populateSelectOptions(currencyOptions)}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                        </Col>
                    )}
                </Row>
                <Divider />
            </>
        );
    };

    return (
        <>
            {!noTopDivider && <Divider />}
            {isRegional && populateRegionalSettings()}
            <Row className={!isRegional ? 'mb-5' : ''}>
                {!isRegional && (
                    <Col span={12}>
                        <Row>
                            <Col>
                                <h3>Filter by hosting locations</h3>
                            </Col>
                        </Row>
                        {!isEmpty(optionsState.regions) && (
                            <Row>
                                <Col style={overflowStyle}>
                                    {populateTreeFormItemByType(
                                        commonOrgFormFields.REGIONS
                                    )}
                                </Col>
                            </Row>
                        )}
                    </Col>
                )}
                <Col span={12}>
                    <Row>
                        <Col>
                            <h3>Filter by country</h3>
                        </Col>
                    </Row>
                    {!isEmpty(optionsState.countries) && (
                        <Row>
                            <Col style={overflowStyle}>
                                {populateTreeFormItemByType(
                                    commonOrgFormFields.COUNTRIES
                                )}
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Filter by companies</h3>
                        </Col>
                    </Row>
                    {!isEmpty(optionsState.companies) && (
                        <Row>
                            <Col style={overflowStyle}>
                                {populateTreeFormItemByType(
                                    commonOrgFormFields.COMPANIES
                                )}
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default RegionsCountriesFilter;
