/**
 * Component for populating the Confirm remittance advice panel when clicking on `Action` button for confirm remittance advice items.
 */

import {
    AutoComplete,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Pagination,
    Row,
    Skeleton,
    Table,
    Tooltip,
    Select
} from 'antd';
import { capitalize, every, filter, find, findIndex, first, get, isEmpty, map, includes, set, times, update, toLower, sumBy, isUndefined, isNumber } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../../components/common/DateFormatHandler';
import ModalWithSpinner from '../../../components/common/ModalWithSpinner';
import { dateFormatDDMMMYYYYSpace, dateFormatYYYYMMDDDash } from '../../../constants/dateFormats';
import { invoiceStateMap } from '../../../constants/invoicesSortAndFilters';
import { ApplicationState } from '../../../store';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import { getNotificationLetterPresignedUrlAction } from '../../../store/notifications/actions';
import { confirmRemittanceAdviceRequest, getRemittanceAdviceDetails, confirmRemittanceAdviceDisregardTasksRequest, reviewRemittanceAdviceRequest } from '../../../store/remittanceAdvices/actions';
import { selectRemittanceAdviceData } from '../../../store/remittanceAdvices/sagas';
import { RemittanceAdviceInvoice, RemittanceAdviceCredits, RemittanceAdvicesState, RemittanceAdviceRebates } from '../../../store/remittanceAdvices/types';
import { Task } from '../../../store/tasks/types';
import { getPopoverContainer, openURLNewTab } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import FontAwesome from '../../common/FontAwesome';
import IFrameWithLoading from '../../common/IFrameWithLoading';
import InputAutoCompleteWithButton from '../../common/InputAutoCompleteWithButton';
import { withNumberFormatHandler } from '../../common/NumberFormatHandler';
import './TaskActionRemittanceAdvicePanelContent.less';
import { Invoice } from '../../../store/invoices/types';
import { confirmModalCancelText, confirmModalOkText } from '../../../config/config';
import { CompanyUserRole } from '../../../store/companies/types';
import { roundToDecimals, roundNumberToDecimalDigits } from '../../../utils/commonFunctions';
import SheetViewer from '../../common/SheetViewer';
import SelectReadonly from '../../FormComponents/SelectReadonly';
import { GetCreditsRequestPayload, Credit, CreditsState, CreditVM } from '../../../store/credits/types';
import { getCreditsRequestAction } from '../../../store/credits/actions';
import { DANGER_COLOR } from '../../../constants/common';
import CreateCreditManuallyDrawer from '../../../components/sales/CreateCreditManuallyDrawer';
import { createCreditManuallyRequestAction, getCreditsByCreditCodesRequestAction } from '../../../store/credits/actions';
import ReviewRemittanceAdvicePanel from './ReviewRemittanceAdvicePanel';
import { faCommentDollar } from '@fortawesome/free-solid-svg-icons';

const Option = Select.Option;
const { confirm } = Modal;

interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly formatCurrency?: (amount: number) => string;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly form?: any;
    readonly selectedTaskId?: string;
    readonly containerRef?: any;
}

interface ICombinedProps extends IProps {
    valueChanges?: any;
    setValueChanges: (valueChanges?: any) => void;
}

interface RemittanceAdviceInvoiceVM extends RemittanceAdviceInvoice {
    _touched: boolean;
    SearchValue: string;
}

interface RemittanceAdviceCreditVM extends RemittanceAdviceCredits {
    _touched: boolean;
    CreditAmount: number;
    SearchValue: string;
}

interface RemittanceAdviceRebateVM extends RemittanceAdviceRebates {
    _touched: boolean;
    SearchValue: string;
}

interface FetchCreditsFilter {
    customerId?: string;
}

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        md: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 19 },
    },
};

const FORM_FIELDS = {
    MULTIPLE_CUSTOMER: 'MultipleCustomer',
    CUSTOMER_ID: 'CustomerId',
    PAID_DATE: 'PaidDate',
    AMOUNT: 'Amount',
    INVOICES: 'RemittanceAdviceInvoices',
    CREDITS: 'RemittanceAdviceCredits',
    REBATES: 'RemittanceAdviceRebates',
    INVOICE_CREDITS: 'InvoiceCredits',
    AVAILABLE_CREDITS: 'AvailableCredits',
    AVAILABLE_REBATES: 'AvailableRebates',
    _ALL: '_ALL'
};

const invoicesTableColumns = [
    {
        title: 'Invoice Number',
        dataIndex: 'InvoiceNumber',
        width: '33.33%'
    },
    {
        title: 'Remaining Amount',
        dataIndex: 'RemainingAmount',
        width: '33.33%'
    },
    {
        title: 'Amount',
        dataIndex: 'Amount',
        width: '33.33%'
    }
];

const creditsTableColumns = [
    {
        title: 'Credit Number',
        dataIndex: 'CreditNumber'
    },
    {
        title: 'Invoice Number',
        dataIndex: 'InvoiceNumber'
    },
    {
        title: 'Remaining Amount',
        dataIndex: 'RemainingAmount'
    },
    {
        title: 'Amount',
        dataIndex: 'Amount'
    }
];

const rebatesTableColumns = [
    {
        title: 'Rebate Number',
        dataIndex: 'RebateNumber'
    },
    {
        title: 'Amount',
        dataIndex: 'Amount'
    },
    {
        title: () => {
        return (
            <div style={{ display: 'flex', alignContent: 'center' }}>
                <div style={{ flexGrow: 1 }} className="mr-5">
                    Amount to Allocate
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Tooltip
                        title={() => {
                            return (
                                <div> 
                                    If your rebate is a percentage amount and your Remittance Advice has a fixed amount, 
                                    you may enter the amount to allocate instead of using the percentage. Calculate Percentage 
                                    must be disabled in order to enter this amount.
                                </div>
                            );
                        }}
                        placement="topRight">
                        <FontAwesome
                            icon={['fas', 'info-circle']}
                            className="fs-18 mr-5"
                        />
                    </Tooltip>
                </div> */}
            </div>
        );
        },
        dataIndex: 'AmountToAllocate'
    },
    // {
    //     title: () => {
    //         return (
    //             <div style={{ display: 'flex', alignContent: 'center' }}>
    //                 <div style={{ flexGrow: 1 }} className="mr-5">
    //                     Calculate Percentage
    //                 </div>
    //                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
    //                     <Tooltip
    //                         title={() => {
    //                             return (
    //                                 <div> 
    //                                     If your rebate is a percentage amount, an amount will be generated based on the invoices 
    //                                     included in the Remittance Advice. To enter your own amount, please uncheck this box.
    //                                 </div>
    //                             );
    //                         }}
    //                         placement="topRight">
    //                         <FontAwesome
    //                             icon={['fas', 'info-circle']}
    //                             className="fs-18 mr-5"
    //                         />
    //                     </Tooltip>
    //                 </div>
    //             </div>
    //         );
    //         },
    //     dataIndex: 'CalculatePercentage'
    // }
];

const excelExtensions = ['.xls', '.xlsx'];
const csvExtensions = ['.csv', '.tsv'];

const RA_SHEET_VIEWER_ID = "app-ra-sheet-viewer";

const TaskActionRemittanceAdvicePanelContent: React.FC<ICombinedProps> = ({
    visible,
    closePanel,
    formatCurrency,
    formatToParts,
    form,
    valueChanges,
    setValueChanges,
    selectedTaskId,
    containerRef
}) => {
    const dispatch = useDispatch();
    const tasksList: Task[] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks.data
    );

    const availableCreditsState: CreditsState = useSelector(
        (state: ApplicationState) => state.credits
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const {
        record: remittanceAdvice,
        loading: getDetailsLoading
    }: RemittanceAdvicesState['activeData'] = useSelector(selectRemittanceAdviceData);
    const customerLabel = useSelector(getCustomerUILabel);
    const selectedTask = find(tasksList, d => d.Id === selectedTaskId);
    const RemittanceAdviceId = get(selectedTask, 'RemittanceAdvice.Id') || '';
    const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } =
        form;
    const [customerSearch, setCustomerSearch] = useState('');
    const [lastCustomer, setLastCustomer] = useState({
        CustomerId: '',
        CustomerName: ''
    });
    const [attachmentUrl, setAttachmentUrl] = useState<string>();
    const [attachmentIdx, setAttachmentIdx] = useState<number>(0);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [submitDisregardLoading, setSubmitDisregardLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [invoiceList, setInvoiceList] = useState<Partial<RemittanceAdviceInvoiceVM>[]>([]);
    const [creditList, setCreditList] = useState<Partial<RemittanceAdviceCreditVM>[]>([]);
    const [rebateList, setRebateList] = useState<Partial<RemittanceAdviceRebateVM>[]>([]);
    const [creatingCredits, setCreatingCredits] = useState<boolean>(false);
    const totalAttachments = get(remittanceAdvice, ['Attachments', 'length']);
    const { loading: fetchingCredits, data: availableCreditList} = availableCreditsState;
    const [initialized, setInitialized] = useState<boolean>(false);
    const [selectedCredits, setSelectedCredits] = useState<Partial<RemittanceAdviceCreditVM>[]>([]);
    const [selectedRebates, setSelectedRebates] = useState<Partial<RemittanceAdviceRebateVM>[]>([]);
    const [validationState, setValidationState] = useState<{
        valid?: boolean,
        errors: { [key: string]: any }
    }>({ valid: undefined, errors: {} });
    const [isReviewRemittanceAdvicePanelVisible, setIsReviewRemittanceAdvicePanelVisible] = useState<boolean>(false);
    const [remittanceAdvicePayload, setRemittanceAdvicePayload] = useState<string[]>([]);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

    /**
     * Function for closing the panel.
     * When `Cancel` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return {
            amountDisplay: formatCurrency ? formatCurrency(amount) : amount,
            currency: first(map(
                filter(
                    (formatToParts ? formatToParts(amount) : []), p => p.type === 'currency'
                ),
                p => p.value
            ))
        };
    };

    /**
     * Listener function if the drawer visibility changes.
     * If drawer closes, resets the field values.
     * If drawer opens, gets action options.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    const populateInvoiceTableDataSourceItem = (invoice: RemittanceAdviceInvoiceVM, index: number) => {
        const appliedCredit = creditList.filter(credit => credit.InvoiceId === (invoice.Invoice && invoice.Invoice.Id || ''));
        const amountOwing = isNaN(invoice.AmountOwing) ? 0 : invoice.AmountOwing;
        console.log(isNaN(invoice.Amount))
        const amount = isNaN(invoice.Amount) ? 0 : invoice.Amount;
        const invoiceRemainingAmount = getInvoiceRemainingAmount(invoice);

        const { currency } = handleFormatCurrency(invoice.Amount);
        const { warning } = validateInvoice(invoice);
        return {
            key: index,
            InvoiceNumber: (<>
                <InvoiceAutoComplete
                    invoice={invoice}
                    customerId={getFieldValue(FORM_FIELDS.CUSTOMER_ID)}
                    updateInvoice={updateInvoice} />
            </>),
            RemainingAmount: (<>
                <div style={{ flexGrow: 1 }} className="mr-5">
                    {`${currency} `}
                    {roundNumberToDecimalDigits(invoiceRemainingAmount)}
                </div>
            </>),
            Amount: (<>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        {`${currency} `}
                        <InputNumber
                            precision={2}
                            step={0.01}
                            min={0}
                            value={amount}
                            onChange={(value) => {
                                let finalValue = isNumber(value) ? value : 0;
                                const newInvoice = {
                                    ...invoice,
                                    Amount: Math.max(finalValue, 0)
                                };
                                updateInvoice(invoice, newInvoice);
                            }}
                        />
                    </div>
                    {(warning.length > 0) && <Tooltip
                        title={warning.map((message, idx) => <div key={idx}>{message}</div>)}
                        placement="topRight">
                        <FontAwesome
                            icon={['fas', 'info-circle']}
                            style={{ color: '#ff5d00' }}
                            className="fs-18 mr-5"
                        />
                    </Tooltip>}
                    <div className="fs-16 cursor-p-div" onClick={handleDeleteInvoice(invoice)}>
                        <FontAwesome icon={['fas', 'trash']} />
                    </div>
                </div>
            </>)
        }
    };

    const populateCreditTableDataSourceItem = (credit: RemittanceAdviceCreditVM, index: number) => {
        const allocatedCredit = isNaN(credit.Amount) ? 0 : credit.Amount;
        const creditAmount = isNaN(credit.CreditAmount) ? 0 : credit.CreditAmount;
        const remainingAmount = getCreditRemainingAmount(credit);

        const { currency } = handleFormatCurrency(credit.Amount);
        const { warning } = validateCredit(credit);

        return {
            key: index,
            CreditNumber: (<>
                <CreditAutoComplete
                    credit={credit}
                    customerId={getFieldValue(FORM_FIELDS.CUSTOMER_ID)}
                    updateCredit={updateCredit} />
            </>),
            InvoiceNumber: (<>
                <Select
                    className="w-100"
                    style={{ width: 150, }}
                    defaultValue={credit.InvoiceId ? credit.InvoiceId : ''}
                    onChange={(value: any) => {
                        const invoiceCredit = {
                            ...credit,
                            InvoiceId: value.toString()
                        }

                        updateCredit(credit, invoiceCredit);
                    }}
                >
                    <Option value=''>&nbsp;</Option>
                    {populateDropdownInvoiceComponent()}
                </Select>
            </>),
            RemainingAmount: (<>
                <div style={{ width: 200 }} className="mr-5">
                    {`${currency} `}
                    {roundNumberToDecimalDigits(remainingAmount)}
                </div>
            </>),
            Amount: (<>
                <div style={{ display: 'flex', alignItems: 'center', width: 150 }}>
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        {`${currency} `}
                        <InputNumber
                            precision={2}
                            step={0.01}
                            min={0}
                            value={allocatedCredit}
                            onChange={(value) => {
                                let finalValue = isNumber(value) ? value : 0;
                                const newCredit = {
                                    ...credit,
                                    Amount: Math.max(finalValue, 0)
                                };
                                updateCredit(credit, newCredit);
                            }}
                        />
                    </div>
                    {(warning.length > 0) && <Tooltip
                        title={warning.map((message, idx) => <div key={idx}>{message}</div>)}
                        placement="topRight">
                        <FontAwesome
                            icon={['fas', 'info-circle']}
                            style={{ color: '#ff5d00' }}
                            className="fs-18 mr-5"
                        />
                    </Tooltip>}
                    <div className="fs-16 cursor-p-div" onClick={handleDeleteCredit(credit)}>
                        <FontAwesome icon={['fas', 'trash']} />
                    </div>
                </div>
            </>)
        }
    };

    const populateRebatesTableDataSourceItem = (rebate: RemittanceAdviceRebateVM, index: number) => {
        const { currency } = handleFormatCurrency(rebate.Amount);
        const { warning } = validateRebate(rebate);

        const amount = isNaN(rebate.Amount) ? 0 : rebate.Amount;
        const isPercentage = rebate.Percentage;
        // const calculatePercentage =  rebate.CalculatePercentage;
        const isFixedAmount = !isPercentage;

        const amountToAllocate = get(rebate, 'AmountToAllocate') || undefined;

        return {
            key: index,
            RebateNumber: (<>
            <div style={{ width: 200 }}>
                <RebateAutoComplete
                    rebate={rebate}
                    customerId={getFieldValue(FORM_FIELDS.CUSTOMER_ID)}
                    updateRebate={updateRebate} />
                </div>
            </>),
            Amount: (<>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                            isPercentage ?
                                <div style={{ flexGrow: 1 }} className="mr-5">
                                    {roundNumberToDecimalDigits(amount)} %
                                </div>
                            : 
                                <div style={{ flexGrow: 1 }} className="mr-5">
                                        {`${currency}`}
                                        {roundNumberToDecimalDigits(amount)}
                                </div>
                    }
                </div>
            </>),
            AmountToAllocate: (<>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        {`${currency} `}
                        <InputNumber
                            precision={2}
                            step={0.01}
                            min={0}
                            disabled={isFixedAmount}
                            value={amountToAllocate}
                            onChange={(value) => {
                                let finalValue = isNumber(value) ? value : 0;
                                const newRebate = {
                                    ...rebate,
                                    AmountToAllocate: Math.max(finalValue, 0)
                                };
                                updateRebate(rebate, newRebate);
                            }}
                        />
                    </div>
                        {(warning.length > 0) && <Tooltip
                            title={warning.map((message, idx) => <div key={idx}>{message}</div>)}
                            placement="topRight">
                            <FontAwesome
                                icon={['fas', 'info-circle']}
                                style={{ color: '#ff5d00' }}
                                className="fs-18 mr-5"
                            />
                        </Tooltip>}
                        <div className="fs-16 cursor-p-div" onClick={handleDeleteRebate(rebate)}>
                            <FontAwesome icon={['fas', 'trash']} />
                        </div>
                </div>
            </>),
            // CalculatePercentage: (<>
            //     <div style={{ display: 'flex', alignItems: 'center' }}>
            //         <div style={{ flexGrow: 1 }} className="mr-5">
            //             <Checkbox
            //                 defaultChecked={calculatePercentage}
            //                 checked={calculatePercentage}
            //                 disabled={isFixedAmount}
            //                 onChange = {(e) => {
            //                     const newRebate = {
            //                         ...rebate,
            //                         AmountToAllocate : 0,
            //                         CalculatePercentage: e.target.checked
            //                     };
            //                     updateRebate(rebate, newRebate);
            //                 }}
            //             ></Checkbox>
            //         </div>
            //         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            //             {(warning.length > 0) && <Tooltip
            //                 title={warning.map((message, idx) => <div key={idx}>{message}</div>)}
            //                 placement="topRight">
            //                 <FontAwesome
            //                     icon={['fas', 'info-circle']}
            //                     style={{ color: '#ff5d00' }}
            //                     className="fs-18 mr-5"
            //                 />
            //             </Tooltip>}
            //             <div className="fs-16 cursor-p-div" onClick={handleDeleteRebate(rebate)}>
            //                 <FontAwesome icon={['fas', 'trash']} />
            //             </div>
            //         </div>
            //     </div>

            // </>),

        }
    };

    /**
     * Function for populating the Invoice dropdown.
     */
    const populateDropdownInvoiceComponent = () => {
        const commonFilters = map(
            invoiceList,
            ({ Number, Invoice }: { Number: string, Invoice: Invoice }) => (
                <Option
                    key={
                        invoiceList.findIndex(inv => inv.Number === Number)
                    }
                    value={(Invoice && Invoice.Id || '')}>
                    {Number}
                </Option>
            )
        );
        return [...commonFilters];
    };
    /**
    * Function for populating the available credits options.
    */
    const populateAvailableCreditsSelectOptions = () => 
    filter(availableCreditList, (credit) => 
            !selectedCredits.some(selectedCredit => 
                                    selectedCredit.Number === credit.Number) && 
            credit.Type == 'Credit')
        .map(( {Number}: any ) => {
            return (
                <Option key={Number} value={Number}>
                    {Number}
                </Option>
            );
        });
       
     /**
    * Function for populating the available rebates options.
    */
     const populateAvailableRebatesSelectOptions = () => 
     filter(availableCreditList, (credit) => 
             !selectedRebates.some(selectedCredit => 
                                     selectedCredit.Number === credit.Number) && 
             credit.Type == 'Rebate')
         .map(( {Number}: any ) => {
             return (
                 <Option key={Number} value={Number}>
                     {Number}
                 </Option>
             );
         });

        
    /**
     * Function called when clicking the `Action item` button
     */
    const handleSubmitForm = () => {
           handleValidateForm();
           setIsReviewRemittanceAdvicePanelVisible(true);
    };

     
    /**
    * Function for calling when the ReviewCreatedNewPaymentPlanPanel is closed.
    * @param refreshList
    */
    const onReviewRemittanceAdviceClose = (isRASuccessfully?: boolean) => {
        setIsReviewRemittanceAdvicePanelVisible(false);

        if(isRASuccessfully) {
            if(closePanel) {
                closePanel(true);
            };
        }
    };

    /**
     * Function called when clicking the `Action item` button
     * Disregard Remittance Advice 
     */
    const handleDisregardTasksForm = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Warning',
            content: (
                <div>
                    Disregarding the task will close the remittance advice.
                    <div className="mt-8">
                        Would you like to continue?
                    </div>
                </div>
            ),
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
            onOk() {
                handleDisregardTasksValidateForm();
            }
        });
    };

    const handleValidateForm = () => {
        validateForm({
            success: (values) => {
                const paidDate = get(values, FORM_FIELDS.PAID_DATE);
                if (paidDate) {
                    set(values, FORM_FIELDS.PAID_DATE, moment(paidDate).format(
                        dateFormatYYYYMMDDDash
                    ));
                }
                const payload = {
                    ...values,
                    [FORM_FIELDS.INVOICES]: invoiceList,
                    [FORM_FIELDS.CREDITS]: creditList,
                    [FORM_FIELDS.REBATES]: rebateList,
                    Task: selectedTask
                };
                
                setRemittanceAdvicePayload(payload);
                setSubmitLoading(true);
                dispatch(
                    reviewRemittanceAdviceRequest(
                    {
                        ...payload,
                        callback: actionResponseModal
                    }
                    )
                );
            }
        })
    };

    const handleDisregardTasksValidateForm = () => {
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        const payload = {
            Task: selectedTask,
            AssignedUserId: selectedTask ? (selectedTask.AssignedUser ? selectedTask.AssignedUser.UserId : null) : null,
            CompanyId: selectedCompanyId
        };

        setSubmitDisregardLoading(true);
        dispatch(
            confirmRemittanceAdviceDisregardTasksRequest({
                ...payload,
                callback: disregardResponseModal
            })
        );
    };

    const getInvoiceRemainingAmount = useMemo(() => (invoice: Partial<RemittanceAdviceInvoiceVM>) => {
        const amountOwing = (invoice.AmountOwing || 0);
        const amount = (invoice.Amount || 0);
        const invoiceRemainingAmount = amountOwing - amount;

        return invoiceRemainingAmount;
    }, []);

    const getCreditRemainingAmount = useMemo(() => (credit: Partial<RemittanceAdviceCreditVM>) => {
        const allocatedCredit = (credit.Amount || 0);
        const creditAmount = (credit.CreditAmount || 0);
        const remainingAmount = creditAmount - allocatedCredit

        return remainingAmount;
    }, []);

    const validateInvoice = useMemo(() => (invoice: Partial<RemittanceAdviceInvoiceVM>) => {
        const validation: {
            valid: boolean,
            warning: string[]
        } = { valid: true, warning: [] };
        const { warning } = validation;

        const amount = invoice.Amount;
        const credits = creditList.filter(credit => credit.InvoiceId === (invoice.Invoice && invoice.Invoice.Id || ''));

        if (!invoice.Number) {
            validation.valid = false;
            warning.push('Invoice has no number!');
        } else if (filter(invoiceList, inv => inv.Number === invoice.Number).length > 1) {
            validation.valid = false;
            warning.push('Invoice duplicated!');
        }

        if(invoice.Amount && isNaN(invoice.Amount))
        {
            validation.valid = false;
            warning.push('Invoice amount format is incorrect!');
        }

        if (!credits || credits.length == 0) {
            if (!invoice.Amount || invoice.Amount <= 0) {
                validation.valid = false;
                warning.push('Invoice has no amount!');
            }
        }

        if (invoice.CustomerId
            && !getFieldValue(FORM_FIELDS.MULTIPLE_CUSTOMER)
            && getFieldValue(FORM_FIELDS.CUSTOMER_ID) !== invoice.CustomerId) {
            warning.push(`Invoice belongs to another ${toLower(customerLabel)}!`);
        }
        if (roundToDecimals(getInvoiceRemainingAmount(invoice) || 0) < 0 ) {
            validation.valid = false;
            warning.push('Invoice is over allocated!');
        }

        if(invoice.State !== 'Open' && invoice.Number) {
            validation.valid = false; 
            warning.push('Invoice has already been settled.');
        }

        return validation;
    }, [getFieldValue, invoiceList, getInvoiceRemainingAmount, creditList]);

    const validateCredit = useMemo(() => (credit: Partial<RemittanceAdviceCreditVM>) => {
        const validation: {
            valid: boolean,
            warning: string[]
        } = { valid: true, warning: [] };
        const { warning } = validation;
        const invoiceExist = invoiceList.find(invoice => (invoice.Invoice && invoice.Invoice.Id || '') === credit.InvoiceId);

        if (!credit.Number) {
            validation.valid = false;
            warning.push('Credit has no number!');
        }

        if (!credit.Amount || credit.Amount <= 0) {
            validation.valid = false;
            warning.push('Credit has no amount!');
        }

        if (credit.Amount && isNaN(credit.Amount)) {
            validation.valid = false;
            warning.push('Credit amount format is incorrect!');
        }

        if (filter(creditList, inv => inv.Number === credit.Number).length > 1) {
            validation.valid = false;
            warning.push('Credit duplicated!');
        }

        if (credit.CustomerId
            && !getFieldValue(FORM_FIELDS.MULTIPLE_CUSTOMER)
            && getFieldValue(FORM_FIELDS.CUSTOMER_ID) !== credit.CustomerId) {
            validation.valid = false;
            warning.push('Credit belongs to another customer!');
        }

        if (!credit.InvoiceId || invoiceExist == undefined) {
            warning.push('Credit does not have an associated invoice!');
        }

        if (roundToDecimals(getCreditRemainingAmount(credit) || 0) < 0) {
            validation.valid = false;
            warning.push('Credit is over allocated!');
        }

        if(credit.State !== 'Open' && credit.Number) {
            validation.valid = false; 
            warning.push('Credit has already been used.');
        }

        return validation;
    }, [getFieldValue, creditList, getCreditRemainingAmount]);

    const validateRebate = useMemo(() => (rebate: Partial<RemittanceAdviceRebateVM>) => {
        const validation: {
            valid: boolean,
            warning: string[]
        } = { valid: true, warning: [] };
        const { warning } = validation;

        if (!rebate.Number) {
            validation.valid = false;
            warning.push('Rebate has no number!');
        }

        if (!rebate.Amount || rebate.Amount <= 0) {
            validation.valid = false;
            warning.push('Rebate has no amount!');
        }

        if (rebate.Amount && isNaN(rebate.Amount)) {
            validation.valid = false;
            warning.push('Rebate amount format is incorrect!');
        }

        if (filter(rebateList, inv => inv.Number === rebate.Number).length > 1) {
            validation.valid = false;
            warning.push('Rebate duplicated!');
        }

        if (rebate.CustomerId
            && !getFieldValue(FORM_FIELDS.MULTIPLE_CUSTOMER)
            && (getFieldValue(FORM_FIELDS.CUSTOMER_ID) !== rebate.CustomerId)) {
            validation.valid = false;
            warning.push('Rebate belongs to another customer!');
        }

        if (rebate.Percentage 
            && (!rebate.AmountToAllocate || rebate.AmountToAllocate <= 0)) {
                validation.valid = false;
                warning.push('Rebate needs an amount to allocate!');
            }

        if(rebate.State !== 'Open' && rebate.Number) {
                validation.valid = false; 
                warning.push('Rebate has already been used.');
            }
    

        return validation;
    }, [getFieldValue, rebateList]);

    const validateForm = useMemo(() => (options?: {
        success?: (values: any) => void
    }) => {
        validateFields([FORM_FIELDS.AMOUNT], { force: true }, (err: any, values: DynamicObject) => {
            if (err) {
                setHasError(true);
            }
        });
        validateFields((err: any, values: DynamicObject) => {
            const validation = {
                valid: true,
                errors: {
                    ...(err || {})
                }
            };

            const validInvoices = invoiceList.length > 0 ? every(invoiceList, inv => validateInvoice(inv).valid) : true;
            const validCredits = creditList.length > 0 ? every(creditList, cred => validateCredit(cred).valid) : true;
            const validRebates = rebateList.length > 0 ? every(rebateList, reb => validateRebate(reb).valid) : true;

            const paidAmount = get(values, FORM_FIELDS.AMOUNT);
            const invoicePaidAmount = parseFloat(invoiceList.reduce((partial, invoice) => partial + (invoice.Amount || 0), 0).toString());
            const creditUsedAmount = parseFloat(creditList.reduce((partial, credit) => partial + (credit.Amount || 0), 0).toString());
            const rebateAmount = parseFloat(rebateList.reduce((partial, rebate) => partial + (rebate.AmountToAllocate || rebate.Amount ||  0), 0).toString());

            const TotalAmountOwing = sumBy(invoiceList, inv => inv.AmountOwing || 0);
            const TotalCreditAllocated = sumBy(creditList, cre => cre.Amount || 0 );
            const TotalRebateAllocated = sumBy(rebateList, reb => reb.AmountToAllocate || reb.Amount || 0);

            if (TotalAmountOwing > 0) {
                if((TotalCreditAllocated + TotalRebateAllocated) >= TotalAmountOwing){
                    validation.valid = false; 
                    validation.errors[FORM_FIELDS.INVOICES] = 'Cannot confirm remittance advice as credits/rebates will fully allocate the invoices!'
                }
            }
            
            setValidationState(validation);

            if (validation.valid) {
                const totalAmount = (parseFloat(invoicePaidAmount.toFixed(2)) - (parseFloat(creditUsedAmount.toFixed(2)) + parseFloat(rebateAmount.toFixed(2)))).toFixed(2)
                if (!err && validInvoices && (paidAmount >= totalAmount) && validCredits && invoiceList.length != 0 && validRebates) {
                        setHasError(false);
                        if (options && options.success) options.success(values);
                } else {
                    setHasError(true);
                }
            }
            else {
                setHasError(true);
            }
        });
    }, [validateFields, invoiceList, validateInvoice, validateCredit, creditList, validateRebate, rebateList]);

    const changeInvoiceList = useMemo(() => (value: React.SetStateAction<Partial<RemittanceAdviceInvoiceVM>[]>) => {
        setInvoiceList(value);
        setValueChanges({
            [FORM_FIELDS.INVOICES]: true
        });
    }, [setValueChanges]);

    const changeCreditList = useMemo(() => (value: React.SetStateAction<Partial<RemittanceAdviceCreditVM>[]>) => {
        setCreditList(value);
        setSelectedCredits(value);
        setValueChanges({
            [FORM_FIELDS.CREDITS]: true,
            [FORM_FIELDS.AVAILABLE_CREDITS]: true
        });
    }, [setValueChanges]);

    const changeRebateList = useMemo(() => (value: React.SetStateAction<Partial<RemittanceAdviceRebateVM>[]>) => {
        setRebateList(value);
        setSelectedRebates(value);
        setValueChanges({
            [FORM_FIELDS.REBATES]: true,
            [FORM_FIELDS.AVAILABLE_REBATES]: true
        });
    }, [setValueChanges]);

    const handleDeleteInvoice = useCallback((invoice: RemittanceAdviceInvoiceVM) => () => {
        changeInvoiceList(list => {
            const invoiceIdx = findIndex(list, inv => inv === invoice);
            if (invoiceIdx !== -1) {
                list.splice(invoiceIdx, 1);
            }
            return [...list];
        })
    }, [changeInvoiceList]);

    const handleDeleteCredit = useCallback((credit: RemittanceAdviceCreditVM) => () => {
        changeCreditList(list => {
            const creditIdx = findIndex(list, inv => inv === credit);
            if (creditIdx !== -1) {
                list.splice(creditIdx, 1);
            }
            return [...list];
        })

    }, [changeCreditList, setSelectedCredits]);

    const handleDeleteRebate = useCallback((rebate: RemittanceAdviceRebateVM) => () => {
        changeRebateList(list => {
            const rebateIdx = findIndex(list, inv => inv === rebate);
            if (rebateIdx !== -1) {
                list.splice(rebateIdx, 1);
            }
            return [...list];
        })

    }, [changeRebateList, setSelectedRebates]);

    const handleAddInvoice = () => {
        changeInvoiceList(list => {
            return [...list, {}];
        })
    };

    const handleAddCredit = () => {
        changeCreditList(list => {
            list.push()
            return [...list, {}];
        })

        setSelectedCredits(list => {
            list.push()
            return [...list,{}];
        })
    };


    const handleAddRebate = () => {
        changeRebateList(list => {
            list.push()
            return [...list, {}];
        })

        setSelectedRebates(list => {
            list.push()
            return [...list,{}];
        })
    };
    
    
    const closeCreateCredits = () => setCreatingCredits(false);

    const handleCreateCreditsClick = () => {
        setCreatingCredits(true);
    };

    const handleCreateCredits = (credits: Partial<CreditVM>[]) => {
        if (!isEmpty(credits)) {
            const creditCodes = credits.map(credit => credit.CreditCode)

            let payload: DynamicObject = {
                CreditCodes: creditCodes
            };

            dispatch(
                getCreditsByCreditCodesRequestAction(
                    payload,
                    (newCredits: Credit[]) => {
                        const credit: Partial<RemittanceAdviceCreditVM>[] =
                        newCredits.filter(cre => cre.Type === "Credit")
                        .map(cred => ({
                            Credit: cred,
                            CustomerId: cred.Customer.Id,
                            Number: cred.Number,
                            CreditAmount: cred.AmountAvailable,
                            Amount: cred.AmountAvailable,
                            State: cred.State
                        })) as RemittanceAdviceCreditVM[];

                        const rebate: Partial<RemittanceAdviceRebateVM>[] =
                        newCredits.filter(reb => reb.Type === "Rebate")
                        .map(reb => ({
                            Rebate: reb,
                            CustomerId: reb.Customer.Id,
                            Number: reb.Number,
                            Amount: !reb.Percentage ? reb.AmountAvailable : reb.Percentage,
                            Percentage: reb.Percentage, 
                            State: reb.State
                            // CalculatePercentage: reb.Percentage ? true : false
                        })) as RemittanceAdviceRebateVM[];

                         setCreditList(prevCreditList => [...prevCreditList, ...credit]);
                         setValueChanges({
                            [FORM_FIELDS.CREDITS]: true
                        });
                         setSelectedCredits(prevSelectedCredits => [...prevSelectedCredits, ...credit]);
                         
                         setRebateList(prevRebateList => [...prevRebateList, ...rebate]);
                         setValueChanges({
                            [FORM_FIELDS.REBATES]: true
                        });
                         setSelectedRebates(prevSelectedCredits => [...prevSelectedCredits, ...rebate]);
                    }
                )
            );

            fetchCredits({});
        }
    };

    /**
     * Function for populating the response modal when completing action.
     * Either success/error.
     */
    const actionResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Reviewed remittance advice successfully!',
            });
        } else {
            let errorMessageContent: any = `Failed to review remittance advice!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }
            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }
    };


    /**
     * Function for populating the response modal when completing action.
     * Either success/error.
     */
    const disregardResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitDisregardLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Remittance advice has been disregarded successfully!',
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to disregard remittance advice!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }
            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }   
    };

    const updateInvoice = (invoice: Partial<RemittanceAdviceInvoiceVM>, newInvoice: Partial<RemittanceAdviceInvoiceVM>) => {
        changeInvoiceList(list => {
            const updatedInvoiceIdx = findIndex(list, inv => inv === invoice);
            if (updatedInvoiceIdx !== -1) {
                newInvoice._touched = true;
                list.splice(updatedInvoiceIdx, 1, newInvoice);
            }
            return [...list];
        });
    };

    const updateCredit = (credit: Partial<RemittanceAdviceCreditVM>, newCredit: Partial<RemittanceAdviceCreditVM>) => {
        changeCreditList(list => {
            const updatedCreditIdx = findIndex(list, inv => inv === credit);
            if (updatedCreditIdx !== -1) {
                newCredit._touched = true;
                list.splice(updatedCreditIdx, 1, newCredit);
            }
            return [...list];
        });
    };

    const updateRebate = (rebate: Partial<RemittanceAdviceRebateVM>, newRebate: Partial<RemittanceAdviceRebateVM>) => {
        changeRebateList(list => {
            const updatedCreditIdx = findIndex(list, inv => inv === rebate);
            if (updatedCreditIdx !== -1) {
                newRebate._touched = true;
                list.splice(updatedCreditIdx, 1, newRebate);
            }
            return [...list];
        });
    };

    useEffect(listenForClosingPanel, [visible]);

    useEffect(() => {
        if (remittanceAdvice) {
            const CustomerId = get(remittanceAdvice, 'Customer.Id');
            const CustomerName = get(remittanceAdvice, 'Customer.DisplayName');
            setValueChanges({
                [FORM_FIELDS._ALL]: true
            });
            setCustomerSearch(CustomerName);
            setLastCustomer({
                CustomerId,
                CustomerName
            });
        }
    }, [remittanceAdvice]);

    useEffect(() => {
        if (!isEmpty(valueChanges)) {
            validateForm();
        }
    }, [valueChanges, validateForm]);

    useEffect(() => {
        dispatch(getRemittanceAdviceDetails(RemittanceAdviceId));
    }, [RemittanceAdviceId, dispatch]);

    useEffect(() => {
        const Attachments = get(remittanceAdvice, 'Attachments');
        if (Attachments && Attachments[attachmentIdx]) {
            dispatch(
                getNotificationLetterPresignedUrlAction(
                    Attachments[attachmentIdx].FileId,
                    (response: any) => {
                        if (response.IsSuccess) {
                            const fileURL = get(
                                response,
                                'data.GetFileLocation'
                            );
                            if (fileURL && fileURL !== 'null') {
                                setAttachmentUrl(fileURL);
                            } else {
                                Modal.error({
                                    title: 'Error generating link',
                                    content: 'File Id might be invalid',
                                    getContainer: () => getPopoverContainer(containerRef),
                                });
                            }
                        }
                    }
                )
            );
        }
    }, [remittanceAdvice, attachmentIdx, dispatch, containerRef]);

    useEffect(() => {
        const RemittanceAdviceInvoices = get(remittanceAdvice, 'RemittanceAdviceInvoices');
        if (RemittanceAdviceInvoices) {
            const invoices = RemittanceAdviceInvoices.map(inv => ({
                CustomerId: inv.Invoice.Customer.Id,
                Number: inv.Invoice.Number,
                AmountOwing: inv.Invoice.AmountOwing,
                Amount: inv.Amount,
                Invoice: {
                    ...inv.Invoice,
                    Id: inv.Invoice.Id
                },
                State: inv.Invoice.State
            }));
            setInvoiceList(filter(invoices, inv => !!inv));
        }

        const RemittanceAdviceCredits = get(remittanceAdvice, 'RemittanceAdviceCredits');
        if (RemittanceAdviceCredits) {
            const credits = RemittanceAdviceCredits.map(cre => ({
                CustomerId: cre.Credit.Customer.Id,
                Number: cre.Credit.Number,
                CreditAmount: cre.Credit.AmountAvailable,
                Amount: cre.Amount,
                Credit: {
                    ...cre.Credit,
                    Id: cre.Credit.Id
                },
                InvoiceId: cre.InvoiceId,
                State: cre.Credit.State
            }));
            setCreditList(filter(credits, cre => !!cre));
            setValueChanges({
                [FORM_FIELDS.CREDITS]: true
            });

           setSelectedCredits(filter(credits, cre => !!cre));
        }

        const RemittanceAdviceRebates = get(remittanceAdvice, 'RemittanceAdviceRebates');
        if (RemittanceAdviceRebates) {
            console.log(RemittanceAdviceRebates);
            const rebates = RemittanceAdviceRebates.map(reb => ({
                CustomerId: reb.Rebate.Customer.Id,
                Number: reb.Rebate.Number,
                Amount: reb.Rebate.Percentage? reb.Rebate.Percentage : reb.Rebate.AmountAvailable,
                Rebate: {
                    ...reb.Rebate,
                    Id: reb.Rebate.Id},
                RebateId: reb.Rebate.Id,
                Percentage: reb.Rebate.Percentage,
                AmountToAllocate: reb.Rebate.Percentage ? reb.AmountToAllocate : 0,
                State: reb.Rebate.State

            }));
            setRebateList(filter(rebates, reb => !!reb));
            setValueChanges({
                [FORM_FIELDS.REBATES]: true
            });

           setSelectedRebates(filter(rebates, reb => !!reb));
        }

    }, [remittanceAdvice, dispatch, containerRef]);

    const generatePayloadForRequest = useMemo(() => (filter: FetchCreditsFilter) => {
        let CustomerId =  getFieldValue(FORM_FIELDS.CUSTOMER_ID);
  
        if (remittanceAdvice) {
            CustomerId = remittanceAdvice.Customer ? remittanceAdvice.Customer.Id : getFieldValue(FORM_FIELDS.CUSTOMER_ID);
        }

        const payload: GetCreditsRequestPayload = {
            filters: {
                CustomerId
            } as any,
            sortAscending: true,
            pageSize: 100,
            currentPage: 0,
            creditState: 'Open',
            sortBy: 'Credit Number'
        };
        return payload;
    }, [selectedUserCompany, FORM_FIELDS.CUSTOMER_ID, remittanceAdvice, FORM_FIELDS.MULTIPLE_CUSTOMER]);

    const fetchCredits = useMemo(() => (filter: FetchCreditsFilter) => {
        if (!fetchingCredits) {
                const payload = generatePayloadForRequest(filter);
                dispatch(getCreditsRequestAction(payload));
        }
    }, [fetchingCredits, generatePayloadForRequest, dispatch, lastCustomer]);

    useEffect(() => {
        if(remittanceAdvice){
            fetchCredits({});
        }        
    }, [remittanceAdvice]);

    useEffect(() => {
         setSaveBtnDisabled(getDetailsLoading || submitLoading || hasError);
    }, [getDetailsLoading, submitLoading, hasError]); 

    const isDisregardTaskBtnDisabled = getDetailsLoading || submitDisregardLoading;

    const { currency: RemittanceAdviceCurrency } = handleFormatCurrency(get(remittanceAdvice, 'Amount') || 0);

    const populatePreviewSection = () => {
           if (attachmentUrl) {
            const downloadSection = <div style={{ textAlign: 'right' }}>
                <Button
                    type="link"
                    onClick={() => openURLNewTab(attachmentUrl)}
                    style={{ padding: 0 }}
                >
                    <FontAwesome
                        icon={['fa', 'file-download']}
                    />
                    &nbsp;
                    <span>Download</span>
                </Button>
            </div>;
            let previewSection: React.ReactNode;

            const url = new URL(attachmentUrl);
            const lastDotIdx = url.pathname.lastIndexOf('.');
            const extension = url.pathname.substring(lastDotIdx);
            const isExcel = includes(excelExtensions, extension);
            const isCsv = includes(csvExtensions, extension);

            if (isExcel || isCsv) {
                previewSection = <SheetViewer
                    id={RA_SHEET_VIEWER_ID}
                    url={attachmentUrl}
                    style={{
                        height: 700,
                        width: '100%'
                    }}
                    isCsv={isCsv}
                />;
            } else {
                previewSection = <IFrameWithLoading
                    title="Remittance Advice"
                    iframeURL={attachmentUrl || ''}
                    loading={!attachmentUrl}
                    height="700"
                />;
            }

            return <>
                {downloadSection}
                {previewSection}
            </>
        } else return <>Nothing to view</>;
    };

    /**
     * Function to populate the loading content.
     * Shows a loading skeleton if the content is being populated (API not finished fetching data for action options).
     */
    const populatePanelContent = () => {
        if (getDetailsLoading) {
            return (
                <>
                    {times(2, (key: number) => {
                        return (
                            <div key={key}>
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 2 }}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {
            const { PaidDate, MultipleCustomer, Amount, Customer } = remittanceAdvice || {} as any;
            const initialValues = {
                [FORM_FIELDS.CUSTOMER_ID]: get(Customer, 'Id'),
                [FORM_FIELDS.MULTIPLE_CUSTOMER]: MultipleCustomer,
                [FORM_FIELDS.PAID_DATE]: PaidDate && moment(PaidDate),
                [FORM_FIELDS.AMOUNT]: Amount
            };
            const invoicePaidAmount = invoiceList.reduce((partial, invoice) => partial + (invoice.Amount || 0), 0);
            const creditUsedAmount = creditList.reduce((partial, credit) => partial + (credit.Amount || 0), 0);
            const rebateAmount = rebateList.reduce((partial, rebate) => partial + (rebate.AmountToAllocate || rebate.Amount || 0), 0);

            return (
                <>
                    <Row className="mb-10">
                        <Col span={24}>
                            Confirm the information on the Remittance Advice then click Save
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="mb-10">
                        <Col span={10} style={{ textAlign: 'center' }}>
                            {totalAttachments && totalAttachments > 1
                                ? <Pagination className="mb-10" current={attachmentIdx + 1} pageSize={1}
                                    total={totalAttachments}
                                    onChange={(page) => setAttachmentIdx(page - 1)}
                                />
                                : undefined}
                            {populatePreviewSection()}
                        </Col>
                        <Col span={14}>
                            <Form labelAlign='left' className="form-inline-mb-8" {...formItemLayout}>
                                <Row gutter={[16, 16]} type="flex" align="middle">
                                    <Col span={15}>
                                        <FormItem label={capitalize(customerLabel)}>
                                            {getFieldDecorator(FORM_FIELDS.CUSTOMER_ID, {
                                                initialValue: get(initialValues, FORM_FIELDS.CUSTOMER_ID),
                                                rules: []
                                            })(
                                                <Input hidden />
                                            )}
                                            <InputAutoCompleteWithButton
                                                updateField={(value: any) => {
                                                    if (customerSearch !== value && getFieldValue(FORM_FIELDS.CUSTOMER_ID)) {
                                                        setFieldsValue({
                                                            [FORM_FIELDS.CUSTOMER_ID]: undefined,
                                                            [FORM_FIELDS.MULTIPLE_CUSTOMER]: getFieldValue(FORM_FIELDS.MULTIPLE_CUSTOMER)
                                                        });
                                                    }
                                                    setCustomerSearch(value);
                                                }}
                                                onSelect={(value: any) => {
                                                    const CustomerId = get(value, 'Id');
                                                    const CustomerName = get(value, 'DisplayName');
                                                    setLastCustomer({
                                                        CustomerId,
                                                        CustomerName
                                                    });
                                                    setFieldsValue({
                                                        [FORM_FIELDS.CUSTOMER_ID]: CustomerId,
                                                        [FORM_FIELDS.MULTIPLE_CUSTOMER]: getFieldValue(FORM_FIELDS.MULTIPLE_CUSTOMER)
                                                    });

                                                    fetchCredits({});
                                                }}
                                                minimumLength={1}
                                                stateValue={customerSearch}
                                                placeholder={`Search ${toLower(customerLabel)}`}
                                                keyField={'Id'}
                                                queryName={'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                                                filterField={'Customer'}
                                                queryFilterName={'Customer'}
                                                sortField={'Company name'}
                                                responseName={'GetCustomersForCompany.Customers'}
                                                labelField={'DisplayName'}
                                                hasNoOkButton
                                                loading={false}
                                                readOnly={getFieldValue(FORM_FIELDS.MULTIPLE_CUSTOMER)}
                                                hasNoContainerRef
                                            />
                                        </FormItem>
                                        <FormItem wrapperCol={{ span: 24 }}>
                                            {getFieldDecorator(FORM_FIELDS.MULTIPLE_CUSTOMER, {
                                                valuePropName: 'checked',
                                                initialValue: get(initialValues, FORM_FIELDS.MULTIPLE_CUSTOMER),
                                                rules: [{
                                                    validator: async (_rule: any, value: any) => {
                                                        const MultipleCustomer =
                                                            value || getFieldValue(FORM_FIELDS.MULTIPLE_CUSTOMER);
                                                        const CustomerId = getFieldValue(FORM_FIELDS.CUSTOMER_ID);
                                                        if (!MultipleCustomer && !CustomerId) {
                                                            throw new Error(`At least one ${toLower(customerLabel)} or multiple ${toLower(customerLabel)}s checkbox required!`);
                                                        }
                                                    }
                                                }],
                                                onChange: (event: any) => {
                                                    const checked = get(event, 'target.checked');
                                                    if (checked) {
                                                        setFieldsValue({
                                                            [FORM_FIELDS.CUSTOMER_ID]: undefined
                                                        });
                                                        setCustomerSearch('');
                                                    } else {
                                                        setFieldsValue({
                                                            [FORM_FIELDS.CUSTOMER_ID]: lastCustomer.CustomerId
                                                        });
                                                        setCustomerSearch(lastCustomer.CustomerName);
                                                    }
                                                    fetchCredits({});
                                                }
                                            })(
                                                <Checkbox>Remittance Advice is for multiple {toLower(customerLabel)}s</Checkbox>
                                            )}
                                        </FormItem>
                                        <FormItem label="Paid on" required={false}>
                                            {getFieldDecorator(FORM_FIELDS.PAID_DATE, {
                                                rules: [{
                                                    required: true,
                                                    message: 'Paid date required!'
                                                }],
                                                initialValue: get(initialValues, FORM_FIELDS.PAID_DATE)
                                            })(
                                                <DatePicker
                                                    format={dateFormatDDMMMYYYYSpace}
                                                    placeholder="Choose a date"
                                                />
                                            )}
                                        </FormItem>
                                        <FormItem label="Paid amount">
                                            {`${RemittanceAdviceCurrency} `}
                                            {getFieldDecorator(FORM_FIELDS.AMOUNT, {
                                                rules: [{
                                                    validator: async (_rule: any, value: any) => {
                                                        const invoicePaidAmountNumber = parseFloat(invoicePaidAmount.toString());
                                                        const creditUsedAmountNumber = parseFloat(creditUsedAmount.toString());
                                                        const rebateAmountNumber = parseFloat(rebateAmount.toString());

                                                        if (isNaN(invoicePaidAmountNumber) || isNaN(creditUsedAmountNumber) || isNaN(rebateAmountNumber)) {
                                                            throw new Error('Allocated amount is an invalid decimal');
                                                        }

                                                        if (!value || value <= 0) {
                                                            throw new Error('Paid amount required!');
                                                        }

                                                        const totalAmount = (parseFloat(invoicePaidAmountNumber.toFixed(2)) - (parseFloat(creditUsedAmountNumber.toFixed(2)) + parseFloat(rebateAmountNumber.toFixed(2)))).toFixed(2);
                                                        if (value < totalAmount) {
                                                          
                                                            throw new Error('Total allocated amount is greater than paid amount!');
                                                        }
                                                    }
                                                }],
                                                initialValue: get(initialValues, FORM_FIELDS.AMOUNT)
                                            })(
                                                <InputNumber
                                                    className="mr-5"
                                                    precision={2}
                                                    step={0.01}
                                                    min={0}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            className="app-ra-invoices-table"
                                            columns={invoicesTableColumns}
                                            dataSource={map(invoiceList, populateInvoiceTableDataSourceItem)}
                                            pagination={false}
                                            bordered={true}
                                        />
                                    </Col>
                                    <Col span={24} className="ta-right">
                                        <Button
                                            type="link"
                                            onClick={handleAddInvoice}
                                            style={{ padding: 0 }}
                                        >
                                            <FontAwesome
                                                icon={['fa', 'plus']}
                                                className="mr-8"
                                            />
                                            <span>Add another invoice</span>
                                        </Button>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem label="Available credits" required={false}>
                                            {getFieldDecorator(FORM_FIELDS.AVAILABLE_CREDITS, {
                                                initialValue: get(initialValues, FORM_FIELDS.AVAILABLE_CREDITS) || ''
                                            })(
                                                <SelectReadonly
                                                    className="app-ra-available-credits-dropdown"
                                                    placeholder="Select Credits"
                                                    onSelect={(value: any) => {
                                                        const selectedCredit = (!isUndefined(availableCreditList) && availableCreditList.find(credit => credit.Number === value));
                                                        if(selectedCredit){
                                                            const CustomerId = get(selectedCredit, 'Customer.Id');
                                                            const Number = get(selectedCredit, 'Number');
                                                            const AmountAvailable = get(selectedCredit, 'AmountAvailable');
                                                
                                                            const newCredit: Partial<RemittanceAdviceCreditVM> = {
                                                                ...selectedCredit,
                                                                Credit: {
                                                                    ...selectedCredit
                                                                },
                                                                CustomerId,
                                                                Number,
                                                                CreditAmount: AmountAvailable,
                                                                Amount: AmountAvailable
                                                            };
                                                
                                                           setCreditList(prevCreditList => [...prevCreditList, newCredit]);
                                                            setValueChanges({
                                                                [FORM_FIELDS.CREDITS]: true
                                                            });

                                                           setSelectedCredits(prevSelectedCredits => [...prevSelectedCredits, newCredit]);
                                                        }
                                                    }}
                                                >
                                                    {populateAvailableCreditsSelectOptions()}
                                                </SelectReadonly>
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={24} className="ta-right" >
                                        <Button
                                            className="mr-8"
                                            type="primary"
                                            onClick={handleCreateCreditsClick}
                                        >
                                            Create credits/rebates
                                        </Button>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            className="app-ra-credits-table"
                                            columns={creditsTableColumns}
                                            dataSource={map(creditList, populateCreditTableDataSourceItem)}
                                            pagination={false}
                                            bordered={true}
                                        />
                                    </Col>
                                    <Col span={24} className="ta-right">
                                        <Button
                                            type="link"
                                            onClick={handleAddCredit}
                                            style={{ padding: 0 }}
                                        >
                                            <FontAwesome
                                                icon={['fa', 'plus']}
                                                className="mr-8"
                                            />
                                            <span>Add another credit</span>
                                        </Button>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem label="Available rebates" required={false}>
                                            {getFieldDecorator(FORM_FIELDS.AVAILABLE_REBATES, {
                                                initialValue: get(initialValues, FORM_FIELDS.AVAILABLE_REBATES) || ''
                                            })(
                                                <SelectReadonly
                                                    className="app-ra-available-rebates-dropdown"
                                                    placeholder="Select Rebates"
                                                    onSelect={(value: any) => {
                                                        const selectedRebates = (!isUndefined(availableCreditList) && availableCreditList.find(credit => credit.Number === value));
                                                        if(selectedRebates){
                                                            const CustomerId = get(selectedRebates, 'Customer.Id');
                                                            const Number = get(selectedRebates, 'Number');
                                                            const Amount = !get(selectedRebates, 'Percentage') ? get(selectedRebates, 'AmountAvailable'): get(selectedRebates, 'Percentage');
                                                            // const isPercentage = get(selectedRebates, 'Percentage') ? true : false;
                                                            const Percentage = get(selectedRebates, 'Percentage') 
                                                            const newRebate: Partial<RemittanceAdviceRebateVM> = {
                                                                ...selectedRebates,
                                                                Rebate: {
                                                                    ...selectedRebates
                                                                },
                                                                CustomerId,
                                                                Number,
                                                                Amount,
                                                                // CalculatePercentage: isPercentage,
                                                                Percentage
                                                            };
                                                
                                                           setRebateList(prevCreditList => [...prevCreditList, newRebate]);
                                                            setValueChanges({
                                                                [FORM_FIELDS.REBATES]: true
                                                            });

                                                           setSelectedRebates(prevSelectedCredits => [...prevSelectedCredits, newRebate]);

                                                        }
                                                    }}
                                                >
                                                    {populateAvailableRebatesSelectOptions()}
                                                </SelectReadonly>
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            className="app-ra-rebates-table"
                                            columns={rebatesTableColumns}
                                            dataSource={map(rebateList, populateRebatesTableDataSourceItem)}
                                            pagination={false}
                                            bordered={true}
                                        />
                                    </Col>
                                    <Col span={24} className="ta-right">
                                        <Button
                                            type="link"
                                            onClick={handleAddRebate}
                                            style={{ padding: 0 }}
                                        >
                                            <FontAwesome
                                                icon={['fa', 'plus']}
                                                className="mr-8"
                                            />
                                            <span>Add another rebate</span>
                                        </Button>
                                    </Col>
                                    <Col span={24} className="ta-right">
                                        <div style={{ color: DANGER_COLOR }}>{validationState.errors[FORM_FIELDS.INVOICES]}</div>
                                    </Col>
                                    <Col span={24} className="ta-right" >
                                        <Button
                                            className="mr-8"
                                            type="primary"
                                            onClick={handleDisregardTasksForm}
                                            disabled={isDisregardTaskBtnDisabled}
                                        >
                                            Disregard
                                        </Button>
                                        <Button
                                            className="mr-8"
                                            type="primary"
                                            onClick={handleSubmitForm}
                                            disabled={saveBtnDisabled}
                                        >
                                            Next
                                        </Button>
                                        <Button onClick={handleClosePanel}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </>
            );
        }
    };

    return  <>
        <Row>
            <Col className="dropdown-multiline-cont">
                <div>{populatePanelContent()}</div>
            </Col>
            {submitLoading && (
                <ModalWithSpinner
                    modalTitle="Confirming remittance advice"
                    modalVisible={submitLoading}
                    displayMessage="Please wait while confirming the remittance advice . . ."
                />
            )}
            {submitDisregardLoading && (
                <ModalWithSpinner
                    modalTitle="Disregarding remittance advice"
                    modalVisible={submitDisregardLoading}
                    displayMessage="Please wait while disregarding the remittance advice . . ."
                />
            )}
        </Row>

        <CreateCreditManuallyDrawer
            visible={creatingCredits}
            closePanel={closeCreateCredits}
            onCreateSuccess={handleCreateCredits}
            dispatchAction={createCreditManuallyRequestAction}
            disableCustomer={!!get(remittanceAdvice, 'Customer')}
            remittanceAdvice={remittanceAdvice}
        />

        <ReviewRemittanceAdvicePanel
            visible={isReviewRemittanceAdvicePanelVisible}
            remittanceAdvice={remittanceAdvicePayload}
            closePanel={onReviewRemittanceAdviceClose}
            formatCurrency={formatCurrency}
            formatToParts={formatToParts}
            dispatchAction={confirmRemittanceAdviceRequest}
        />
    </>;
};

const TaskActionRemittanceAdvicePanelContentWrapper: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const TaskActionRemittanceAdvicePanelContentForm = Form.create({
            name: 'task-confirm-remittance-advice-panel-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(TaskActionRemittanceAdvicePanelContent);

        return withRouter(
            withNumberFormatHandler(
                withDateFormatHandler(TaskActionRemittanceAdvicePanelContentForm)
            )
        );
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default TaskActionRemittanceAdvicePanelContentWrapper;

const InvoiceAutoComplete: React.FC<{
    invoice: RemittanceAdviceInvoiceVM,
    customerId?: string,
    updateInvoice: (invoice: Partial<RemittanceAdviceInvoiceVM>, newInvoice: Partial<RemittanceAdviceInvoiceVM>) => void
}> = ({ invoice, customerId, updateInvoice }) => {
    useEffect(() => {
        if (invoice && !invoice.SearchValue) {
            invoice.SearchValue = invoice.Number;
        }
    }, [invoice]);

    return <InputAutoCompleteWithButton
        updateField={(value: any) => {
            if (invoice.Number && invoice.Number !== value) {
                const newInvoice: Partial<RemittanceAdviceInvoiceVM> = {
                    ...invoice,
                    Number: '',
                    CustomerId: undefined,
                    Amount: 0,
                    AmountOwing: 0,
                    State: ''
                };
                invoice.SearchValue = value;
                updateInvoice(invoice, newInvoice);
            }
        }}
        onSelect={(value: any) => {
            const InvoiceId = get(value, 'Id');
            const CustomerId = get(value, 'Customer.Id');
            const Number = get(value, 'Number');
            const AmountOwing = get(value, 'AmountOwing');
            const State = get(value, 'State');
            const newInvoice = {
                ...invoice,
                Invoice: {
                    ...invoice.Invoice,
                    Id: InvoiceId
                },
                CustomerId,
                Number,
                AmountOwing:  AmountOwing || 0,
                Amount: AmountOwing || 0,
                State: State
            };
            updateInvoice(invoice, newInvoice);
        }}
        minimumLength={1}
        placeholder="Search invoice"
        stateValue={invoice.SearchValue}
        keyField={'Id'}
        queryName={'GET_INVOICES_DETAILS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
        filterField={'InvoiceNumber'}
        queryFilterName={'InvoiceNumber'}
        sortField={'Invoice number'}
        responseName={'GetInvoicesForCompany.Invoices'}
        labelField={'Number'}
        hasNoOkButton
        loading={false}
        hasNoContainerRef
        extraQueryVariables={{
            InvoiceState: invoiceStateMap.OPEN
        }}
    />;
}

const CreditAutoComplete: React.FC<{
    credit: RemittanceAdviceCreditVM,
    customerId?: string,
    updateCredit: (credit: Partial<RemittanceAdviceCreditVM>, newCredit: Partial<RemittanceAdviceCreditVM>) => void
}> = ({ credit, customerId, updateCredit }) => {
    useEffect(() => {
        if (credit && !credit.SearchValue) {
            credit.SearchValue = credit.Number;
        }
    }, [credit]);

    return <InputAutoCompleteWithButton
        updateField={(value: any) => {
            if (credit.Number && credit.Number !== value) {
                const newCredit: Partial<RemittanceAdviceCreditVM> = {
                    ...credit,
                    Number: '',
                    CustomerId: customerId,
                    Amount: 0,
                    CreditAmount: 0,
                    State: '',
                };
                credit.SearchValue = value;
                updateCredit(credit, newCredit);
            }
        }}
        onSelect={(value: any) => {
            const CreditId = get(value, 'Id');
            const CustomerId = get(value, 'Customer.Id');
            const Number = get(value, 'Number');
            const AmountAvailable = get(value, 'AmountAvailable');
            const State = get(value, 'State');
            const newCredit = {
                ...credit,
                Credit: {
                    ...credit.Credit,
                    Id: CreditId
                },
                CustomerId,
                Number,
                CreditAmount: AmountAvailable ? AmountAvailable : 0,
                Amount : AmountAvailable ? AmountAvailable : 0,
                State: State,
            };
            updateCredit(credit, newCredit);
        }}
        minimumLength={1}
        placeholder="Search credits"
        stateValue={credit.SearchValue}
        keyField={'Id'}
        queryName={'GET_CREDITS_FOR_COMPANY'}
        filterField={'CreditNumber'}
        queryFilterName={'CreditNumber'}
        sortField={'Credit number'}
        responseName={'GetCreditsForCompany.Credits'}
        labelField={'Number'}
        hasNoOkButton
        loading={false}
        hasNoContainerRef
        extraQueryVariables={{
            CreditState: 'Open',
            CustomerId: customerId, 
            Type: 'Credit'
        }}
    />;
}

const RebateAutoComplete: React.FC<{
    rebate: RemittanceAdviceRebateVM,
    customerId?: string,
    updateRebate: (rebate: Partial<RemittanceAdviceRebateVM>, newRebate: Partial<RemittanceAdviceRebateVM>) => void
}> = ({ rebate, customerId, updateRebate }) => {
    useEffect(() => {
        if (rebate && !rebate.SearchValue) {
            rebate.SearchValue = rebate.Number;
        }
    }, [rebate]);

    return <InputAutoCompleteWithButton
        updateField={(value: any) => {
            if (rebate.Number && rebate.Number !== value) {
                const newRebate: Partial<RemittanceAdviceRebateVM> = {
                    ...rebate,
                    Number: '',
                    CustomerId: customerId,
                    Amount: 0,
                    State:'',
                };
                rebate.SearchValue = value;
                updateRebate(rebate, newRebate);
            }
        }}
        onSelect={(value: any) => {
            const RebateId = get(value, 'Id');
            const CustomerId = get(value, 'Customer.Id');
            const Number = get(value, 'Number');
            const Amount = get(value, 'Percentage') ? get(value, 'Percentage') : get(value, 'AmountAvailable');
            const Percentage = get(value, 'Percentage');
            const State = get(value, 'State');
            const newRebate = {
                ...rebate,
                RebateId,
                Rebate: {
                    ...rebate.Rebate,
                    Id: RebateId,
                    Percentage
                },
                CustomerId,
                Number,
                Amount,
                Percentage,
                State
                // CalculatePercentage: Percentage ? true : false,
            };
            updateRebate(rebate, newRebate);
        }}
        minimumLength={1}
        placeholder="Search rebates"
        stateValue={rebate.SearchValue}
        keyField={'Id'}
        queryName={'GET_CREDITS_FOR_COMPANY'}
        filterField={'CreditNumber'}
        queryFilterName={'CreditNumber'}
        sortField={'Credit number'}
        responseName={'GetCreditsForCompany.Credits'}
        labelField={'Number'}
        hasNoOkButton
        loading={false}
        hasNoContainerRef
        extraQueryVariables={{
            CreditState: 'Open',
            CustomerId: customerId,
            Type: 'Rebate'
        }}
    />;
}