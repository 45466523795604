/**
 * File for the `Current ATB` widget form.
 */

import { Col, Divider, Form, Input, Radio, Row, Select } from 'antd';
import { capitalize, filter, get, isEmpty, isUndefined, map, toLower } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { currentATBViewOptions, chartWidgetDisplayTypes } from '../../constants/dashboards';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { DynamicObject } from '../../utils/commonInterfaces';
import { customFieldIndicator } from '../common/FilterBar';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import SelectReadonly from '../FormComponents/SelectReadonly';
import { populatePopoverContainer } from '../../utils/commonFunctions';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    getFieldValue: any;
    setFieldsValue: any;
    isOrgView?: boolean;
}
export const defaultViewOptionsValue = false; // for Due date
export const currentATBGroupingOptions = (isCalendarView: boolean) => {
    let options = {
        NOT_DUE: {
            label: 'Not due',
            value: 'NotDue',
            color: '#9c27b0',
        },
        CURRENT: {
            label: 'Current',
            value: 'Current',
            color: '#0088fe',
        },
    };
    
    if (isCalendarView) {
        options = {
            ...options,
            ...{
                ONE_MONTH: {
                    label: '1 month',
                    value: 'Period1',
                    color: '#fd6a02',
                },
                TWO_MONTHS: {
                    label: '2 months',
                    value: 'Period2',
                    color: '#a6a6a6',
                },
                THREE_PLUS_MONTHS: {
                    label: '3+ months',
                    value: 'Period3Plus',
                    color: '#ffbb28',
                }
            }
        }
    } else {
        options = {
            ...options, 
            ...{
                THIRTY_DAYS: {
                    label: '30 days',
                    value: 'Period1',
                    color: '#fd6a02',
                },
                SIXTY_DAYS: {
                    label: '60 days',
                    value: 'Period2',
                    color: '#a6a6a6',
                },
                NINETY_PLUS_DAYS: {
                    label: '90+ days',
                    value: 'Period3Plus',
                    color: '#ffbb28',
                }
            }
        }
    }
    
    return options;
};
const CurrentATBWidgetFields: React.FC<IProps> = ({
    widgetDetails,
    drawerRef,
    formDisabled,
    getFieldDecorator,
    customFieldsFilterList,
    getFieldValue,
    setFieldsValue,
    isOrgView,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);
    /**
     * Common function for populating the custom fields filters.
     * @param customFieldType
     */
    const populateCustomFieldsFilters = (customFieldType: string) => {
        return filter(customFieldsFilterList, ['Type', customFieldType]).map(
            ({ Type, FieldName }: DynamicObject) => {
                const customFieldKey = `${customFieldIndicator}${Type}--${FieldName}`;
                return (
                    <Col span={12} key={customFieldKey}>
                        <FormItem label={FieldName}>
                            {getFieldDecorator(customFieldKey, {
                                initialValue: get(
                                    widgetDetails,
                                    customFieldKey
                                ),
                            })(
                                <Input
                                    placeholder={FieldName}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                );
            }
        );
    };

    /**
     * Function that populates the Customer custom fields section.
     */
    const populateCustomerCustomFieldsSection = () => {
        const customerCustomFields = populateCustomFieldsFilters(
            CUSTOM_FIELD_TYPES.CUSTOMER
        );
        if (!isEmpty(customerCustomFields)) {
            return (
                <>
                    <Divider />
                    <Row>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <h3>
                                        Filter by{' '}
                                        {isOrgView
                                            ? 'customer'
                                            : toLower(customerLabel)}{' '}
                                        custom fields
                                    </h3>
                                </Col>
                            </Row>
                            <Row gutter={10}>{customerCustomFields}</Row>
                        </Col>
                    </Row>
                </>
            );
        }

        return null;
    };

    /**
     * Function that populates the radio group buttons based on given list of options.
     * @param radioOptions
     */
    const populateRadioOptions = (radioOptions: DynamicObject[]) => {
        return map(radioOptions, (opt) => (
            <Radio.Button value={get(opt, 'value')} key={get(opt, 'value')}>
                {get(opt, 'label')}
            </Radio.Button>
        ));
    };

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});
    
     /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
     const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

    const populateCustomerFieldSection = () => {
        const filterNameUsed = !isOrgView
            ? capitalize(customerLabel)
            : 'Customer'

        const stateName = 'Customer';
        return (
            <Col span={24} key={stateName}>
                <FormItem label={filterNameUsed}>
                    <InputAutoCompleteWithButtonDashboard
                        readOnly={formDisabled}
                        hasNoOkButton={true}
                        updateField={(value: string) => {
                            changeSearchFilter(stateName, value);
                        }}
                        stateValue={searchFilters[stateName]}
                        queryName={'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                        filterField={'Customer'}
                        sortField={'Company name'}
                        responseName={'GetCustomersForCompany.Customers'}
                        labelField={'DisplayName'}
                        getFieldDecorator={getFieldDecorator}
                        widgetDetails={widgetDetails}
                        filterFieldKey={'Customer'}
                    />
                </FormItem>
            </Col>
        );
    } ;

    /**
     * Function that populates the dropdown based on given list of options.
     */
      const populateNotificationSuccessDisplayTypeOptions = () =>
      map(chartWidgetDisplayTypes, ({ label, value }: any) => (
          <Option key={value} value={value}>
              {label}
          </Option>
      ));

    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label="Filter on display type">
                            {getFieldDecorator('displayType', {
                                initialValue:
                                    get(widgetDetails, 'displayType') ||
                                    get(
                                        chartWidgetDisplayTypes,
                                        '2.value'
                                    ),
                            })(
                            <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateNotificationSuccessDisplayTypeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>                     
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <div>Filter on view options</div>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <FormItem>
                                {getFieldDecorator('viewOptions', {
                                    initialValue: !isUndefined(
                                        get(widgetDetails, 'viewOptions')
                                    )
                                        ? get(widgetDetails, 'viewOptions')
                                        : defaultViewOptionsValue,
                                })(
                                    <Radio.Group className="btn-radio-group">
                                        {populateRadioOptions(
                                            currentATBViewOptions
                                        )}
                                    </Radio.Group>
                                )}
                            </FormItem>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <h3>
                                    Filter by{' '}
                                    {isOrgView
                                        ? 'customer'
                                        : toLower(customerLabel)}{' '}
                                </h3>
                                </Col>
                            </Row>
                            <Row gutter={10}>{populateCustomerFieldSection()}</Row>
                        </Col>
                </Row>
                {populateCustomerCustomFieldsSection()}
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                    />
                )}
            </Col>
        </Row>
    );
};

export default CurrentATBWidgetFields;
