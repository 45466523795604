import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, forEach, isEmpty, orderBy } from 'lodash';
import { ApplicationState } from '../store';
import { NotificationsState } from '../store/notifications/types';
import { fetchNewNotificationsAction } from '../store/notifications/actions';
import { notificationPollTime } from '../config/config';
import { DynamicObject } from './commonInterfaces';

interface IProps {
    onShowNotification: (notification: DynamicObject) => void;
    getCategorizedNotificationsRequestAction: (
        refetchNewNotif: boolean,
        callback?: (notifications: any) => void
    ) => void;
}
let refetchNotificationsRef: any = null;
let notificationRef: any = null;
let notificationPollingTimer: any = null;
let usedNotificationList: any = [];
const NotificationSubscriptionService: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const selectedUserCompany = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const {
        refetchNewNotifications,
        categorizedNotifications,
    }: NotificationsState = useSelector(
        (state: ApplicationState) => state.notifications
    );

    const fetchNewNotifications = (
        notificationList: any = categorizedNotifications.data
    ) => {
        usedNotificationList = notificationList;

        if (refetchNewNotifications) {
            if (notificationPollingTimer)
                clearTimeout(notificationPollingTimer);

            const sortedCategorizedNotifications = orderBy(
                notificationList,
                ['CreatedDateTime'],
                ['desc']
            );

            let latestNotificationDatetime = get(
                sortedCategorizedNotifications,
                `0.CreatedDateTime`
            );

            dispatch(
                fetchNewNotificationsAction(
                    latestNotificationDatetime,
                    ({ data, IsSuccess }: any) => {
                        let notificationsList: any = usedNotificationList;
                        if (IsSuccess && !isEmpty(data)) {
                            props.getCategorizedNotificationsRequestAction(
                                false,
                                (updatedNotificationsList: []) => {
                                    notificationsList = updatedNotificationsList;
                                    forEach(data, (notificationData: any) => {
                                        props.onShowNotification(
                                            notificationData
                                        );
                                    });
                                }
                            );
                        }

                        notificationPollingTimer = setTimeout(() => {
                            fetchNewNotifications(notificationsList);
                        }, notificationPollTime);
                    }
                )
            );
        }
    };

    useEffect(fetchNewNotifications, [
        selectedUserCompany,
        refetchNewNotifications,
    ]);

    useEffect(() => {
        return () => {
            if (notificationPollingTimer)
                clearTimeout(notificationPollingTimer);
        };
    }, []);

    useEffect(() => {
        return () => {
            const notificationsRefList = [
                refetchNotificationsRef,
                notificationRef,
            ];

            forEach(notificationsRefList, (refVariable: any) => {
                if (refVariable !== null) {
                    if (refVariable !== null) {
                        refVariable.unsubscribe();
                    }

                    refVariable = null;
                }
            });
        };
    }, []);

    return <></>;
};

export default NotificationSubscriptionService;
