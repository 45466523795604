/**
 * Common component for displaying an image with loading indicator
 */

import React, { useState } from 'react';
import { Spin } from 'antd';

interface IProps {
    alt?: string;
    src: string;
    style?: any;
    className?: string;
    hasPlaceholder?: boolean;
    loading?: boolean;
    hasError?: boolean;
}
const ImageWithLoading: React.FC<IProps> = ({
    className,
    src,
    style,
    alt,
    hasPlaceholder,
    loading,
    hasError,
}) => {
    const [loaded, setLoaded] = useState<boolean>(false);

    const loadingElement = hasPlaceholder ? (
        <Spin>
            <svg width="100" height="100" viewBox="0 0 100 100">
                <rect width="100" height="100" rx="10" ry="10" fill="#CCC" />
            </svg>
        </Spin>
    ) : (
        <Spin />
    );

    const errorElement = (
        <svg width="100" height="100" viewBox="0 0 100 100">
            <rect width="100" height="100" rx="10" ry="10" fill="#EFEFEF" />
        </svg>
    );

    const isLoading = !loaded || loading;

    return (
        <>
            {isLoading ? loadingElement : null}
            {hasError ? errorElement : null}
            <img
                className={className}
                alt={alt || ''}
                src={src}
                style={
                    isLoading || hasError
                        ? { visibility: 'hidden', width: 0 }
                        : style
                }
                onLoad={() => setLoaded(true)}
            />
        </>
    );
};

export default ImageWithLoading;
