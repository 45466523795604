import { compact, forEach, get, includes, isEmpty, map, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { PaymentPlanSchedule } from '../store/paymentPlans/types';
import { DynamicObject } from '../utils/commonInterfaces';
import { dateSelectOptions } from './invoicesSortAndFilters';

export const preConfiguredFilters = {
    ALL_PAYMENT_PLANS: 'All paymentPlans',
};

export const paymentPlansTableFilterOptions = [
    // { label: 'All open invoices', value: 'Open' },
    {
        label: 'All paymentPlans',
        value: preConfiguredFilters.ALL_PAYMENT_PLANS,
    },
];

export const paymentPlansSortByOptions = [
    { label: 'Customer code', value: 'Customer code' },
    { label: 'Company name', value: 'Company name' },
    { label: 'Number', value: 'Number' },
    { label: 'Created date', value: 'Created date' },
    { label: 'Due date', value: 'Due date' },
    { label: 'Amount owing', value: 'Amount owing' },
];

const {
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
} = dateSelectOptions;

export const paymentPlansCreatedDateOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const paymentPlansSettledDateOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const paymentPlanState = {
    PENDING: 'Pending',
    OPEN: 'Open',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    REJECTED: 'Rejected',
};

export const paymentPlanScheduleState = {
    PAID: 'Paid',
    OPEN: 'Open',
    NEW: 'New',
    CANCELLED: 'Cancelled',
    PENDING: 'Pending'
};

export const paymentPlanStateOptions = [
    {
        label: paymentPlanState.PENDING,
        value: 1,
    },
    {
        label: paymentPlanState.OPEN,
        value: 2,
    },
    {
        label: paymentPlanState.COMPLETED,
        value: 4,
    },
    {
        label: paymentPlanState.CANCELLED,
        value: 8,
    },
    {
        label: paymentPlanState.REJECTED,
        value: 16,
    },
];

export const getPaymentPlansFilterBarFilters = () => {
    return [
         {
             filterName: 'Customer',
             filterStateName: 'Customer',
             filterElement: 'input-autocomplete',
             filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
             filterSort: 'Company name',
             filterResponse: 'GetCustomersForCompany.Customers',
             filterLabelField: 'DisplayName',
        },
        {
            filterName: 'Plan state',
            filterStateName: 'StatusType',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: paymentPlanStateOptions,
        },
        {
            filterName: 'Created date',
            filterStateName: 'CreatedDate',
            filterElement: 'select',
            filterType: 'text',
            // filterMaxDate: moment(),
            filterOptions: paymentPlansCreatedDateOptions,
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to',
            },
            // offset: 1,
            // span: 5,
        },
    ];
};

export const paymentPlansStateFilterOptions = [
    { label: 'All active payment plans', value: 'Active' },
    { label: 'All payment plans', value: 'All' },
];

export const CUSTOM_PLAN_STATE_FILTER_OPTION = 'Custom';

/**
 * For conversation
 */
export const conversationFilters = {
    ALL_CONVERSATIONS: 'All conversations',
    ALL_COMMENTS: 'All comments',
    ALL_SUPPORT_TICKETS: 'All support tickets',
    ALL_COMMUNICATIONS: 'All communications',
};
export const conversationTableFilterOptions = [
    {
        label: 'All conversations',
        value: conversationFilters.ALL_CONVERSATIONS,
    },
    {
        label: 'All comments',
        value: conversationFilters.ALL_COMMENTS,
    },
    {
        label: 'All support tickets',
        value: conversationFilters.ALL_SUPPORT_TICKETS,
    },
    {
        label: 'All communications',
        value: conversationFilters.ALL_COMMUNICATIONS,
    },
];

/**
 * Payment schedule
 */
export const paymentScheduleSortByOptions = [
    { label: 'Schedule date', value: 'LocalDueDateTime' },
    { label: 'Amount', value: 'AmountRemaining' },
    { label: 'Status', value: 'State' },
];

export const paymentPlanScheduleDateOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const paymentScheduleState = {
    PAID: 'Paid',
    OVERDUE: 'Overdue',
    NOT_DUE: 'Not due',
};

export const paymentScheduleBackendState = {
    OPEN: 'Open',
    CLOSED: 'Closed',
};

export const paymentScheduleStateOptions = [
    {
        label: paymentScheduleState.PAID,
        value: paymentScheduleState.PAID,
    },
    {
        label: paymentScheduleState.OVERDUE,
        value: paymentScheduleState.OVERDUE,
    },
    {
        label: paymentScheduleState.NOT_DUE,
        value: paymentScheduleState.NOT_DUE,
    },
];

export const paymentScheduleAmountType = [{ label: 'Amount', value: 'Amount' }];

export const getPaymentPlanFirstOpenSchedule = (
    planSchedules: PaymentPlanSchedule[]
) => {
    const paymentPlanScheduleItems = orderBy(
        compact(
            map(planSchedules, (pps: PaymentPlanSchedule) => {
                if (pps.State === paymentPlanState.OPEN
                    || pps.State === paymentPlanState.PENDING) {
                    return pps;
                }
            })
        ),
        ['DueDateTime'],
        ['asc']
    );

    return get(paymentPlanScheduleItems, '0', {});
};

export const fetchPaymentPlanScheduleHandler = (
    paymentPlanSchedules: PaymentPlanSchedule[],
    cleanFilters: DynamicObject
) => {
    const planSchedulesFiltered: any[] = [];
    delete cleanFilters.paymentPlanId;

    forEach(paymentPlanSchedules, (pps: PaymentPlanSchedule) => {
        if (isEmpty(cleanFilters)) {
            planSchedulesFiltered.push(pps);
        } else {
            let dateMinPassed = true;
            let dateMaxPassed = true;
            let statusPassed = true;
            let amountPassed = true;

            if (cleanFilters.DueDateMin && cleanFilters.DueDateMin.isValid()) {
                dateMinPassed = moment(pps.DueDateTime).isSameOrAfter(
                    cleanFilters.DueDateMin
                );
            }
            if (cleanFilters.DueDateMax && cleanFilters.DueDateMax.isValid()) {
                dateMaxPassed = moment(pps.DueDateTime).isSameOrBefore(
                    cleanFilters.DueDateMax
                );
            }

            if (!isEmpty(cleanFilters.State)) {
                let planScheduleState = '';
                if (pps.State === 'Open') {
                    planScheduleState = moment(
                        pps.LocalDueDateTime
                    ).isSameOrAfter(moment())
                        ? paymentScheduleState.NOT_DUE
                        : paymentScheduleState.OVERDUE;
                } else if (
                    pps.State === 'Completed' &&
                    !isEmpty(pps.LocalClosedDateTime)
                ) {
                    planScheduleState = paymentScheduleState.PAID;
                }

                statusPassed = includes(cleanFilters.State, planScheduleState);
            }

            if (cleanFilters.AmountOperator) {
                const operatorUsed = cleanFilters.AmountOperator;
                const amountValue = cleanFilters.AmountValue;
                if (operatorUsed === '>') {
                    amountPassed = pps.AmountRemaining > amountValue;
                } else if (operatorUsed === '>=') {
                    amountPassed = pps.AmountRemaining >= amountValue;
                } else if (operatorUsed === '<') {
                    amountPassed = pps.AmountRemaining < amountValue;
                } else if (operatorUsed === '<=') {
                    amountPassed = pps.AmountRemaining <= amountValue;
                } else if (operatorUsed === '=') {
                    amountPassed = pps.AmountRemaining == amountValue;
                }
            }

            if (
                dateMinPassed &&
                dateMaxPassed &&
                statusPassed &&
                amountPassed
            ) {
                planSchedulesFiltered.push(pps);
            }
        }
    });

    return planSchedulesFiltered;
};

export const defaultPpCommentLabel = 'Comment';
export const defaultPpCommentLabelFieldName = 'CommentLabel';
export const defaultPpCommentFieldName = 'Comment';

export const paymentPlanActions = {
    Requested: "Requested",
    Approved: "Approved",
    Rejected: "Rejected",
    Completed: "Completed",
    Cancelled: "Cancelled",
};