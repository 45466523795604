import API from '@aws-amplify/api';
import { get } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { checkShouldRequestRefetch } from '../../utils/commonFunctions';
import { companyInviteCreateAction } from './actions';
import { InviteActionTypes } from './types';
import { DynamicObject } from '../../utils/commonInterfaces';

let refetchCount = 0;

function* handleCompanyInviteCreate({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/company/invite/create',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;
        refetchCount = 0;
        if (callback) callback(res);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(companyInviteCreateAction(payload, callback));
        } else {
            if (callback) {
                let returnData: DynamicObject;
                if (typeof err === 'object' && err !== null && 'response' in err) {
                    const errorResponse = err as { response: { data: any } };
                    returnData = get(errorResponse.response, 'data')
                        ? errorResponse.response.data
                        : { Messages: ['An error occurred'] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

function* handleCompanyInviteValidate({ payload: sagaPayload }: any) {
    const { inviteToken, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/company/invite/validate',
            {
                body: {
                    InviteToken: inviteToken,
                },
            }
        );

        res.IsSuccess = true;
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: DynamicObject;
            if (typeof err === 'object' && err !== null && 'response' in err) {
                const errorResponse = err as { response: { data: any } };
                returnData = get(errorResponse.response, 'data')
                    ? errorResponse.response.data
                    : { Messages: ['An error occurred'] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* handleCompanyInviteLink({ payload: sagaPayload }: any) {
    const { inviteToken, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/company/invite/link',
            {
                body: {
                    Token: inviteToken,
                },
            }
        );

        res.IsSuccess = true;
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: DynamicObject;
            if (typeof err === 'object' && err !== null && 'response' in err) {
                const errorResponse = err as { response: { data: any } };
                returnData = get(errorResponse.response, 'data')
                    ? errorResponse.response.data
                    : { Messages: ['An error occurred'] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchCompanyInviteCreateRequest() {
    yield takeLatest(
        InviteActionTypes.COMPANY_INVITE_CREATE,
        handleCompanyInviteCreate
    );
}

function* watchCompanyInviteValidateRequest() {
    yield takeLatest(
        InviteActionTypes.COMPANY_INVITE_VALIDATE,
        handleCompanyInviteValidate
    );
}

function* watchCompanyInviteLinkRequest() {
    yield takeLatest(
        InviteActionTypes.COMPANY_INVITE_LINK,
        handleCompanyInviteLink
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* inviteSaga() {
    yield all([
        fork(watchCompanyInviteCreateRequest),
        fork(watchCompanyInviteValidateRequest),
        fork(watchCompanyInviteLinkRequest),
    ]);
}

export default inviteSaga;
