export const atbCalculationOptions = [
    {
        label: 'Invoice create date',
        value: 'CreatedDate',
    },
    {
        label: 'Invoice due date',
        value: 'DueDate',
    },
];

export enum AtbViewType {
    CalendarView = 'IsCalendarView',
    DueDateView = 'IsDueDateView'
};

export const atbPeriodOptions = [
    {
        label: '30, 60, 90+ days',
        value: '306090',
    },
    {
        label: 'Calendar month',
        value: 'CalendarMonth',
    },
];

export const companyDisplayInformationOptions = [
    {
        label: 'include',
        value: 'include',
    },
    {
        label: "don't include",
        value: 'dont_include',
    },
];

export const companyImportTypes = {
    CLOUD: 'Cloud',
    API: 'API',
    EXCEL: 'Excel',
};

export const companyCloudImportTypes = {
    XERO: 'Xero',
};

export const companyApiImportTypes = {
    LEGACY: 'Legacy',
    IODM_CONNECT_WEB: 'IODM Connect Web',
};

export const accountingSystemOptions = {
    IODM_CONNECT_WEB_API: 'IODM Connect Web API',
    XERO: 'Xero',
    EXCEL: 'Excel',
    LEGACY_API: 'Legacy API',
};

export const accountingSystemSelectOptions = [
    {
        label: 'Connect via IODM Connect Web API',
        value: accountingSystemOptions.IODM_CONNECT_WEB_API,
    },
    {
        label: 'Connect via Xero',
        value: accountingSystemOptions.XERO,
    },
    {
        label: 'Import via Excel file',
        value: accountingSystemOptions.EXCEL,
    },
    {
        label: 'Import via Legacy API',
        value: accountingSystemOptions.LEGACY_API,
    },
];

export const hiddenCloudImportFields = ['CustomerCode', 'Customer code'];

export const customizationPlaceholders = {
    INFORM_US_LABEL: '{{INFORM_US_LABEL}}'
};

export const paymentPlanDepositTypeOptions = [
    {
        label: 'Percent',
        value: 'Percent',
    },
    {
        label: 'Set amount',
        value: 'Set amount',
    },
];