export enum AccessTokensActionTypes {
    GET_ACCESS_TOKENS_REQUEST = '@@accessTokens/GET_ACCESS_TOKENS_REQUEST',
    GET_ACCESS_TOKENS_SUCCESS = '@@accessTokens/GET_ACCESS_TOKENS_SUCCESS',
    GET_ACCESS_TOKENS_ERROR = '@@accessTokens/GET_ACCESS_TOKENS_ERROR',
    REVOKE_ACCESS_TOKENS_REQUEST = '@@accessTokens/REVOKE_ACCESS_TOKENS_REQUEST',
    REVOKE_ACCESS_TOKENS_RESPONSE = '@@accessTokens/REVOKE_ACCESS_TOKENS_RESPONSE',
    CLEAR_ACCESS_TOKENS_STATE_DATA = '@@accessTokens/CLEAR_ACCESS_TOKENS_STATE_DATA',

    CREATE_ACCESS_TOKEN_REQUEST = '@@accessTokens/CREATE_ACCESS_TOKEN_REQUEST',
}

export interface AccessTokensState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly revokeAccessTokensLoading: boolean;
    readonly data: AccessToken[];
}

export interface AccessToken {
    readonly CompanyId: string;
    readonly Name: string;
    readonly ParentUser: {
        UserId: string;
        GivenName: string;
        FamilyName: string;
        Email: string;
    };
    readonly Role: {
        RoleId: string;
        Name: string;
    };
    readonly Active: boolean;
    readonly Key: string;
    readonly CreatedDateTime: string;
}
